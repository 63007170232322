import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormHead from "../../components/GetStarted/Form/FormHead";
import FormLayout from "../../components/GetStarted/Layout/FormLayout";
import styles from "../../styles/GetStarted.module.css";
import { API_URL } from "../../utils/constant";
import { Grid } from "@mui/material";
import ChatwootWidget from "../../components/ChatwootWidget/ChatwootWidget";

async function getDistricts(stateName) {
  const response = await fetch(`${API_URL}/state?state=${stateName}`, {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(`Failed to get districts for state: ${stateName}`);
  }

  const data = await response.json();

  const districtNames = data.map((district) => {
    return { name: district.name, id: district.id };
  });

  return districtNames;
}

function addProtocolToWebsite(website) {
  if (!website) {
    return website;
  }
  if (!/^(f|ht)tps?:\/\//i.test(website)) {
    website = "http://" + website;
  }
  return website;
}


const AddInfo = () => {

  const residency = ['Day', 'Boarding', 'Day And Boarding']
  const medium = ['English', 'Hindi', 'Tami', 'Kannada', 'Others']
  const [board, setBoard] = useState("");
  const [classs, setClasss] = useState("");
  const [gender, setGender] = useState("");
 
  const [dayBoarding, setDayBoarding] = useState("");
  const [resError, setResError] = React.useState(""); //this state is for storing the error message from the backend

  const [valuesGlobal, setValues] = useState({ state: "" });
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const res = await axios.get(`${API_URL}/school/search-filters`);
        console.log('labels:', res)
        setBoard(res.data.boards);
        setClasss(res.data.classes);
        setGender(res.data.genders);
        setDayBoarding(res.data.dayBoarding);
       
      } catch (err) {
        console.error(err);
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    async function fetchDistricts() {
      try {
        const newDistricts = await getDistricts(valuesGlobal.state);
        setDistricts(newDistricts);
      } catch (error) {
        console.error(error);
      }
    }

    fetchDistricts();
  }, []);

  const districtOptions =
    districts &&
    districts.map((district) => ({
      label: district.name,
      value: district.id,
    }));

  function handleChangeState(event, value) {
    // console.log(">>>> ",event.target.name, value)
    setValues((prevValues) => ({
      ...prevValues,
      state: value.label,
    }));
  }

  return (
    <div className={styles.add_bg_img}>
      <div className={`${styles.mainContainer}`}>
        <Grid container direction="column">
          <div className="mt-5">
            <FormHead itemTitle="Enter your School Information" />
          </div>

          <br />
          <FormLayout>
            <Formik
              initialValues={{
                name: "",
                schoolName: "",
                schoolPhone: "",
                schoolEmail: "",
                schoolWebsite: "",
                schoolAddress: "",
                schoolMedium:"",
                principal_name: "",
                state: "",
                city: "",
                pincode: "",
                reg_no: "",
                board: "",
                gender: "",
                established_year: "",
                residencyType: "",
                all_classes: [],
              }}
              validationSchema={yup.object({
                name: yup.string().required("Required"),
                schoolName: yup.string().required("Required"),
                schoolPhone: yup
                  .string()
                  .required("Required")
                  .min(10, "Must be 10 digits")
                  .max(10, "Must be 10 digits")
                  .matches(/^[0-9]+$/, "Must be only digits")
                  .typeError("Must be only digits"),
                schoolEmail: yup
                  .string()
                  .required("Required")
                  .email("Invalid email address")
                  .typeError("Invalid email address"),
                schoolAddress: yup.string().required("Required"),
                principal_name: yup.string().required("Required"),
                // state: yup.string().required('Required'),
                city: yup.string().required("Required"),
                pincode: yup
                  .string()
                  .required("Required")
                  .min(6, "Must be 6 digits")
                  .max(6, "Must be 6 digits")
                  .matches(/^[0-9]+$/, "Must be only digits")
                  .typeError("Must be only digits"),
                reg_no: yup.string().required("Required"),
                board: yup.string().required("Required"),
                gender: yup.string().required("Required"),
                schoolWebsite: yup
                .string()
                .matches(
                  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
                  "Invalid website address"),
                residencyType: yup.string().required("Required"),
                schoolMedium: yup.string().required('Required'),
                established_year: yup
                    .string()
                    .required("Required")
                    .min(4, "Must be 4 digits")
                    .max(4, "Must be 4 digits")
                    .matches(/^[0-9]+$/, "Must be only digits")
                    .typeError("Must be only digits")
                    .test("is-validated", "Established year must be less or equal to the current year", function(value) {
                      const currentYear = new Date().getFullYear();
                      return parseInt(value) <= currentYear;
                    }),

              })}
              onSubmit={(values, { setSubmitting }) => {
                const data = {
                  name: values.name,
                  head: values.principal_name,
                  schoolName: values.schoolName,
                  email: values.schoolEmail,
                  address: values.schoolAddress,
                  website: addProtocolToWebsite(values.schoolWebsite),
                  city: values.city,
                  state: valuesGlobal.state,
                  pincode:
                    values.pincode === "" ? "" : values.pincode.toString(),
                  board: parseInt(values.board),
                  gender: values.gender,
                  classes: values.all_classes.map(Number),
                  registrationNumber: values.reg_no,
                  established: parseInt(values.established_year),
                  residencyType: values.residencyType,
                  medium: values.schoolMedium,
                  phoneNumber:
                    values.schoolPhone === ""
                      ? ""
                      : values.schoolPhone.toString(),
                };
                // alert(JSON.stringify(data, null, 2)) //for testing purpose
                axios
                  .post(`${API_URL}/school/selfSignupSchool`, data)
                  .then((res) => {
                    
                    console.log("values", values);
                    console.log("res123", res);
                    console.log(res.data.success);
                    if(res.data.success){              
                      // console.log(res.data.success)
                      setSubmitting(false)
                      window.location.href = "/get-started/completed";
                    } else {
                      setResError(res.data.message);
                      window.scrollTo(0, 0);
                      return;
                    }
                  })
                  .catch((err) => {
                    // if there is an error, show the error message
                    if (err.response && err.response.data && err.response.data.err) {
                      setResError(err.response.data.err);
                    } else {
                      setResError("An unknown error occurred. Please try again later.");
                    }
                    window.scrollTo(0, 0);
                    setSubmitting(false);
                    return;
                  }).finally(() => {
                    setSubmitting(false);
                    return;
                  });
               
              }}
            >
              {({ errors, values, handleChange, handleSubmit, touched, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <div style={{ color: "white" }} className="px-lg-5 px-3">
                    {resError.length > 0 ? (
                      <div
                        className="text-center bg-danger p-2 rounded text-white my-3
                  "
                        id="error"
                      >
                        {resError}
                      </div>
                    ) : null}

                    <div className="my-4">
                      <div className=" text-start">
                        <label htmlFor="">Your Name</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Your Name"
                          className={styles.field}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                        />
                        {touched.name && errors.name && (
                          <div className="text-danger text-end">
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-4">
                      <div className=" text-start">
                        <label htmlFor="">Head/Principal Name</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter Head/Principal Name"
                          className={styles.field}
                          name="principal_name"
                          value={values.principal_name}
                          onChange={handleChange}
                        />
                        {touched.principal_name && errors.principal_name && (
                          <div className="text-danger text-end">
                            {errors.principal_name}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-4">
                      <div className=" text-start">
                        <label htmlFor="">School Name</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter School Name"
                          className={styles.field}
                          name="schoolName"
                          value={values.schoolName}
                          onChange={handleChange}
                        />
                        {touched.schoolName && errors.schoolName && (
                          <div className="text-danger text-end">
                            {errors.schoolName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-4">
                      <div className=" text-start">
                        <label htmlFor="">School Phone Number</label>
                      </div>
                      <div>
                        <input
                          placeholder="Enter School Phone Number"
                          className={styles.field}
                          name="schoolPhone"
                          value={values.schoolPhone}
                          onChange={(e) => {
                              let newValue = e.target.value;
                              newValue = newValue.replace(/\D/gm,"");

                              setFieldValue("schoolPhone", newValue)
                          }}
                          minLength="10"
                        />
                        {touched.schoolPhone && errors.schoolPhone && (
                          <div className="text-danger text-end">
                            {errors.schoolPhone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-4">
                      <div className=" text-start">
                        <label htmlFor="">School Email</label>
                      </div>
                      <div>
                        <input
                          type="email"
                          placeholder="Enter School Email"
                          className={styles.field}
                          name="schoolEmail"
                          value={values.schoolEmail}
                          onChange={handleChange}
                        />
                        {touched.schoolEmail && errors.schoolEmail && (
                          <div className="text-danger text-end">
                            {errors.schoolEmail}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-4">
                      <div className=" text-start">
                        <label htmlFor="">School Website</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="Enter School Website"
                          className={styles.field}
                          name="schoolWebsite"
                          value={values.schoolWebsite}
                          onChange={handleChange}
                        />
                        {touched.schoolWebsite && errors.schoolWebsite && (
                          <div className="text-danger text-end">
                            {errors.schoolWebsite}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-4">
                      <div className=" text-start">
                        <label htmlFor="">School Address</label>
                      </div>
                      <div>
                        <textarea
                          type="text"
                          placeholder="e.g 23, New No 4, RamBalaji Avenue 2nd St, Thirumala Rd."
                          className={styles.field}
                          name="schoolAddress"
                          value={values.schoolAddress}
                          onChange={handleChange}
                        />
                        {touched.schoolAddress && errors.schoolAddress && (
                          <div className="text-danger text-end">
                            {errors.schoolAddress}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-4">
                      <div className=" text-start">
                        <label htmlFor="">PIN Code </label>
                      </div>
                      <div>
                        <input
                          type="text"
                          placeholder="e.g 600017"
                          className={styles.field}
                          name="pincode"
                          minLength={6}
                          value={values.pincode}
                          onChange={handleChange}
                        />
                        {touched.pincode && errors.pincode && (
                          <div className="text-danger text-end">
                            {errors.pincode}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="my-4 d-flex">
                      <div style={{ width: "100%" }}>
                          <div className="text-start">
                            <label htmlFor="">State</label>
                          </div>
                          <div>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={districtOptions}
                              sx={{
                                width: "98%",
                                "& .MuiAutocomplete-inputRoot": {
                                  padding: 0,
                                  height: 42,
                                  border: "none",
                                  backgroundColor: "#3B3B3B",
                                  color: "#fff",
                                },
                              }}
                              name="state"
                              onChange={(event, value) => {
                                handleChangeState(event, value);
                                setFieldValue("state", value ? value.label : ""); // update the value of the TextField component
                              }}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  value={values.state}
                                  // onClick={handleChangeState}
                                  placeholder="" // to remove the default placeholder
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "transparent",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "transparent",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "transparent",
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>

                      <div
                        style={{
                          width: "100%",
                          marginRight: "10px",
                        }}
                      >
                        <div className=" text-start">
                          <label htmlFor="">City</label>
                        </div>
                        <div>
                          <input
                            name="city"
                            onChange={handleChange}
                            className={styles.field}
                            value={values.city}
                            type="text"
                          />

                          {touched.city && errors.city && (
                            <div className="text-danger text-end">
                              {errors.city}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="my-4 d-flex">
                      <div
                        style={{
                          width: "100%",
                          marginRight: "10px",
                        }}
                      >
                        <div className=" text-start">
                          <label htmlFor="">School Registration Number</label>
                        </div>
                        <div>
                          <input
                            type="text"
                            placeholder="School Registration Number"
                            className={styles.field}
                            name="reg_no"
                            value={values.reg_no}
                            onChange={handleChange}
                          />
                          {touched.reg_no && errors.reg_no && (
                            <div className="text-danger text-end">
                              {errors.reg_no}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className=" text-start">
                          <label htmlFor="">Board</label>
                        </div>
                        <div>
                          <select
                            type="text"
                            className={styles.field}
                            name="board"
                            value={values.board}
                            onChange={handleChange}
                          >
                            <option value="">Select Board</option>
                            {Array.isArray(board) &&
                              board.map((board) => (
                                <option
                                  key={board.id + board.name + 1}
                                  value={board.id}
                                >
                                  {board.name}
                                </option>
                              ))}
                          </select>
                          {touched.board && errors.board && (
                            <div className="text-danger text-end">
                              {errors.board}
                            </div>
                          )}
                        </div>
                      </div>
                      
                    </div>
                    <div className="my-4 d-flex">
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className=" text-start">
                          <label htmlFor="">Residency Type</label>
                        </div>
                        <div>
                          <select
                            type="text"
                            className={styles.field}
                            name="residencyType"
                            value={values.residencyType}
                            onChange={handleChange}
                          >
                            <option value="">Select Residency</option>
                            {
                              residency.map((residency) => (
                                <option
                                  key={residency}
                                  value={residency}
                                >
                                  {residency}
                                </option>
                              ))}
                          </select>
                          {touched.residencyType && errors.residencyType && (
                            <div className="text-danger text-end">
                              {errors.residencyType}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="my-4 d-flex">
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className=" text-start">
                          <label htmlFor="">Gender</label>
                        </div>
                        <div>
                          <select
                            type="text"
                            className={styles.field}
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                          >
                            <option value="">Select Gender</option>
                            {Array.isArray(dayBoarding) &&
                              gender.map((gender) => (
                                <option
                                  key={gender.id + gender.name + 1}
                                  value={gender.name}
                                >
                                  {gender.name}
                                </option>
                              ))}
                          </select>
                          {touched.gender && errors.gender && (
                            <div className="text-danger text-end">
                              {errors.gender}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="my-4 d-flex">
                      <div
                        style={{
                          width: "100%",
                          marginRight: "10px",
                        }}
                      >
                        <div className=" text-start">
                          <label htmlFor="">Medium</label>
                        </div>
                        <div>
                        <select
                            type="text"
                            className={styles.field}
                            name="schoolMedium"
                            value={values.schoolMedium}
                            onChange={handleChange}
                          >
                            <option value="">Select Medium</option>
                            {
                              medium.map((medium) => (
                                <option
                                  key={medium}
                                  value={medium}
                                >
                                  {medium}
                                </option>
                              ))}
                          </select>
                          {touched.schoolMedium && errors.schoolMedium && (
                            <div className="text-danger text-end">
                              {errors.schoolMedium}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div className=" text-start">
                          <label htmlFor="">Established Year</label>
                        </div>
                        <div>
                          <input
                            type="number"
                            placeholder="Established Year"
                            className={styles.field}
                            name="established_year"
                            value={values.established_year}
                            onChange={(e) => {
                              let newValue = e.target.value;
                              newValue = newValue.replace(/\D/gm,"");
                              setFieldValue("established_year", newValue)
                            }}
                          />
                          {touched.established_year &&
                            errors.established_year && (
                              <div className="text-danger text-end">
                                {errors.established_year}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className=" text-start">
                        <label htmlFor="">Add Classes</label>
                      </div>
                      <br />
                      <div className=" text-start">
                        <div
                          style={{
                            width: "100%",
                            marginRight: "10px",
                            border: "1px solid #3B3B3B",
                            padding: "12px",
                            borderRadius: "15px",
                            height: "160px",
                            overflow: "auto",
                          }}
                        >
                          {Array.isArray(classs) &&
                            classs.map((classs) => (
                              <div className=" d-flex my-2" key={classs.id}>
                                <input
                                  type="checkbox"
                                  className="my-1"
                                  name="all_classes"
                                  value={classs.id}
                                  onChange={handleChange}
                                />
                                <div
                                  className="mx-2 align-content-center justify-content-center"
                                  onClick={() => {
                                    //auto check the checkbox
                                    const checkbox = document.querySelector(
                                      `input[value="${classs.id}"]`
                                    );
                                    checkbox.checked = !checkbox.checked;
                                    //add or remove the class from the array
                                    if (checkbox.checked) {
                                      values.all_classes.push(classs.id);
                                    }
                                    if (!checkbox.checked) {
                                      values.all_classes =
                                        values.all_classes.filter(
                                          (item) => item !== classs.id
                                        );
                                    }
                                  }}
                                >
                                  {classs.name}
                                </div>
                              </div>
                            ))}
                          {touched.all_classes && errors.all_classes && (
                            <div className="text-danger text-end">
                              {errors.all_classes}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="my-5">
                      <button
                        style={{
                          backgroundColor: "#F876A7",
                          width: "100%",
                          padding: "15px",
                          border: "none",
                        }}
                        type="submit"
                        onClick={() => {
                          const err = Object.keys(errors);
                          if (err.length) {
                            const input = document.querySelector(
                              `input[name=${err[0]}]`
                            );

                            input.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                              inline: "start",
                            });
                          }
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </FormLayout>
        </Grid>
        <ChatwootWidget />
      </div>
    </div>
  );
};

export default AddInfo;
import React from "react";
import styles from "../../styles/PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div
        className="text-center p-lg-2 m-0 w-100  text-white"
        style={{ backgroundColor: "#F87FAA" }}
      >
        <h1 className="p-5">Privacy Policies</h1>
      </div>

      <div style={{ display: "inline-block", position: "relative" }}>
        <div className={styles.sidebar}>
          <a href="#collect">Information we collect</a>
          <a href="#useinfo">How we use your information</a>
          <a href="#personalinfo">How we protect your personal information</a>
          <a href="#logfiles">Log Files</a>
        </div>

        <div className={styles.content}>
          <h3 className="mb-4">Privacy Policy for AdmissionPedia Pvt. Ltd.</h3>
          <p>
            At AdmissionPedia (“AdmissionPedia ”, “we”, ”us”, “our”), We respect
            users Privacy rights. Our Privacy Policy (“Private Policy”) is
            explain how we use the information from your use of our Services
            provided through the website, admissionpedia.com and any other
            websites or applications we own, operate, or control (“the
            Website(s)” ).
          </p>
          <p>
            At admissionpedia.com , accessible from https://admissionpedia.com ,
            one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is
            collected and recorded by admissionpedia.com and how we use it.
          </p>
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>

          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in admissionpedia.com . This policy
            is not applicable to any information collected offline or via
            channels other than this website. Our Privacy Policy was created
            with the help of the Free Privacy Policy Generator.
          </p>

          <div id="consent">
            <h3 className="my-4">Consent</h3>

            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
          </div>

          <div>
            <h3 id="collect" className="my-4">
              Information we collect
            </h3>

            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information
              through website, mobile or mobile app.
            </p>

            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>

            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>
          </div>

          <div id="useinfo">
            <h3 className="my-4">How we use your information</h3>
            <p>
              We use the information we collect in various ways, including to:
            </p>

            <ul>
              <li>Provide, operate, and maintain our website</li>
              <li>Improve, personalize, and expand our website</li>
              <li>
                Understand and analyse what&nbsp; you are looking for &nbsp;how
                you use our website
              </li>
              <li>
                Develop new products, services, features, and functionality
              </li>
              <li>
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </li>
              <li>
                Send you information emails about your Search, Admission, other
                applications&nbsp;{" "}
              </li>
              <li>Find and prevent fraud</li>
            </ul>
          </div>

          <h3 className="my-4" id="personalinfo">
            How we protect your personal information
          </h3>

          <ul>
            <li>
              In order to protect your personal information from unauthorized
              access, we adopt reasonable security practices and procedures, in
              line with international standard, to include, technical,
              operational, managerial and physical security controls or
              disclosure while it is under our control.
            </li>
            <li>
              Prevent unauthorized access, maintain data accuracy, and ensure
              the correct use of information, to safeguard and secure the
              information and data stored on our system /Server.
            </li>
            <li>
              Specialized security teams constantly review and improve all
              measures to protect your personal information from unauthorized
              access, accidental loss, disclosure or destruction.
            </li>
            <li>
              No data transmission over the Internet is completely secure and
              for reasons outside of our control, security risks may still
              arise. Any personal information transmitted to us or from online
              products or services will therefore be at your own risk. However,
              we will strive to ensure the security of your information. We
              observe reasonable security measures to protect your personal
              information against hacking and virus dissemination.
            </li>
            <li>
              User Profile, forms will be encrypted the information by using
              Secure Socket Layer technology (SSL).
            </li>
          </ul>

          <h3 className="my-4" id="logfiles">
            Log files
          </h3>

          <p>
            admissionpedia.com &nbsp;follows a standard procedure of using log
            files. These files log visitors when they visit websites. All
            hosting companies do this and a part of hosting services' analytics.
            The information collected by log files include internet protocol
            (IP) addresses, browser type, Internet Service Provider (ISP), date
            and time stamp, referring/exit pages, and possibly the number of
            clicks. These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </p>
          <p>
            <b>Cookies and Web Beacons</b>
          </p>
          <p>
            Like any other website, admissionpedia.com &nbsp;uses 'cookies'.
            These cookies are used to store information including visitors'
            preferences, and the pages on the website that the visitor accessed
            or visited. The information is used to optimize the users'
            experience by customizing our web page content based on visitors'
            browser type and/or other information.
          </p>
          <p>
            For more general information on cookies, please read&nbsp;
            <a href="https://www.privacypolicyonline.com/what-are-cookies/">
              "What Are Cookies" from Cookie Consent
            </a>
            .
          </p>
          <p>
            <b>Our Advertising Partners</b>
          </p>
          <p>
            Some of advertisers on our site may use cookies and web beacons. Our
            advertising partners are listed below. Each of our advertising
            partners has their own Privacy Policy for their policies on user
            data. For easier access, we hyperlinked to their Privacy Policies
            below.
          </p>
          <ul>
            <li>Google&nbsp; AdSense </li>
          </ul>
          <p>
            <a href="https://policies.google.com/technologies/ads">
              https://policies.google.com/technologies/ads
            </a>
          </p>
          <p>
            <b>Advertising Partners Privacy Policies</b>
          </p>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of admissionpedia.com .
          </p>
          <p>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on
            admissionpedia.com , which are sent directly to users' browser. They
            automatically receive your IP address when this occurs. These
            technologies are used to measure the effectiveness of their
            advertising campaigns and/or to personalize the advertising content
            that you see on websites that you visit.
          </p>
          <p>
            Note that admissionpedia.com &nbsp;has no access to or control over
            these cookies that are used by third-party advertisers.
          </p>
          <p>&nbsp;</p>
          <p>
            <b>Third Party Privacy Policies</b>
          </p>
          <p>
            admissionpedia.com 's Privacy Policy does not apply to other
            advertisers or websites. Thus, we are advising you to consult the
            respective Privacy Policies of these third-party ad servers for more
            detailed information. It may include their practices and
            instructions about how to opt-out of certain options.
          </p>
          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </p>
          <p>
            <b>CCPA Privacy Rights (Do Not Sell My Personal Information)</b>
          </p>
          <p>Under the CCPA, among other rights consumers have the right to:</p>
          <p>
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>
          <p>
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>
          <p>
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
          <p>
            <b>GDPR Data Protection Rights</b>
          </p>
          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <p>
            The right to access &ndash; You have the right to request copies of
            your personal data. We may charge you a small fee for this service.
          </p>
          <p>
            The right to rectification &ndash; You have the right to request
            that we correct any information you believe is inaccurate. You also
            have the right to request that we complete the information you
            believe is incomplete.
          </p>
          <p>
            The right to erasure &ndash; You have the right to request that we
            erase your personal data, under certain conditions.
          </p>
          <p>
            The right to restrict processing &ndash; You have the right to
            request that we restrict the processing of your personal data, under
            certain conditions.
          </p>
          <p>
            The right to object to processing &ndash; You have the right to
            object to our processing of your personal data, under certain
            conditions.
          </p>
          <p>
            The right to data portability &ndash; You have the right to request
            that we transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
          <p>
            <b>Children's Information</b>
          </p>
          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p>
            admissionpedia.com &nbsp;does not knowingly collect any Personal
            Identifiable Information from children under the age of 13. If you
            think that your child provided this kind of information on our
            website, we strongly encourage you to contact us immediately and we
            will do our best efforts to promptly remove such information from
            our records.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import cookie from "js-cookie";
import Layout from "../Layout/Layout";
import styles from "../../styles/SearchResults.module.css";
import DashboardSidebar from "./DashboardSidebar";
import LoginFirst from "../LoginFirst/LoginFirst";
import SpeedDialTooltipOpen from "./NavigateDial/SpeedNavigate";
import ArticleIcon from "@mui/icons-material/Article";
import StarsIcon from "@mui/icons-material/Stars";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import { useMediaQuery } from "@mui/material";
import useAuth from "src/utils/useAuth";
import Loading from "../Loading/Loading";

const LayoutDashboard = ({ children }) => {
  const { token, decoded } = useAuth();
  const email = decoded.username;

  const actions = [
    { icon: <LogoutIcon />, name: "Logout", link: "/Dashboard/logout" },
    { icon: <DashboardIcon />, name: "Dashboard", link: "/Dashboard" },
    {
      icon: <ArticleIcon />,
      name: "Applications",
      link: "/Dashboard/applied-schools",
    },
    {
      icon: <StarsIcon />,
      name: "Shortlist",
      link: "/Dashboard/shortlisted-schools",
    },
    {
      icon: <SentimentSatisfiedAltIcon />,
      name: "Profile",
      link: "/Dashboard/my-profile",
    },
  ];

  const isSmallScreen = useMediaQuery("(max-width:900px)");
  let displayNone;
  if (isSmallScreen) {
    displayNone = "d-none";
  } else {
    displayNone = "";
  }

  const [isLogged, setIsLogged] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);

    if (token) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }

    setIsLoading(false);
  }, [token]);

  // Show the loading spinner if still loading
  if (isLoading) {
    return <Loading height="100vh" />;
  }

  return (
    <Typography component="div">
      {isLogged ? (
        <Layout>
          <div className={styles.mainContainer}>
            <div className="w-[100%]">
              <div className="flex lg:gap-24 md:gap-2  w-[100%] lg:px-[5%] md:px-[2%] sm:px-[2%] px-[2%]">
                <div>
                  <div className={`d-lg-flex ${displayNone}`}>
                    <DashboardSidebar />
                  </div>
                </div>
                <div className="w-[100%]">{children}</div>
              </div>
            </div>
          </div>
          <SpeedDialTooltipOpen actions={actions} />
        </Layout>
      ) : (
        <>
          <LoginFirst />
        </>
      )}
    </Typography>
  );
};

export default LayoutDashboard;

import React from "react";
import { Modal, Fade, Box, Backdrop, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Moment from "moment";
import { classStyle } from "./result.styles.js";

const ClassModal = ({
  openClassModal,
  item,
  setOpenClassModal,
  handleDownload,
  decoded,
  router,
}) => {
  const handleSessionAction = (session, classDate) => {
    console.log(
      "handleSessionAction called with session:",
      session,
      "and classDate:",
      classDate
    );

    if (session.admission_system === "link") {
      const link = session.external_links.find(
        (link) => link.class_id === classDate.class_id
      )?.link;
      console.log("Redirecting to external link:", link);
      window.open(link, "_blank");
    } else if (session.admission_system === "download") {
      const filePath = session.download_forms.find(
        (form) => form.class_id === classDate.class_id
      )?.file_path;
      console.log("Downloading form from:", filePath);
      handleDownload(filePath);
    } else if (
      session.admission_system === "regular" ||
      session.admission_system === "enquiry"
    ) {
      const url =
        "/apply/" +
        item.id +
        "?classID=" +
        classDate.class_id +
        "&className=" +
        classDate.class_id;
      console.log("Redirecting to:", url);
      router.push(url);
    }
  };

  const groupSessionsByYear = (sessions) => {
    return sessions.reduce((acc, session) => {
      const yearName = session.academic_year?.name;
      if (yearName) {
        if (!acc[yearName]) {
          acc[yearName] = [];
        }
        acc[yearName].push(session);
      }
      return acc;
    }, {});
  };

  const sessionsByYear = groupSessionsByYear(item.admission_sessions);

  const handleClose = () => {
    setOpenClassModal({ ...openClassModal, [item.id]: false });
  };

  return (
    openClassModal[item.id] && (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openClassModal[item.id]}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openClassModal[item.id]}>
          <Box sx={classStyle}>
            <CloseIcon
              style={{
                float: "right",
                cursor: "pointer",
                color: "gray",
                marginBottom: "1rem",
              }}
              onClick={() => {
                setOpenClassModal({
                  ...openClassModal,
                  [item.id]: false,
                });
              }}
            />
            {Object.keys(sessionsByYear).map((year) => (
              <div key={year}>
                <h3 className="pb-2 pl-2">{year}</h3>
                {sessionsByYear[year].map((session) =>
                  session.class_dates.map((classDate) => (
                    <div
                      className="border-top py-2"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingLeft: "1rem",
                      }}
                      key={classDate.id}
                    >
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            fontSize: "0.9rem",
                            fontFamily: "Space Grotesk",
                            color: "gray",
                            fontWeight: "bold",
                          }}
                        >
                          {
                            item.classes.find(
                              (cls) => cls.id === classDate.class_id
                            )?.name
                          }
                        </div>
                      </div>
                      <div style={{ flex: 2 }}>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "0.9rem",
                            fontFamily: "Space Grotesk",
                          }}
                        >
                          {classDate.start_date
                            ? Moment(classDate.start_date).format("ll")
                            : null}{" "}
                          -{" "}
                          {classDate.end_date
                            ? Moment(classDate.end_date).format("ll")
                            : null}
                        </div>
                      </div>
                      <div style={{ flex: 1, textAlign: "center" }}>
                        {Moment(classDate.end_date).isBefore(
                          Moment().format("YYYY-MM-DD")
                        ) ? (
                          <div
                            style={{
                              color: "#D31515",
                              textDecoration: "none",
                              textAlign: "center",
                              fontSize: "0.9rem",
                              borderRadius: "5px",
                              padding: "1rem",
                              width: "100%",
                            }}
                          >
                            Admission closed
                          </div>
                        ) : Moment(classDate.start_date).isAfter(
                            Moment().format("YYYY-MM-DD") + " 23:59:59"
                          ) ? (
                          <div
                            style={{
                              color: "#F89B2D",
                              textDecoration: "none",
                              textAlign: "center",
                              fontSize: "0.9rem",
                              padding: "1rem",
                              borderRadius: "5px",
                            }}
                          >
                            Admission not started
                          </div>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              backgroundColor: "#F77EAA",
                              "&:hover": {
                                backgroundColor: "#F99CBD",
                              },
                              padding: "0.5rem 1rem",
                              fontSize: "0.9rem",
                            }}
                            onClick={() =>
                              handleSessionAction(session, classDate)
                            }
                          >
                            {session.admission_system === "link"
                              ? "Apply on school site"
                              : session.admission_system === "download"
                              ? "Download Form"
                              : "Apply Now"}
                          </Button>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            ))}
          </Box>
        </Fade>
      </Modal>
    )
  );
};

export default ClassModal;

import React from "react";
import AuthenticationPageLayout from "../../components/Authentication/Layout/AuthenticationPageLayout";
import Layout from "../../components/Layout/Layout";
import OtpEnquiry from "src/components/Enquiry/OtpEnquiry";
import { ToastContainer } from "react-toastify";

const Index = () => {
  return (
    <Layout>
      <AuthenticationPageLayout>
        <OtpEnquiry />
      </AuthenticationPageLayout>
      <ToastContainer position="bottom left" />
    </Layout>
  );
};

export default Index;

import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Loading from "../Loading/Loading";
import classesOptions from "src/utils/classes";
import axios from "axios";
import { API_URL } from "src/utils/constant";
import { Button } from "@material-tailwind/react";
import EnquiryModal from "../SearchResults/EnquiryModal";

const theme = createTheme({
  palette: {
    primary: {
      main: pink[500],
    },
  },
});

const getClassById = (id) => {
  const classOption = classesOptions.find(
    (classOption) => classOption.id === id
  );
  return classOption ? classOption.name : "Unknown";
};

const SchoolDetails = ({ schoolData }) => {
  const [activeSection, setActiveSection] = useState(null);
  const [isFixed, setIsFixed] = useState(true);
  const [downloadLinks, setDownloadLinks] = useState({});
  const [openEnquiryModal, setOpenEnquiryModal] = useState({});
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedAcademicYear, setAcademicYearId] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".content > div");
      const viewportTop = window.scrollY - 100;
      let currentSectionId = null;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;

        if (sectionTop <= viewportTop) {
          currentSectionId = section.id;
        }
      });

      setActiveSection(currentSectionId);

      const scrollThreshold = 750;
      if (window.scrollY < scrollThreshold) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (schoolData?.admission_sessions) {
      schoolData.admission_sessions.forEach((session) => {
        if (session.download_forms && session.download_forms.length > 0) {
          session.download_forms.forEach(async (form) => {
            const response = await axios.get(
              `${API_URL}/public/admission/download-form/${form.id}`
            );
            setDownloadLinks((prevLinks) => ({
              ...prevLinks,
              [form.id]: response.data.data.file_download_link,
            }));
          });
        }
      });
    }
  }, [schoolData]);

  const handleOpenEnquiryModal = (session, classId) => {
    setSelectedClass(classId);
    setSelectedSession(session);
    setAcademicYearId(session.academic_year_id);
    setOpenEnquiryModal((prevState) => ({ ...prevState, [session.id]: true }));
  };

  const handleCloseEnquiryModal = (session) => {
    setOpenEnquiryModal((prevState) => ({ ...prevState, [session.id]: false }));
    setSelectedClass(null);
    setSelectedSession(null);
    setAcademicYearId(null);
  };

  if (!schoolData) {
    return <Loading height={"100vh"} />;
  }

  const FacilityItem = ({ available, label }) => (
    <div className="flex items-center mb-2">
      <div
        className={`rounded-md p-1 h-5 w-5 flex justify-center items-center ${
          available ? "bg-pink-200" : "bg-gray-400"
        } text-white mr-2`}
      >
        {available ? (
          <CheckIcon sx={{ fontSize: 15 }} />
        ) : (
          <CloseIcon sx={{ fontSize: 15 }} />
        )}
      </div>
      <span className={available ? "text-pink-200" : "text-gray-500"}>
        {label}
      </span>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="flex relative w-full bg-[#FFF6F9]">
        <div className="w-1/6 h-full z-0 absolute py-[4%] bg-white shadow-md lg:block md:hidden sm:hidden">
          <nav className={`${isFixed ? "fixed top-[10%]" : ""}`}>
            <a
              href="#school-details"
              className={`block py-2 px-4 mb-2 text-lg font-semibold opacity-60 ${
                activeSection === "school-details"
                  ? "text-pink-500 border-l-4 border-pink-500"
                  : ""
              }`}
            >
              School Details
            </a>
            <a
              href="#admission-forms"
              className={`block py-2 px-4 mb-2 text-lg font-semibold opacity-60 ${
                activeSection === "admission-forms"
                  ? "text-pink-500 border-l-4 border-pink-500"
                  : ""
              }`}
            >
              Admission Sessions
            </a>

            {/* <a
              href="#fees-structure"
              className={`block py-2 px-4 mb-2 text-lg font-semibold opacity-60 ${
                activeSection === "fees-structure"
                  ? "text-pink-500 border-l-4 border-pink-500"
                  : ""
              }`}
            >
              Fees Structure
            </a> */}

            <a
              href="#admission-forms"
              className={`block py-2 px-4 mb-2 text-lg font-semibold opacity-60 ${
                activeSection === "admission-process"
                  ? "text-pink-500 border-l-4 border-pink-500"
                  : ""
              }`}
            >
              Admission Process
            </a>
            {/* <a
              href="#facilities"
              className={`block py-2 px-4 mb-2 text-lg font-semibold opacity-60 ${
                activeSection === "facilities"
                  ? "text-pink-500 border-l-4 border-pink-500"
                  : ""
              }`}
            >
              Facilities
            </a>
            <a
              href="#achievements"
              className={`block py-2 px-4 mb-2 text-lg font-semibold opacity-60 ${
                activeSection === "achievements"
                  ? "text-pink-500 border-l-4 border-pink-500"
                  : ""
              }`}
            >
              Achievements
            </a> */}
            <a
              href="#contact-details"
              className={`block py-2 px-4 mb-2 text-lg font-semibold opacity-60  ${
                activeSection === "contact-details"
                  ? "text-pink-500 border-l-4 border-pink-500"
                  : ""
              }`}
            >
              Contact Details
            </a>
          </nav>
        </div>

        <div className="lg:ml-[16.66666%] md:w-full p-8 lg:w-5/6  sm:w-full content">
          <div
            id="school-details"
            className="bg-white h-auto  p-4 shadow-sm rounded-lg  mb-8"
          >
            <h2 className="text-2xl font-bold mb-4">{schoolData?.name}</h2>
            <p>{schoolData?.about}</p>
          </div>
          <div
            id="school-details"
            className="bg-white h-auto  p-4 shadow-sm rounded-lg flex flex-wrap justify-between mb-8"
          >
            <div>
              <p className="text-gray-600">Ownership</p>
              <p className="text-black font-bold">{schoolData?.head}</p>
            </div>
            <div>
              <p className="text-gray-600">Board</p>
              <p className="text-black font-bold">{schoolData?.board}</p>
            </div>
            <div>
              <p className="text-gray-600">Medium</p>
              <p className="text-black font-bold">{schoolData?.medium}</p>
            </div>
            <div>
              <p className="text-gray-600">Status</p>
              <p className="text-black font-bold ">
                {schoolData?.status &&
                  schoolData.status.charAt(0).toUpperCase() +
                    schoolData.status.slice(1)}
              </p>
            </div>
            <div>
              <p className="text-gray-600">Residency</p>
              <p className="text-black font-bold">
                {schoolData?.residency_type}
              </p>
            </div>
            <div>
              <p className="text-gray-600">Gender</p>
              <p className="text-black font-bold">
                {schoolData?.gender_accepted}
              </p>
            </div>
          </div>

          <div
            id="admission-forms"
            className="h-auto my-4 p-4 bg-white shadow-sm rounded-lg"
          >
            <h2 className="text-2xl font-bold mb-4">Admission Sessions</h2>
            {schoolData?.admission_sessions &&
            schoolData?.admission_sessions.length > 0 ? (
              schoolData.admission_sessions.map((session) => (
                <div
                  key={session.id}
                  className="h-auto my-4 p-4 bg-white shadow-md rounded-lg"
                >
                  <div className="text-2xl font-bold mb-4 flex gap-3">
                    {session.name}{" "}
                    <Chip
                      color={
                        session.status === "open"
                          ? "success"
                          : session.status === "closed"
                          ? "error"
                          : "warning"
                      }
                      variant="outlined"
                      size="medium"
                      label={`${session.status.toUpperCase()}`}
                    />
                  </div>

                  <div className="my-4 text-xl">
                    <p>
                      <span className="font-bold">Academic Year:</span>{" "}
                      {session.academic_year.name}
                    </p>
                  </div>

                  <div className="my-4 ">
                    {session.class_dates && session.class_dates.length > 0 ? (
                      <TableContainer
                        className="shadow-none "
                        component={Paper}
                      >
                        <Table className="min-w-full bg-white ">
                          <TableHead>
                            <TableRow>
                              <TableCell className="py-2">Class</TableCell>
                              <TableCell className="py-2">Start Date</TableCell>
                              <TableCell className="py-2">End Date</TableCell>
                              <TableCell className="py-2">Status</TableCell>
                              <TableCell className="py-2"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {session.class_dates.map((classDate, index) => (
                              <TableRow
                                key={index}
                                className={index % 2 === 0 ? "bg-gray-50" : ""}
                              >
                                <TableCell className="py-2">
                                  {getClassById(classDate.class_id)}
                                </TableCell>
                                <TableCell className="py-2">
                                  {new Date(
                                    classDate.start_date
                                  ).toLocaleString()}
                                </TableCell>
                                <TableCell className="py-2">
                                  {new Date(
                                    classDate.end_date
                                  ).toLocaleString()}
                                </TableCell>
                                <TableCell className="py-2">
                                  {classDate.status}
                                </TableCell>
                                <TableCell className="py-2">
                                  {session.admission_system === "enquiry" ? (
                                    <Button
                                      color="pink"
                                      size="sm"
                                      onClick={() => {
                                        handleOpenEnquiryModal(
                                          session,
                                          classDate.class_id
                                        );
                                        console.log(
                                          "enquiry modal",
                                          session,
                                          classDate.class_id
                                        );
                                      }}
                                      disabled={session.status === "closed"}
                                    >
                                      Apply
                                    </Button>
                                  ) : (
                                    <Button
                                      color="pink"
                                      size="sm"
                                      onClick={() => {}}
                                      disabled={session.status === "closed"}
                                    >
                                      Apply
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <p>No class dates available</p>
                    )}
                  </div>

                  {session.download_forms &&
                  session.download_forms.length > 0 ? (
                    <div className="my-4">
                      <h3 className="text-xl font-bold mb-2">
                        Download Admission Forms
                      </h3>
                      <Table className="min-w-full bg-white">
                        <TableHead>
                          <TableRow>
                            <TableCell className="py-2">Class</TableCell>
                            <TableCell className="py-2">
                              Download Link
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {session.download_forms.map((form, index) => (
                            <TableRow
                              key={index}
                              className={index % 2 === 0 ? "bg-gray-50" : ""}
                            >
                              <TableCell className="py-2">
                                {getClassById(form.class_id)}
                              </TableCell>
                              <TableCell className="py-2">
                                {downloadLinks[form.id] ? (
                                  <a
                                    href={downloadLinks[form.id]}
                                    className="text-pink-500 hover:underline"
                                  >
                                    Download
                                  </a>
                                ) : (
                                  <p>Loading...</p>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : null}

                  {session.external_links &&
                  session.external_links.length > 0 ? (
                    <div className="my-4">
                      <h3 className="text-xl font-bold mb-2">Apply forms</h3>
                      <Table className="min-w-full bg-white">
                        <TableHead>
                          <TableRow>
                            <TableCell className="py-2">Class</TableCell>
                            <TableCell className="py-2">Link to form</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {session.external_links.map((link, index) => (
                            <TableRow
                              key={index}
                              className={index % 2 === 0 ? "bg-gray-50" : ""}
                            >
                              <TableCell className="py-2">
                                {getClassById(link.class_id)}
                              </TableCell>
                              <TableCell className="py-2">
                                <a
                                  href={link.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-pink-400 "
                                >
                                  Apply
                                </a>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : null}

                  <div className="flex flex-col gap-2">
                    <span className="font-bold text-xl ">Guidelines:</span>
                    <span
                      className="custom-html"
                      dangerouslySetInnerHTML={{ __html: session.guidelines }}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p>No admission sessions available</p>
            )}
          </div>

          {/* <div
            id="fees-structure"
            className="h-auto my-4 p-4 bg-white shadow-sm rounded-lg"
          >
            <h2 className="text-2xl font-bold mb-4">Fee Structure</h2>
            <div className="mb-4">
              <Select defaultValue="Nursery" className="w-full">
                <MenuItem value="Nursery">Nursery</MenuItem>
              </Select>
            </div>
            <div>
              <div className="bg-white h-auto  p-4 shadow-sm rounded-lg flex flex-wrap justify-between mb-8">
                <div>
                  <p className="text-gray-600">School Fee</p>
                  <p className="text-black font-bold">
                    {schoolData.feesStructure.schoolFee}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600">Books Fee</p>
                  <p className="text-black font-bold">
                    {schoolData.feesStructure.booksFee}
                  </p>
                </div>
                <div>
                  <p className="text-gray-600">Uniform Fee</p>
                  <p className="text-black font-bold">
                    {schoolData.feesStructure.uniformFee}
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <div
            id="admission-process"
            className="h-auto my-4 p-4 bg-white shadow-sm rounded-lg"
          >
            <h2 className="text-2xl font-bold mb-4">Admission Process</h2>
            <span
              className="custom-html"
              dangerouslySetInnerHTML={{ __html: schoolData.admission_process }}
            />
          </div>
          {/* 
          <div
            id="facilities"
            className="h-auto my-4 p-4 bg-white shadow-sm rounded-lg"
          >
            <h2 className="text-2xl font-bold mb-4">Facilities</h2>
            {schoolData.facilities.map((facility, index) => (
              <FacilityItem
                key={index}
                available={facility.available}
                label={facility.label}
              />
            ))}
          </div> */}
          {/* 
          <div
            id="achievements"
            className="h-auto my-4 p-4 bg-white shadow-sm rounded-lg"
          >
            <h2 className="text-2xl font-bold mb-4">Achievements</h2>
            {schoolData.achievements.map((achievement, index) => (
              <div key={index} className="flex items-center mb-2">
                <EmojiEventsIcon className="text-pink-200 mr-2" />
                <span className="text-black">{achievement}</span>
              </div>
            ))}
          </div> */}

          <div
            id="contact-details"
            className="h-auto my-4 p-4 bg-white shadow-sm rounded-lg"
          >
            <h2 className="text-2xl font-bold mb-4">Contact Details</h2>
            <div>
              <p className="text-gray-600">Address</p>
              <p className="text-black font-bold">{schoolData.address}</p>
            </div>
            <div>
              <p className="text-gray-600">Phone</p>
              <p className="text-black font-bold">{schoolData.phone}</p>
            </div>
            <div>
              <p className="text-gray-600">Email</p>
              <p className="text-black font-bold">{schoolData.email}</p>
            </div>

            <div component="div" className="gmap_canvas">
              <iframe
                width="100%"
                height="300"
                id="gmap_canvas"
                src={`https://maps.google.com/maps?q=${schoolData.geolocation.coordinates[1]},${schoolData.geolocation.coordinates[0]}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* 
      {schoolData.admission_sessions && (
        <>
          {schoolData.admission_sessions.map((session) => (
            <>
              <h1>TESTING</h1>
              <EnquiryModal
                item={session}
                open={!!openEnquiryModal[session.id]}
                onClose={() => handleCloseEnquiryModal(session)}
                selectedClass={selectedClass}
                selectedSession={selectedSession}
              />
            </>
          ))}
        </>
      )} */}
    </ThemeProvider>
  );
};

export default SchoolDetails;

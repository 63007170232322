import React from "react";
import Configure from "../../components/SchoolDashboard/Configuration/Configure";
import PageLayout from "../../components/SchoolDashboard/Layout/PageLayout";

export const revalidate = 0;

const Configuration = () => {
  return (
    <>
      <PageLayout>
        <div className="conatiner">
          <div className="row pt-5">
            <div className="row mt-lg-5">
              <Configure />
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default Configuration;

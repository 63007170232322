import React from 'react'
import ContactUsForm from './ContactUsForm'
import styles from '../../../styles/ForSchool.module.css'

const ContactSection = () => {
  return (
    <div
    
      className='container-fluid py-5 text-center text-md-start'
      style={{
        backgroundColor: '#F4F4F4',
        fontFamily: 'Space Grotesk',
        color: '#000',
        margin: '10rem 0',
       
      }}
      data-scroll-to='contact'
      
    >
      <div className='row gap-4' >
        <div className='col-md-12 col-lg-4 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center my-5 px-lg-5 px-3'>
          <h1 className=' pb-4 text-black display-3 fw-bold '>School Admission made easier.</h1>
          <div className='text-black fs-3 display-5'>
            {
              "Have a question about pricing or need a demo?   Fill out the form our team will be in touch shortly. "
            }
          </div>
          
        </div>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center centering px-lg-5 px-1'>
          <ContactUsForm />
        </div>
      </div>
    </div>
  )
}

export default ContactSection

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* The side navigation menu */\n.PrivacyPolicy_sidebar__--dhs {\n  margin: 0;\n  padding: 2rem;\n  width: 400px;\n  font-weight: 800;\n  position: absolute;\n  height: 100%;\n  overflow: auto;\n  display: inline-block;\n}\n\n.PrivacyPolicy_sidebar__--dhs a {\n  display: block;\n  color: black;\n  padding: 16px;\n  text-decoration: none;\n}\n\n.PrivacyPolicy_sidebar__--dhs a:hover:not(.PrivacyPolicy_active__4Lt7F) {\n  color: rgb(134, 134, 134);\n}\n\ndiv.PrivacyPolicy_content__o9SFJ {\n  padding: 2rem 5rem;\n  margin-left: 250px;\n\n  height: 1000px;\n}\n\n@media screen and (max-width: 700px) {\n  .PrivacyPolicy_sidebar__--dhs {\n    display: none;\n  }\n\n  div.PrivacyPolicy_content__o9SFJ {\n    margin-left: 0;\n    padding: 2rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/styles/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,SAAS;EACT,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;;EAElB,cAAc;AAChB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,aAAa;EACf;AACF","sourcesContent":["/* The side navigation menu */\n.sidebar {\n  margin: 0;\n  padding: 2rem;\n  width: 400px;\n  font-weight: 800;\n  position: absolute;\n  height: 100%;\n  overflow: auto;\n  display: inline-block;\n}\n\n.sidebar a {\n  display: block;\n  color: black;\n  padding: 16px;\n  text-decoration: none;\n}\n\n.sidebar a:hover:not(.active) {\n  color: rgb(134, 134, 134);\n}\n\ndiv.content {\n  padding: 2rem 5rem;\n  margin-left: 250px;\n\n  height: 1000px;\n}\n\n@media screen and (max-width: 700px) {\n  .sidebar {\n    display: none;\n  }\n\n  div.content {\n    margin-left: 0;\n    padding: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "PrivacyPolicy_sidebar__--dhs",
	"active": "PrivacyPolicy_active__4Lt7F",
	"content": "PrivacyPolicy_content__o9SFJ"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import { Helmet } from 'react-helmet'
import FreeResources from 'src/components/FreeResources/FreeResources'
import Layout from 'src/components/Layout/Layout'


const index = () => {
  return <>
  <Layout>

    <Helmet>
    <title>Free resources</title>
    </Helmet>

    <FreeResources/>
   
  </Layout>
  </>
}

export default index

import React, { useState, useEffect, useContext } from "react";
import { useRouter } from "../../hooks/useRouter";
import Moment from "moment";
import { Box, Fade, Grid, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  MoreDetailsIcons,
  MoreDetailsIconsPhone,
} from "./Icons/MoreDetailsIcons";
import ActionButtons from "./Buttons/ActionButtons";
import Loading from "../Loading/Loading";
import FilterPhone from "../Filter/FilterPhone";
import styles from "../../styles/SearchResults.module.css";
import { API_URL } from "../../utils/constant";
import SearchSchools from "../Search/SearchSchools";
import { style, classStyle, mapStyle } from "./result.styles.js";
import { FilterContext } from "../../context/FilterContext";
import SearchResultsImage from "src/assets/image/SearchResult/school1.jpg";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { Helmet } from "react-helmet";
import Image from "../../assets/images/search-magnifier-with-a-cross.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import SchoolCard from "./SchoolCard";
import ClassModal from "./ClassModal";
import MapModal from "./MapModal";

const Results = ({
  searchTerm,
  setSearchTerm,
  locality,
  setLocality,
  pincodeTerm,
  setPincodeTerm,
}) => {
  const router = useRouter();
  Moment.locale("en");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalOpen, setModalOpen] = useState({});
  const [mapModalOpen, setMapModalOpen] = useState({});
  const [openClassModal, setOpenClassModal] = useState({});
  const [openEnquiryModal, setOpenEnquiryModal] = useState({});
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  const { filters } = useContext(FilterContext);

  const [token, setToken] = useState(Cookies.get("token"));
  const decoded = token ? jwtDecode(token) : null;

  useEffect(() => {
    fetchSchools();
  }, [filters]);

  const fetchSchools = async () => {
    setLoading(true);
    const url = new URL(window.location);
    const keyword = url.searchParams.get("keyword");
    setSearchTerm(keyword);

    const searchQuery = {
      keyword: keyword || undefined,
      locality: filters.location || undefined,
      pincode: filters.pincode || undefined,
      board: filters.board || undefined,
      gender: filters.gender || undefined,
      admissionStatus: filters.admissionStatus || undefined,
      district: filters.district || undefined,
      class: filters.class
        ? Array.isArray(filters.class)
          ? filters.class
          : [filters.class]
        : undefined,
      residencyType: filters.residencyType || undefined,
      status: filters.status || 1,
      page: 1, // Starting with the first page
    };

    // Remove undefined values
    Object.keys(searchQuery).forEach(
      (key) => searchQuery[key] === undefined && delete searchQuery[key]
    );

    const queryString = qs.stringify(searchQuery, { arrayFormat: "brackets" });

    try {
      const res = await axios.get(`${API_URL}/school?${queryString}`);
      const { rows, count, page, maxPages } = res.data;

      const formattedSchools = await getFormattedSchools(rows);
      setSchools(
        formattedSchools.sort(
          (a, b) => b.openAdmissions.length - a.openAdmissions.length
        )
      );

      // Use count, page, and maxPages for pagination purposes if needed
    } catch (error) {
      console.error("Error fetching schools:", error);
      setSchools(null);
    } finally {
      setLoading(false);
    }
  };

  const getFormattedSchools = async (data) => {
    return await Promise.all(
      data.map((item) => {
        const locationForMap = item.location
          ? `${item.name}, ${item.address}, ${item.location.district_name}, ${item.location.state_name}, ${item.location.country_name}`
          : "";
        return {
          ...item,
          classMap: item.classes.map((item) => item?.name),
          locationForMap,
          openAdmissions: item.classes.filter(
            (item) => item.admission_status === 1
          ),
          sortedClasses: item.classes
            .filter((item) => item.admission_status === 1)
            .concat(item.classes.filter((item) => item.admission_status !== 1)),
        };
      })
    );
  };
  const handleDownload = (id) => {
    // Implement your download logic here
    console.log(`Downloading data for school with id: ${id}`);
  };

  return (
    <Typography component="div">
      <Helmet>
        <title>Search for school {searchTerm ? `- ${searchTerm}` : ""}</title>
      </Helmet>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          width: "95%",
          bgcolor: "white",
          border: "2px solid #000",
          borderRadius: 4,
          overflow: "scroll",
          margin: "auto",
          height: "90%",
          marginTop: "20%",
        }}
      >
        <Box>
          <CloseIcon
            style={{ float: "right", cursor: "pointer", color: "gray" }}
            onClick={handleClose}
          />
          <FilterPhone handleClose={handleClose} />
        </Box>
      </Modal>
      <Typography
        component="div"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid container>
          <div
            className={`${styles.cardComponent}  mx-auto `}
            style={{ width: "100%", borderRadius: "30px", cursor: "pointer" }}
          >
            <SearchSchools
              searchTerm={searchTerm}
              refreshSchools={fetchSchools}
            />
            <div
              style={{
                float: "right",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                color: "gray",
                cursor: "pointer",
              }}
              className="d-lg-none d-flex"
              onClick={handleOpen}
            >
              Filter By &nbsp; <FilterAltIcon />
            </div>
            {!searchTerm && !filters.location && filters.district && (
              <div className="mt-4">
                <div className="text-red-500">
                  Select the locality or keyword
                </div>
              </div>
            )}
            {!searchTerm && !filters.location && !filters.pincode && (
              <div className="mt-4">
                <div className="centering">
                  Use the search bar above to find schools.
                </div>
              </div>
            )}
          </div>
          {loading ? (
            <div
              className={`my-4 mx-auto p-0`}
              style={{ width: "100%", borderRadius: "25px", cursor: "pointer" }}
            >
              <Loading />
            </div>
          ) : schools && schools.length > 0 ? (
            schools.map((item) => (
              <SchoolCard
                key={item.id}
                item={item}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                mapModalOpen={mapModalOpen}
                setMapModalOpen={setMapModalOpen}
                openClassModal={openClassModal}
                setOpenClassModal={setOpenClassModal}
                SearchResultsImage={SearchResultsImage}
                openEnquiryModal={openEnquiryModal}
                setOpenEnquiryModal={setOpenEnquiryModal}
                handleDownload={handleDownload} // Pass handleDownload
                decoded={decoded} // Pass decoded
                router={router} // Pass router
              />
            ))
          ) : (
            searchTerm && (
              <div className="mt-16 text-center flex flex-column w-100 h-[80vh] justify-center items-center">
                <img
                  src={Image}
                  className="opacity-10 shadow-sm w-40"
                  alt="No results"
                />
                <div className="opacity-70 text-md leading-8">
                  <ul className="p-0">
                    <div className="pb-4 fw-bold text-xl pt-10 opacity-70">
                      No results found for "{searchTerm}"
                    </div>
                    <li>- Check your spelling for typos</li>
                    <li>- Broaden your search using the filters on the left</li>
                    <li>
                      - Search using city name instead of locality name. (e.g)
                      Try entering Chennai instead of Adyar
                    </li>
                  </ul>
                </div>
              </div>
            )
          )}
        </Grid>
      </Typography>
    </Typography>
  );
};

export default Results;

import React from "react";
import AuthenticationPageLayout from "../../components/Authentication/Layout/AuthenticationPageLayout";
import Signup from "../../components/Authentication/Signup/Signup";
import Layout from "../../components/Layout/Layout";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Sign up</title>
      </Helmet>

      <Signup />
    </>
  );
};

export default Index;

import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import Layout from "../Layout/Layout";
import styles from "../../styles/SearchResults.module.css";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import SideBarClient from "../../components/ClientDashboard/SideBarClient";
import SpeedDialTooltipOpen from "../Dashboard/NavigateDial/SpeedNavigate";
import ArticleIcon from "@mui/icons-material/Article";

import { useMediaQuery } from "@mui/material";

const LayoutClient = ({ children }) => {
  const actions = [
    {
      icon: <ArticleIcon />,
      name: "School List",
      link: "/client-dashboard",
    },
    {
      icon: <GroupIcon />,
      name: "Admins",
      link: "/client-dashboard/school-admins",
    },
    {
      icon: <PersonIcon />,
      name: "Sub Accounts",
      link: "/client-dashboard/sub-accounts",
    },
  ];

  const isSmallScreen = useMediaQuery("(max-width:900px)");
  let displayNone;
  if (isSmallScreen) {
    displayNone = "d-none";
  } else {
    displayNone = "";
  }

  //   const [isLogged, setIsLogged] = React.useState(false);
  //   const [isLoading, setIsLoading] = React.useState(true);

  //   // Show the loading spinner if still loading
  //   if (isLoading) {
  //     return <Loading height="100vh" />;
  //   }

  return (
    <Typography component="div">
      <Layout>
        <Typography component="div" className={styles.mainContainer}>
          <Container maxWidth="xl">
            <Grid container direction="row">
              <Grid item lg={3} md={4} sm>
                <div className={`d-lg-flex ${displayNone}`}>
                  <SideBarClient />
                </div>
              </Grid>
              <Grid item lg={9} md={8} sm={12} xs={12}>
                <main>{children}</main>
              </Grid>
            </Grid>
          </Container>
        </Typography>
        <SpeedDialTooltipOpen actions={actions} />
      </Layout>
    </Typography>
  );
};

export default LayoutClient;

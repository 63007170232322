import React from "react";
import { Link } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";

import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { useRouter } from "../../hooks/useRouter";
import styles from "../../styles/DashboardSidebar.module.css";

const SideBarItems = [
  {
    icon: <ArticleIcon />,
    name: "School List",
    link: "/client-dashboard",
  },
  {
    icon: <GroupIcon />,
    name: "School Admins",
    link: "/client-dashboard/school-admins",
  },
  {
    icon: <PersonIcon />,
    name: "Sub Accounts",
    link: "/client-dashboard/sub-accounts",
  },

  {
    name: "Logout",
    link: "/Dashboard/logout",
    icon: <LogoutIcon />,
  },
];

const DashboardSidebar = () => {
  const router = useRouter();
  const currentUrl = router.pathname;
  return (
    <div className="container px-4">
      <div className="">
        <div className=" mt-5 pt-5">
          <div className={styles.sidebar}>
            {SideBarItems.map((item, index) => (
              <div key={index} className="py-2">
                <Link
                  to={item.link}
                  className={
                    currentUrl === item.link
                      ? styles.sidebarItemActive
                      : styles.sidebarItem
                  }
                >
                  <div className={styles.sidebarItemIcon}>{item.icon}</div>
                  <div className={styles.sidebarItemName}>{item.name}</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSidebar;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { Formik } from "formik";
import axios from "axios";
import cookie from "js-cookie";
import Feilds from "../Fields/InputField";
import CheckBox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import styles from "../../../styles/Login.module.css";
import { API_URL } from "../../../utils/constant";
import useAuth from "../../../utils/useAuth";
import jwtDecode from "jwt-decode";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import image from "../../../assets/images/login.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import useFetch from "src/hooks/useFetch";

const Login = () => {
  const [Iferrors, setIfErrors] = React.useState("");
  const { setToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [captchaValid, setCaptchaValid] = useState(false);
  const google = window.google;

  const { handleGoogle, error } = useFetch(
    "https://api.admissionpedia.dev/api/user/google-login"
  );

  useEffect(() => {
    /* global google */

    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        // type: "standard",
        theme: "outline",
        // size: "small",
        text: "continue_with",
        shape: "rectangular",
      });

      // google.accounts.id.prompt()
    }
  }, [handleGoogle]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (!captchaValid) {
        setIfErrors("Please complete the CAPTCHA.");
        window.scrollTo(0, 0);
        return;
      }
      setLoading(true);
      setSubmitting(true);
      const { email, password } = values;
      const response = await axios
        .post(`${API_URL}` + `/user/login`, {
          userName: email,
          password,
        })
        .catch((err) => {
          console.log("Error ----", err);
          setIfErrors(err.response.data);
          setSubmitting(false);
          return;
        });

      if (!response) return;

      /* Getting the token from the response. */
      const token = response?.data;
      const decoded = token ? jwtDecode(token) : null;

      if (decoded && decoded.email_verified) {
        setToken(token);
        /* Setting the token in the local storage. */
        localStorage.setItem("token", token);
        /* Setting the token in the cookie. */
        cookie.set("token", token);
        /* Setting the token in the header of the axios request. */

        /* Setting the token in the header of the axios request. */
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        // Redirect the user to the dashboard/home page.
        window.location.href = "/Dashboard";

        console.log("decoded:", decoded);

        // window.location.href = '/Dashboard'
      } else if (decoded && !decoded.email_verified) {
        // Email is not verified, redirect the user to the OTP validation page.
        const data = {
          email: decoded.username,
          source: "email",
        };
        axios
          .get(`${API_URL}/user/otpResend`, { params: data })
          .then((response) => {})
          .catch((err) => {
            console.log(err);
          });
        const otpValidationUrl = `/otp-validation?token=${encodeURIComponent(
          token
        )}`;
        window.location.href = otpValidationUrl;
      } else if (!captchaValid) {
        setIfErrors("Please complete the CAPTCHA.");
      } else {
        setIfErrors("Invalid Credentials");
        // alert('Invalid Credentials')
        // window.location.href = '/login'
      }
    } catch (err) {
      setIfErrors(err.response.data.err);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };
  return (
    <>
      <MDBContainer fluid className={styles.containerBg}>
        <MDBRow>
          <MDBCol sm="6" className="login-wrapper">
            <div className="d-flex flex-row ps-5 pt-5">
              <div>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.91)",
                  }}
                  className="logoStyle"
                >
                  <span>Admission</span>
                  <span className="pedia">Pedia</span>
                </Link>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center h-custom-2 loginWidth pt-1">
              <h3
                className="fw-light mb-5 ps-5 pb-3 loginSub"
                style={{
                  letterSpacing: "1px",
                }}
              >
                Welcome back!
              </h3>

              <Formik
                /* Setting the initial values of the form. */
                initialValues={{ email: "", password: "" }}
                /* Validating the form. */
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Email is Required";
                  } else if (
                    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid email address";
                  }
                  if (!values.password) {
                    errors.password = "Password is Required";
                  } else if (values.password.length <= 7) {
                    errors.password = "Password must be at least 8 characters";
                  }
                  return errors;
                }}
                /* A function that is called when the form is submitted. */
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  // isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="ps-5 d-flex flex-column gap-2"
                  >
                    {Iferrors ? (
                      <div
                        className={styles.errorStyle}
                        role="alert"
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          backgroundColor: "#f8d7da",
                        }}
                      >
                        {Iferrors}
                      </div>
                    ) : null}

                    {errors.email && touched.email && errors.email ? (
                      <>
                        <Feilds
                          label="Email"
                          inputType="text"
                          name="email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.email}
                          errors="email"
                          classNameProp={styles.errorStyle}
                        />

                        <Typography
                          component="div"
                          textAlign={"end"}
                          className="mx-5 text-danger"
                        >
                          {" "}
                          {errors.email && touched.email && errors.email}{" "}
                        </Typography>
                      </>
                    ) : (
                      <Feilds
                        label="Email"
                        placeholder="Enter your Email "
                        inputType="text"
                        name="email"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.email}
                        errors="email"
                        classNameProp={styles.inputField}
                      />
                    )}
                    {errors.password && touched.password && errors.password ? (
                      <>
                        <Feilds
                          label="Password"
                          name="password"
                          inputType="password"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.password}
                          errors="password"
                          classNameProp={styles.errorStyle}
                        />

                        <Typography
                          component="div"
                          textAlign={"end"}
                          className="mx-5 text-danger"
                        >
                          {" "}
                          {errors.password &&
                            touched.password &&
                            errors.password}{" "}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Feilds
                          label="Password"
                          placeholder="Enter your password"
                          name="password"
                          inputType="password"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.password}
                          errors="password"
                          classNameProp={styles.inputField}
                        />
                      </>
                    )}
                    <CheckBox />
                    <ReCAPTCHA
                      className="w-[100%]"
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      size="normal"
                      onChange={(value) => {
                        setCaptchaValid(!!value);
                      }}
                    />

                    <Button
                      action="Sign in"
                      className=" tw-text-white"
                      disabled={!captchaValid}
                      loading={loading}
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    {loading ? (
                      <div>Loading....</div>
                    ) : (
                      <div id="loginDiv" className="w-[90%] mt-2"></div>
                    )}
                    <Typography
                      component="div"
                      margin={1}
                      textAlign={"end"}
                      className="mx-5 text-black-50"
                    >
                      {"Don't have account?"}
                      <Link to="/signup">
                        <span className="mx-1 fw-semibold text-decoration-underline text-dark">
                          Sign up
                        </span>
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </div>
          </MDBCol>

          <MDBCol sm="6" className="d-none d-sm-block px-0">
            <img
              src={image}
              alt="Login image"
              className="w-100 h-[110vh]"
              style={{ objectFit: "cover", objectPosition: "left" }}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Login;

import { Typography } from '@mui/material'
import React from 'react'

const ProfileAvatar = (props) => {
  const [userHaveImage, setUserHaveImage] = React.useState(false)

  return (
    <Typography as='div'>
      {!userHaveImage ? (
        <img
          src='/Header/default.jpg'
          width={40}
          height={40}
          className='rounded-circle border border-success'
          alt='Profile Avatar'
          onClick={props.handleOpenUserMenu}
        />
      ) : (
        <img
          src='/Header/test.jpg'
          width={40}
          height={40}
          className=' rounded-circle'
          alt='Profile Avatar'
          onClick={props.handleOpenUserMenu}
        />
      )}
    </Typography>
  )
}

export default ProfileAvatar

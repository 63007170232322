const classesOptions = [
  {
    id: 1,
    name: "Nursery",
    in_group: "S",
    position: 1,
  },
  {
    id: 2,
    name: "LKG",
    in_group: "S",
    position: 2,
  },
  {
    id: 3,
    name: "UKG",
    in_group: "S",
    position: 3,
  },
  {
    id: 4,
    name: "I",
    in_group: "S",
    position: 4,
  },
  {
    id: 5,
    name: "II",
    in_group: "S",
    position: 5,
  },
  {
    id: 6,
    name: "III",
    in_group: "S",
    position: 6,
  },
  {
    id: 7,
    name: "IV",
    in_group: "S",
    position: 7,
  },
  {
    id: 8,
    name: "V",
    in_group: "S",
    position: 8,
  },
  {
    id: 9,
    name: "VI",
    in_group: "S",
    position: 9,
  },
  {
    id: 10,
    name: "VII",
    in_group: "S",
    position: 10,
  },
  {
    id: 11,
    name: "VIII",
    in_group: "S",
    position: 11,
  },
  {
    id: 12,
    name: "IX",
    in_group: "S",
    position: 12,
  },
  {
    id: 13,
    name: "X",
    in_group: "S",
    position: 13,
  },
  {
    id: 14,
    name: "XI Arts",
    in_group: "S",
    position: 14,
  },
  {
    id: 15,
    name: "XI Commerce",
    in_group: "S",
    position: 15,
  },
  {
    id: 16,
    name: "XI Science",
    in_group: "S",
    position: 16,
  },
  {
    id: 17,
    name: "XI Vocational",
    in_group: "S",
    position: 17,
  },
  {
    id: 18,
    name: "XII Arts",
    in_group: "S",
    position: 18,
  },
  {
    id: 19,
    name: "XII Commerce",
    in_group: "S",
    position: 19,
  },
  {
    id: 20,
    name: "XII Science",
    in_group: "S",
    position: 20,
  },
  {
    id: 21,
    name: "XII Vocational",
    in_group: "S",
    position: 21,
  },
  {
    id: 22,
    name: "XI",
    in_group: "S",
    position: 22,
  },
  {
    id: 23,
    name: "XII",
    in_group: "S",
    position: 23,
  },
];

export default classesOptions;

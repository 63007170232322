import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Typography } from "@mui/material";
import { Formik } from "formik";
import Button from "../Button/Button";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import styles from "../../../styles/Login.module.css";

import { API_URL } from "../../../utils/constant";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import image from "../../../assets/images/login.jpg";
import ReCAPTCHA from "react-google-recaptcha";
import useFetch from "src/hooks/useFetch";

const Signup = () => {
  const [Iferrors, setIfErrors] = React.useState("");
  const [showField, setShowField] = React.useState(true);
  const [suggestions, setSuggestions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState("");
  const [inputClicked, setInputClicked] = React.useState(false);
  const [school_name, setSchoolName] = React.useState("");
  const [school_affiliation_no, setAffiliationNumber] = React.useState("");
  const [captchaValid, setCaptchaValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const google = window.google;

  const { handleGoogle, error } = useFetch(
    "https://api.admissionpedia.dev/api/user/google-login"
  );

  React.useEffect(() => {
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        // type: "standard",
        theme: "outline",
        // size: "small",
        text: "continue_with",
        shape: "rectangular",
      });

      // google.accounts.id.prompt()
    }
  }, [handleGoogle]);

  async function fetchSuggestions() {
    if (!inputValue) return;

    const res = await fetch(`${API_URL}/school?keyword=` + inputValue);
    const data = await res.json();
    console.log(">>> Data --");
    setSuggestions(
      data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          location: item.location.district_name,
        };
      })
    );
  }

  React.useEffect(() => {
    if (inputValue.length > 2 && inputClicked) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [inputValue, inputClicked]);

  let successMessage = false;

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    try {
      if (!captchaValid) {
        setIfErrors("Please complete the CAPTCHA.");
        window.scrollTo(0, 0);
        return;
      }

      setLoading(true);
      setSubmitting(true);

      // format the data object to send to the API
      const data = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        password: values.password,
        role: values.role,
      };

      if (values.teacher === "true") {
        data.school_name = inputValue;
        data.school_affiliation_no = school_affiliation_no;
      }

      axios
        .post(`${API_URL}/user/registration`, data)
        .then(() => {
          setSubmitting(false);
          successMessage = true;
          setShowField(false);
          window.location = "/login";
          resetForm();
          console.log("success");
        })
        .catch((err) => {
          /* Setting the error message to the error message returned from the server. */

          setIfErrors(err.response.data);
          console.log(err.response);
          window.scrollTo(0, 0);
          setSubmitting(false);
        });
    } catch (err) {
      window.scrollTo(0, 0);
      setIfErrors(err.response.data.err);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <MDBContainer fluid className={styles.containerBg}>
        <MDBRow>
          <MDBCol sm="6" className="login-wrapper ">
            <div className="d-flex flex-row ps-5 pt-5">
              <div>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.91)",
                  }}
                  className="logoStyle"
                >
                  <span>Admission</span>
                  <span className="pedia">Pedia</span>
                </Link>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center h-custom-2 loginWidth pt-1">
              <h3
                className="fw-light mb-5 ps-5 pb-3 loginSub"
                style={{
                  letterSpacing: "1px",
                }}
              >
                Create your account to start applying
              </h3>

              {showField ? (
                <>
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      mobile: "",
                      password: "",
                      confirmPassword: "",
                      role: "",
                      school_name: "",
                      school_affiliation_no: "",
                      teacher: "false",
                    }}
                    validate={(values) => {
                      const errors = {};

                      // Name Validation
                      if (!values.name) {
                        errors.name = "Name is required";
                      } else if (!/^[a-zA-Z ]+$/.test(values.name)) {
                        errors.name = "Name must be only alphabets";
                      }

                      // Email Validation
                      if (!values.email) {
                        errors.email = "Email is required";
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      } else if (values.email.length > 50) {
                        errors.email = "Email must be less than 50 characters";
                      } else if (
                        !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = "Invalid email address";
                      }

                      //Mobile Validation
                      if (!values.mobile) {
                        errors.mobile = "Mobile Number is required";
                      } else if (!/^[0-9]+$/.test(values.mobile)) {
                        errors.mobile = "Mobile Number must be only numbers";
                      } else if (values.mobile.length !== 10) {
                        errors.mobile = "Mobile Number must be 10 digits";
                      }

                      //Passowrd Validation
                      if (!values.password) {
                        errors.password = "Password is required";
                      } else if (values.password.length <= 7) {
                        errors.password =
                          "Password must be at least 8 characters";
                      } else if (/^[0-9]+$/.test(values.password)) {
                        errors.password =
                          "Password must contain at least one alphabet";
                      }

                      //Confirm Passowrd Validation
                      if (!values.confirmPassword) {
                        errors.confirmPassword = "Confirm Password is required";
                      } else if (values.password !== values.confirmPassword) {
                        errors.confirmPassword = "Password does not match";
                      }

                      //User Type Validation
                      if (!values.role) {
                        errors.role = "Required";
                      }
                      return errors;
                    }}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit} className="ps-5">
                        {successMessage ? (
                          <div
                            role="alert"
                            style={{
                              color: "#57cd6b",
                              textAlign: "center",
                              fontSize: "1rem",
                              padding: "10px",
                              marginBottom: "13px",
                              fontWeight: "bold",
                            }}
                          >
                            Account Created!
                          </div>
                        ) : null}

                        {Iferrors ? (
                          <div
                            className={styles.errorStyle}
                            role="alert"
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: "bold",
                              borderRadius: "5px",
                              backgroundColor: "#f8d7da",
                            }}
                          >
                            {Iferrors}
                          </div>
                        ) : null}

                        <Typography component="div" marginTop={2}>
                          <label className={styles.labelStyle}>Name</label>{" "}
                          <span className=" text-danger">*</span>
                        </Typography>
                        {errors.name && touched.name && errors.name ? (
                          <>
                            <input
                              name="name"
                              type="text"
                              className={styles.errorStyle}
                              placeholder={
                                errors.name && touched.name && errors.name
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.name}
                              required
                            />
                            <Typography
                              component="div"
                              textAlign={"end"}
                              className="mx-5 text-danger"
                            >
                              {errors.name && touched.name && errors.name}
                            </Typography>
                          </>
                        ) : (
                          <input
                            name="name"
                            type="text"
                            className={styles.inputField}
                            placeholder="Enter your name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            required
                          />
                        )}
                        <Typography component="div" marginTop={2}>
                          <label className={styles.labelStyle}>
                            Email Address
                          </label>{" "}
                          <span className=" text-danger">*</span>
                        </Typography>
                        {errors.email && touched.email && errors.email ? (
                          <>
                            <input
                              name="email"
                              type="email"
                              className={styles.errorStyle}
                              placeholder={
                                errors.email && touched.email && errors.email
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              required
                            />
                            <Typography
                              component="div"
                              textAlign={"end"}
                              className="mx-5 text-danger"
                            >
                              {errors.email && touched.email && errors.email}
                            </Typography>
                          </>
                        ) : (
                          <input
                            name="email"
                            type="email"
                            className={styles.inputField}
                            placeholder="Enter Email Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            required
                          />
                        )}
                        <Typography component="div" marginTop={2}>
                          <label className={styles.labelStyle}>
                            Mobile Number
                          </label>
                        </Typography>
                        {errors.mobile && touched.mobile && errors.mobile ? (
                          <>
                            <input
                              name="mobile"
                              type="text"
                              className={styles.errorStyle}
                              placeholder={
                                errors.mobile && touched.mobile && errors.mobile
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobile}
                              required
                            />
                            <Typography
                              component="div"
                              textAlign={"end"}
                              className="mx-5 text-danger"
                            >
                              {errors.mobile && touched.mobile && errors.mobile}
                            </Typography>
                          </>
                        ) : (
                          <input
                            name="mobile"
                            type="text"
                            className={styles.inputField}
                            placeholder="Enter mobile number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.mobile}
                            required
                          />
                        )}

                        <Typography component="div" marginTop={2}>
                          <label className={styles.labelStyle}>Password</label>{" "}
                          <span className=" text-danger">*</span>
                        </Typography>
                        {errors.password &&
                        touched.password &&
                        errors.password ? (
                          <>
                            <input
                              name="password"
                              type="password"
                              className={styles.errorStyle}
                              placeholder={
                                errors.password &&
                                touched.password &&
                                errors.password
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              required
                            />

                            <Typography
                              component="div"
                              textAlign={"end"}
                              className="mx-5 text-danger"
                            >
                              {errors.password &&
                                touched.password &&
                                errors.password}
                            </Typography>
                          </>
                        ) : (
                          <input
                            name="password"
                            type="password"
                            className={styles.inputField}
                            placeholder="Enter Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            required
                          />
                        )}
                        <Typography component="div" marginTop={2}>
                          <label className={styles.labelStyle}>
                            Confirm Password
                          </label>{" "}
                          <span className=" text-danger">*</span>
                        </Typography>
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <>
                            <input
                              name="confirmPassword"
                              type="password"
                              className={styles.errorStyle}
                              placeholder={
                                errors.confirmPassword &&
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              required
                            />

                            <Typography
                              component="div"
                              textAlign={"end"}
                              className="mx-5 text-danger"
                            >
                              {errors.confirmPassword &&
                                touched.confirmPassword &&
                                errors.confirmPassword}
                            </Typography>
                          </>
                        ) : (
                          <input
                            name="confirmPassword"
                            type="password"
                            className={styles.inputField}
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.confirmPassword}
                            required
                          />
                        )}
                        <Typography component="div" marginTop={2}>
                          <label className={styles.labelStyle}>
                            I am a <span className=" text-danger">*</span>
                          </label>
                        </Typography>
                        <select
                          name="role"
                          id=""
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.role}
                          className={styles.inputField}
                          required
                        >
                          <option value="">Select</option>
                          <option value="parent">Parent</option>
                          <option value="student">Student</option>
                        </select>
                        {errors.role && touched.role && errors.role ? (
                          <Typography
                            component="div"
                            textAlign={"end"}
                            className="mx-5 text-danger"
                          >
                            {errors.role && touched.role && errors.role}
                          </Typography>
                        ) : null}

                        {values.role === "parent" && (
                          <Typography component="div" marginTop={2}>
                            <div className={styles.labelStyle}>
                              Are you also a Teacher?
                              <span className="text-danger">*</span>
                              <div className="flex gap-16 py-3">
                                <div className="flex gap-2 items-baseline">
                                  <input
                                    type="radio"
                                    id="teacherYes"
                                    name="teacher"
                                    value="true"
                                    checked={values.teacher === "true"}
                                    onChange={handleChange}
                                  />
                                  <label htmlFor="teacherYes">Yes</label>
                                </div>
                                <div className="flex gap-2 items-baseline">
                                  <input
                                    type="radio"
                                    id="teacherNo"
                                    name="teacher"
                                    value="false"
                                    checked={values.teacher === "false"}
                                    onChange={handleChange}
                                  />
                                  <label htmlFor="teacherNo">No</label>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        )}

                        {values.role === "parent" &&
                          values.teacher === "true" && (
                            <Typography component="div" marginTop={2}>
                              <label className={styles.labelStyle}>
                                Enter School:
                              </label>

                              <input
                                name="school_name"
                                value={inputValue}
                                className={styles.inputField}
                                type="text"
                                placeholder="Enter your School"
                                onChange={(e) =>
                                  setInputValue(
                                    e.target.value.charAt(0).toUpperCase() +
                                      e.target.value.slice(1)
                                  ) &&
                                  setInputClicked(false) &&
                                  setSchoolName(e.target.value) &&
                                  handleChange(e)
                                }
                                onClick={() => setInputClicked(true)}
                                onBlur={handleBlur}
                              />

                              <input
                                name="school_affiliation_no"
                                value={school_affiliation_no}
                                type="text"
                                hidden
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />

                              {suggestions && suggestions.length > 0 && (
                                <div
                                  style={{
                                    width: "91%",
                                    height: `${
                                      suggestions.length > 5 ? "110px" : "auto"
                                    }`,
                                    overflow: "auto",
                                    zIndex: "100",
                                    backgroundColor: "white",
                                    borderRadius: "0 0 15px 15px",
                                    margin: "-9px 5px",
                                  }}
                                  className="shadow"
                                >
                                  {suggestions.map((suggestion) => (
                                    <div
                                      key={suggestion.value}
                                      style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                      }}
                                      className="hoverColor"
                                      onClick={() => {
                                        /* Setting the input value to the suggestion label and then clearing the suggestions. */
                                        setInputValue(suggestion.label);
                                        setAffiliationNumber(suggestion.value);
                                        setSuggestions([]);
                                      }}
                                    >
                                      {suggestion.label}, {suggestion.location}
                                    </div>
                                  ))}
                                </div>
                              )}
                              {/* <AutoCompleteSearch
                                inputValue={schoolName}
                                setInputValue={setSchoolName}
                                setSchoolId={setSchoolId}
                              /> */}
                            </Typography>
                          )}

                        {Object.keys(errors).length === 0 &&
                        values.name !== "" &&
                        values.email !== "" &&
                        values.password !== "" &&
                        values.confirmPassword !== "" &&
                        values.role !== "" &&
                        (values.teacher === "false" ||
                          (values.teacher === "true" &&
                            values.school_name !== "" &&
                            values.school_affiliation_no !== "")) ? (
                          <Typography
                            component="div"
                            marginTop={2}
                            className="text-success"
                            textAlign={"center"}
                          >
                            All fields are valid and ready to submit!{" "}
                            <span className="text-success">✔</span> <br />
                          </Typography>
                        ) : null}

                        <ReCAPTCHA
                          className="mt-8 mb-3 w-[100%]"
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          size="normal"
                          onChange={(value) => {
                            setCaptchaValid(!!value);
                          }}
                        />
                        <Button
                          action="Sign Up"
                          disabled={!captchaValid}
                          loading={loading}
                        />
                        {error && <p style={{ color: "red" }}>{error}</p>}
                        {loading ? (
                          <div>Loading....</div>
                        ) : (
                          <div
                            id="signUpDiv"
                            className="w-[90%] mt-3"
                            data-text="signup_with"
                          ></div>
                        )}
                        <Typography
                          component="div"
                          margin={1}
                          textAlign={"end"}
                          className="mx-5 text-black-50 mb-5"
                        >
                          Already have an account?
                          <Link to={`/login`}>
                            <span className="mx-1 fw-semibold text-decoration-underline text-dark">
                              Sign In
                            </span>
                          </Link>
                        </Typography>
                      </form>
                    )}
                  </Formik>
                </>
              ) : (
                <div className="text-center">
                  <DoneAllIcon
                    className="text-success"
                    style={{
                      fontSize: 100,
                      marginTop: 10,
                      marginBottom: 20,
                      color: "#4caf50",
                    }}
                  />
                  <Typography component="div" className="text-success">
                    Your account has been created successfully!
                  </Typography>
                </div>
              )}
            </div>
          </MDBCol>

          <MDBCol sm="6" className="d-none d-sm-block px-0">
            <img
              src={image}
              alt="Login image"
              style={{ objectFit: "cover", objectPosition: "left" }}
              className="w-100 h-100"
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Signup;

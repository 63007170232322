import React from "react";
import Login from "../../components/Authentication/Login/Login";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      <Login />
    </>
  );
};

export default Index;

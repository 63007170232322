import React from 'react'
import Services from '../../data/Home/Services'
import styles from 'src/styles/HeroSection.module.css'


const ServicesComponent = () => {
  return (
   
        <div data-aos='fade-up' data-aos-once="true"   className={`${styles.servicesContainer} row w-100`}>
          {Services.map((service, index) => (
            <div className='col-md-12 col-lg-4 mb-5' key={index + service.title + 1}>
              <div
                className='shadow rounded-5 bg m-2  hoverColor gap-3 '
                style={{ height: '100%' }}
              >
                <div className='card-body p-5 d-flex flex-column'>
                  <img
                    src={service.img}
                    width={50}
                    height={50}
                    alt={service.title}
                  />
                  <div className='mt-3'>
                    <h4>{service.title}</h4>
                  </div>
                  <div className='mt-3 overflow-hidden '>{service.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
    
  )
}

export default ServicesComponent

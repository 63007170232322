import React, { useEffect, useMemo } from "react";
import LayoutClient from "../../components/ClientDashboard/LayoutClient";
import { Helmet } from "react-helmet";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";
import Image from "./images/logoschool1.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const projects = [
  {
    name: "Affiliation",
    initials: "GA",
    href: "#",
    members: "CBSE",
    bgColor: "bg-pink-600",
  },
  {
    name: "Principal",
    initials: "CD",
    href: "#",
    members: "John Smith",
    bgColor: "bg-purple-600",
  },
  {
    name: "Gender",
    initials: "T",
    href: "#",
    members: "Co-ed",
    bgColor: "bg-yellow-500",
  },
  {
    name: "Residency",
    initials: "RC",
    href: "#",
    members: "Local",
    bgColor: "bg-green-500",
  },

  {
    name: "Medium",
    initials: "RC",
    href: "#",
    members: "On Campus",
    bgColor: "bg-green-500",
  },

  {
    name: "Phone",
    initials: "RC",
    href: "#",
    members: "+965 622 2357",
    bgColor: "bg-green-500",
  },

  {
    name: "Website",
    initials: "RC",
    href: "#",
    members: "www.purdue.com",
    bgColor: "bg-green-500",
  },
];

const ClientDashboard = () => {
  return (
    <Typography component="div">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <LayoutClient>
        <div>
          <Card className="w-full flex-row mt-20 mb-0 shadow-none">
            <CardBody className="w-full">
              <div className="flex gap-3">
                <img
                  src={Image}
                  alt="card-image"
                  className="object-cover"
                  style={{ width: "8%" }}
                />
                <div className="w-full">
                  <div className="flex justify-between">
                    <Typography variant="h6" className=" text-black text-lg">
                      Purdue University
                    </Typography>
                    <Typography variant="h6" className=" text-black fw-light">
                      182, Avvai Shanmugam Salai, Gopalapuram, Chennai, Tamil
                      Nadu 600086
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Button color="black" variant="outlined" size="sm">
                      Classes
                    </Button>

                    <div>
                      <Button variant="Danger" size="sm">
                        Delete
                      </Button>{" "}
                      <Button color="black" variant="outlined" size="sm">
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className=" bg-gray-100 h-full w-full shadow-sm py-2 pt-4">
            <ul
              role="list"
              className="px-3 grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-6 lg:grid-cols-7 "
            >
              {projects.map((project) => (
                <li key={project.name} className="col-span-1 flex rounded-md ">
                  <div className="flex flex-1 items-center justify-between ">
                    <div className="flex-1 truncate px-4 text-sm">
                      <a
                        href={project.href}
                        className="font-bold text-gray-900 hover:text-gray-600 text-md"
                      >
                        {project.name}
                      </a>
                      <p className="text-gray-600">{project.members} </p>
                    </div>
                    <div className="flex-shrink-0 pr-2">
                      <span className="sr-only">Open options</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <Card className="w-full flex-row mt-4 mb-0 shadow-none">
            <CardBody className="w-full">
              <div className="flex gap-3">
                <img
                  src={Image}
                  alt="card-image"
                  className="object-cover"
                  style={{ width: "8%" }}
                />
                <div className="w-full">
                  <div className="flex justify-between">
                    <Typography variant="h6" className=" text-black text-lg">
                      Purdue University
                    </Typography>
                    <Typography variant="h6" className=" text-black fw-light">
                      182, Avvai Shanmugam Salai, Gopalapuram, Chennai, Tamil
                      Nadu 600086
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Button color="black" variant="outlined" size="sm">
                      Classes
                    </Button>

                    <div>
                      <Button variant="Danger" size="sm">
                        Delete
                      </Button>{" "}
                      <Button color="black" variant="outlined" size="sm">
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className=" bg-gray-100 h-full w-full shadow-sm py-2 pt-4">
            <ul
              role="list"
              className="px-3 grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-6 lg:grid-cols-7 "
            >
              {projects.map((project) => (
                <li key={project.name} className="col-span-1 flex rounded-md ">
                  <div className="flex flex-1 items-center justify-between ">
                    <div className="flex-1 truncate px-4 text-sm">
                      <a
                        href={project.href}
                        className="font-bold text-gray-900 hover:text-gray-600 text-md"
                      >
                        {project.name}
                      </a>
                      <p className="text-gray-600">{project.members} </p>
                    </div>
                    <div className="flex-shrink-0 pr-2">
                      <span className="sr-only">Open options</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <Card className="w-full flex-row mt-4 mb-0 shadow-none">
            <CardBody className="w-full">
              <div className="flex gap-3">
                <img
                  src={Image}
                  alt="card-image"
                  className="object-cover"
                  style={{ width: "8%" }}
                />
                <div className="w-full">
                  <div className="flex justify-between">
                    <Typography variant="h6" className=" text-black text-lg">
                      Purdue University
                    </Typography>
                    <Typography variant="h6" className=" text-black fw-light">
                      182, Avvai Shanmugam Salai, Gopalapuram, Chennai, Tamil
                      Nadu 600086
                    </Typography>
                  </div>
                  <div className="flex justify-between">
                    <Button color="black" variant="outlined" size="sm">
                      Classes
                    </Button>

                    <div>
                      <Button variant="Danger" size="sm">
                        Delete
                      </Button>{" "}
                      <Button color="black" variant="outlined" size="sm">
                        Edit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <div className=" bg-gray-100 h-full w-full shadow-sm py-2 pt-4">
            <ul
              role="list"
              className="px-3 grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-6 lg:grid-cols-7 "
            >
              {projects.map((project) => (
                <li key={project.name} className="col-span-1 flex rounded-md ">
                  <div className="flex flex-1 items-center justify-between ">
                    <div className="flex-1 truncate px-4 text-sm">
                      <a
                        href={project.href}
                        className="font-bold text-gray-900 hover:text-gray-600 text-md"
                      >
                        {project.name}
                      </a>
                      <p className="text-gray-600">{project.members} </p>
                    </div>
                    <div className="flex-shrink-0 pr-2">
                      <span className="sr-only">Open options</span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </LayoutClient>
    </Typography>
  );
};

export default ClientDashboard;

import React from "react";
import styles from "../../styles/PrivacyPolicy.module.css";

const Article = () => {
  return (
    <>
      <div
        className="text-center p-lg-2 m-0 w-100  text-white"
        style={{ backgroundColor: "#F87FAA" }}
      >
        <h1 className="p-5">Best practices for School websites</h1>
      </div>

      <div style={{ display: "inline-block", position: "relative" }}>
        <div className="px-[5%] py-[5%]">
          <h3 className="mb-4">Best practices for School websites</h3>
          <p>
            In a digital era, school websites play multifaceted role, extending
            far beyond mere online representation. They function as dynamic
            hubs, with front-end accessible to the parents & students, and an
            exclusive admin panel to school staff. These websites empower
            private elementary, middle, and high schools across India to manage
            their ever-evolving online presence effectively.
          </p>
          <p>
            Developing an effective school website is paramount in the digital
            age. Admissionpedia, an experienced Edtech entrepreneur, is your
            trusted partner in this endeavor. Based on our 20-year experience
            here is our take on creating a school webpage that adheres to best
            practices. Here are key principles to optimize your private school's
            online presence:
          </p>
          <p>First things first, school will need to address:</p>
          <ul className="px-5">
            <li>1. Domain Name Registration </li>
            <li>2. Web Hosting</li>
            <li>3. School Email</li>
            <li>4. Bulk SMS</li>
          </ul>

          <p>
            In crafting an exceptional school website, it's vital to carefully
            structure its pages. Here's a comprehensive list of sections to
            consider. Each page plays a unique role in conveying your school's
            identity, information, and interactions, ensuring your website is a
            valuable resource for your community.
          </p>

          <ul className="px-5 pb-4">
            <li>1. Home</li>
            <li>2. About School</li>
            <li>3. Message from the Principal</li>
            <li>4. Management Detail</li>
            <li>5. School Curriculum</li>
            <li>6. School Facilities</li>
            <li>7. Co-Curricular Activities</li>
            <li>8. Contact Us</li>
            <li>9. Web Interface</li>
            <li>10. Alumni Section</li>
            <li>11. Online Admission Registration</li>
            <li>12. Mandatory disclosure</li>
            <li>13. News & Events</li>
            <li>14. Timetable</li>
            <li>15. Yearly Planner</li>
            <li>16. Gallery</li>
            <li>17. Downloads Section</li>
            <li>18. Online Transfer Certificate</li>
            <li>19. Teachers Detail Module</li>
            <li>20. Feedback Form</li>
            <li>21. School Specified Pages</li>
          </ul>

          <p>
            <strong>Web Interface:</strong> Both the front-end and back-end,
            including the CMS, are integral. A separate web interface for the
            front-end operates dynamically, allowing universal access on the
            World Wide Web. In the back-end (Admin Panel), an exclusive
            interface is designated for administrators/authorities. Through
            this, schools can autonomously manage their websites using the
            Content Management System (CMS).
          </p>

          <p>
            <strong>Email Facilities:</strong> Personalized, spam-free email
            services will be extended to the school, with the option to redirect
            messages to personal email IDs, such as principal@abcschool.com or
            info@abcschool.com.
          </p>

          <p>
            <strong>Alumni:</strong> An alumni association for former students
            to connect through newsletters, gatherings, and school events. It
            serves as a vital emotional platform for the school and its
            ex-student community.
          </p>

          <p>
            <strong>Online Admission Registration:</strong> Admission
            application forms are accessible on the website. Parents/applicants
            can directly fill out forms for their child's admission, which will
            reach the school administrator with all necessary student details.
            Later, approval by the Principal/Administrators is facilitated, with
            integration options for email and SMS notifications to applicants
            upon form submission.
          </p>

          <p>
            <strong>SMS to Parents/Teachers:</strong> The web application,
            accessible through an admin login, enables individual or bulk SMS
            communication to parents, staff, and management, conveying short or
            urgent messages from any location, at any time.
          </p>

          <p>
            <strong>Mandatory Disclosure:</strong> CBSE schools can easily
            update dynamic school information, ensuring compliance with
            regulatory requirements.
          </p>

          <p>
            <strong>Online TC Download:</strong> A unique number is assigned to
            students for viewing and verifying their Transfer Certificates (TC)
            from the school website.
          </p>

          <p>
            <strong>Content Management Systems (CMS Details):</strong> This
            software integrated into the website allows school administrators to
            maintain and update the school website dynamically through the
            back-end, enabling daily updates.
          </p>

          <p>
            <strong>Gallery Section:</strong> This feature facilitates the
            uploading of an unlimited number of photos, categorized by events,
            such as sports day, annual events, or cultural functions. The
            user-friendly online system simplifies the process.
          </p>

          <p>
            <strong>News & Events:</strong> Schools can post the latest news and
            events, reducing paper costs for parent and teacher notifications.
          </p>

          <p>
            {" "}
            For any further assistances reach us : admissionpedia@gmail.com
          </p>
        </div>
      </div>
    </>
  );
};

export default Article;

import { TurnedInOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from "../../styles/PartnerSchools.module.css";
import getRandomString from "../../utils/helper";
import randomGenerationString from "../../utils/helper";
import "src/styles/PartnerSchools.module.css";

import Logo1 from "src/assets/SchoolLogos/logo1.svg";
import Logo3 from "src/assets/SchoolLogos/logo3.svg";
import Logo4 from "src/assets/SchoolLogos/logo4.svg";
import Logo5 from "src/assets/SchoolLogos/logo5.svg";
import Logo6 from "src/assets/SchoolLogos/logo6.svg";
import Logo7 from "src/assets/SchoolLogos/logo7.svg";
import Logo8 from "src/assets/SchoolLogos/logo8.svg";
import Logo9 from "src/assets/SchoolLogos/logo9.svg";
import Logo10 from "src/assets/SchoolLogos/logo10.svg";
import Logo11 from "src/assets/SchoolLogos/logo11.svg";

const PartnerSchools = () => {
  const [isMobile, setIsMobile] = useState(false);

  const Logo = [
    {
      id: 1,
      name: " Cluny Convent High School",
      image: Logo1,
      city: "Bengaluru",
    },
    {
      id: 2,
      name: "Delhi Public School",
      image: Logo5,
      city: "",
    },
    {
      id: 3,
      name: "Indirapuram Public School ",
      image: Logo6,
      city: "Indirapuram",
    },
    {
      id: 11,
      name: "Nirmala Rani High School ",
      image: Logo11,
      city: "Bengaluru",
    },
    { id: 4, name: "Morning Bells Academy ", image: Logo9, city: "Kolkata" },
    { id: 5, name: " Navy Children School ", image: Logo3, city: "Mumbai" },
    {
      id: 6,
      name: "Nirmalamatha Convent Matric Hr. Sec. School ",
      image: Logo4,
      city: "Coimbatore",
    },
    {
      id: 8,
      name: "Dr. Vijaypat Singhania School",
      image: Logo7,
      city: "Vapi",
    },
    { id: 9, name: "St. Joseph's Academy  ", image: Logo10, city: "Dehradun" },
    { id: 10, name: "St. Patrick's Academy", image: Logo8, city: "Meerut" },
  ];

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1064,
      },
      items: 5,
      partialVisibilityGutter: 10,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 2,
      partialVisibilityGutter: 10,
    },
    tablet: {
      breakpoint: {
        max: 1064,
        min: 464,
      },
      items: 3,
      partialVisibilityGutter: 10,
    },
  };

  return (
    <>
      <Typography component="div" className={styles.schools_container}>
        <Carousel
          additionalTransfrom={0}
          autoPlay
          autoPlaySpeed={4000}
          centerMode={false}
          containerClass="container-with-dots"
          customTransition="all 3s linear"
          draggable
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderDotsOutside={true}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          slidesToSlide={1}
          swipeable
          ssr={TurnedInOutlined}
          className={styles.carousel}
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
          {Logo.map((item) => (
            <Typography
              component="div"
              key={
                item.id +
                getRandomString(item.id, 5, randomGenerationString) +
                1
              }
              className={styles.imageContainerStyle}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={item.image}
                width={170}
                height={160}
                alt={item.name}
                className={styles.imageStyle}
                style={{
                  zIndex: -1,
                }}
              />

              <div>
                <h3
                  style={{
                    fontSize: ".8rem",
                    fontFamily: "Space Grotesk",
                    paddingTop: "8px",
                    color: "white",
                  }}
                >
                  {item.name}
                </h3>
                <h3
                  style={{
                    fontSize: ".6rem",
                    fontFamily: "Space Grotesk",
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  {item.city}
                </h3>
              </div>
            </Typography>
          ))}
        </Carousel>
      </Typography>
    </>
  );
};

export default PartnerSchools;

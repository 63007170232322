import { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import axios from "axios";
import { API_URL } from "../../utils/constant";
import styles from "../../styles/SearchFilter.module.css";
import { FilterContext } from "../../context/FilterContext";

function Filter() {
  const { filters, setFilters } = useContext(FilterContext);

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [location, setLocation] = useState("");
  const [board, setBoard] = useState([]);
  const [classs, setClasss] = useState([]);
  const [admissionStatus, setAdmissionStatus] = useState([
    { id: 1, value: 1, name: "Open" },
    { id: 2, value: null, name: "Any" },
  ]);
  const [gender, setGender] = useState([]);
  const [dayBoarding, setDayBoarding] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [selectedAdmissionStatus, setSelectedAdmissionStatus] = useState(1);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedDayBoarding, setSelectedDayBoarding] = useState("");
  const [selectedClasses, setSelectedClasses] = useState([]);

  const [debouncedInputValue, setDebouncedInputValue] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setInputValue(debouncedInputValue);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(timer);
    };
  }, [debouncedInputValue]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const res = await axios.get(`${API_URL}/school/search-filters`);
        setBoard(res.data.boards);
        setClasss(res.data.classes);
        setGender(res.data.genders);
        setDayBoarding(res.data.dayBoarding);
      } catch (err) {
        console.error(err);
      }
    };
    fetchFilters();
  }, []);

  useEffect(() => {
    async function fetchSuggestions() {
      const res = await fetch(`${API_URL}/location?keyword=${inputValue}`);
      const data = await res.json();

      setSuggestions(
        data.map((item) => {
          return {
            value: item.district_id,
            label: item.district_name,
          };
        })
      );
    }

    if (inputValue.length > 0) {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const updateAppliedFilters = (updatedFiltersArray) => {
    setAppliedFilters(updatedFiltersArray);
    console.log("applied filters", updatedFiltersArray);
  };

  const handleRemoveFilter = (filterId) => {
    const updatedFilters = { ...filters };

    switch (filterId) {
      case "location":
        updatedFilters.district = null;
        break;
      case "board":
        updatedFilters.board = null;
        break;
      case "status":
        updatedFilters.admissionStatus = null;
        break;
      case "gender":
        updatedFilters.gender = null;
        break;
      case "dayBoarding":
        updatedFilters.residencyType = null;
        break;
      case "class":
        updatedFilters.class = [];
        break;
      default:
        break;
    }

    setFilters(updatedFilters);
  };
  useEffect(() => {
    const appliedFiltersArray = [];

    if (filters.district)
      appliedFiltersArray.push({
        id: "location",
        name: location.name,
        value: location.value,
      });

    if (filters.board) {
      const selected = board.find((item) => item.id == filters.board);
      let boardName = selected ? selected.name : "Board not found";
      appliedFiltersArray.push({
        id: "board",
        name: boardName,
        value: filters.board,
      });
    }

    // Ensure admissionStatus defaults to "Open" if not set
    if (
      filters.admissionStatus !== null &&
      filters.admissionStatus !== undefined
    ) {
      const selected = admissionStatus.find(
        (item) => item.value == filters.admissionStatus
      );
      let statusName = selected ? selected.name : "Any";
      appliedFiltersArray.push({
        id: "status",
        name: statusName,
        value: filters.admissionStatus,
      });
    } else {
      setFilters({ ...filters, admissionStatus: 0 });
      setSelectedAdmissionStatus(0);
    }

    if (filters.gender) {
      const selected = gender.find((item) => item.value == filters.gender);
      let genderName = selected ? selected.name : "Gender not found";
      appliedFiltersArray.push({
        id: "gender",
        name: genderName,
        value: filters.gender,
      });
    }

    if (filters.residencyType) {
      const selected = dayBoarding.find(
        (item) => item.value == filters.residencyType
      );
      let dayBoardingName = selected ? selected.name : "Day boarding not found";
      appliedFiltersArray.push({
        id: "dayBoarding",
        name: dayBoardingName,
        value: filters.residencyType,
      });
    }

    if (filters.class && filters.class.length > 0) {
      filters.class.forEach((classId) => {
        const selected = classs.find((item) => item.id == classId);
        if (selected) {
          appliedFiltersArray.push({
            id: "class",
            name: selected.name,
            value: classId,
          });
        }
      });
    }

    if (filters.district) setSelectedLocation(filters.district);
    else setSelectedLocation("");

    if (filters.board) setSelectedBoard(filters.board);
    else setSelectedBoard("");

    if (
      filters.admissionStatus !== null &&
      filters.admissionStatus !== undefined
    )
      setSelectedAdmissionStatus(filters.admissionStatus);
    else setSelectedAdmissionStatus(1); // Default to "Open"

    if (filters.gender) setSelectedGender(filters.gender);
    else setSelectedGender("");

    if (filters.residencyType) setSelectedDayBoarding(filters.residencyType);
    else setSelectedDayBoarding("");

    setSelectedClasses(filters.class || []);
    updateAppliedFilters(appliedFiltersArray);
  }, [location, filters, board, admissionStatus, gender, dayBoarding, classs]);

  return (
    <>
      <div>
        <Formik
          initialValues={{
            locations: "",
            boards: "",
            classs: [],
            admissionStatus: 0, // Default to "Open"
            genders: "",
            dayBoarding: "",
          }}
          onSubmit={async (values) => {
            const boardValue = values.boards;
            const newClasses = selectedClasses?.length
              ? selectedClasses.map((classId) => +classId)
              : [];

            setFilters({
              ...filters,
              district: location ? location : null,
              board: boardValue ? boardValue : null,
              class: Array.isArray(newClasses) ? newClasses : [],
              admissionStatus: values.admissionStatus,
              gender: values.genders ? values.genders : null,
              residencyType: values.dayBoarding ? values.dayBoarding : null,
            });
          }}
        >
          {({ values, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <div className="flex gap-2 transition-all flex-wrap">
                  {appliedFilters.map(
                    (filter, index) =>
                      filter.id !== "location" &&
                      filter.id !== "status" && (
                        <div
                          key={index}
                          className="border-2 border-pink-200 text-pink-200 flex justify-between rounded-full px-2 py-1 gap-2 transition-all"
                        >
                          {filter.name}

                          <button
                            className={styles.removeFilter}
                            type="button"
                            onClick={() => handleRemoveFilter(filter.id)}
                          >
                            &#10005;
                          </button>
                        </div>
                      )
                  )}
                </div>
              </div>
              <div>
                <div>
                  <label className={styles.filterLabel}>Board</label>
                </div>
                <div>
                  <select
                    name="boards"
                    onChange={(e) => {
                      handleChange(e);
                      setFilters({
                        ...filters,
                        board: e.target.value ? e.target.value : null,
                      });
                      setSelectedBoard(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={selectedBoard}
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "0.6px solid #ccc",
                      outline: "none",
                      fontSize: "16px",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      color: "#000",
                      padding: "0 10px",
                    }}
                  >
                    <option value="">Select</option>
                    {board &&
                      board.map((item) => (
                        <option value={item.id} key={item.id + item.name + 1}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className={styles.filterClass}>
                <div className="line">
                  <hr></hr>
                </div>

                <div style={{ padding: "10px 0" }}>
                  <label className={styles.filterLabel} style={{ margin: 0 }}>
                    Class
                  </label>
                </div>
                <div
                  style={{
                    height: "230px",
                    backgroundColor: "#fff",
                    width: "100%",
                    outline: "none",
                    fontSize: "16px",
                    backgroundColor: "#fff",
                    overflow: "auto",
                    marginBottom: "20px",
                  }}
                >
                  {classs &&
                    classs.map((c) => (
                      <div key={c.id} className="flex items-center gap-2 pb-2">
                        <input
                          type="checkbox"
                          name="classs"
                          value={c.id}
                          checked={selectedClasses.includes(c.id)}
                          onChange={(e) => {
                            const newSelectedClasses = [...selectedClasses];
                            if (e.target.checked) {
                              newSelectedClasses.push(c.id);
                            } else {
                              const index = newSelectedClasses.indexOf(c.id);
                              if (index > -1) {
                                newSelectedClasses.splice(index, 1);
                              }
                            }
                            setSelectedClasses(newSelectedClasses);

                            // Update the filters state
                            setFilters((prevFilters) => ({
                              ...prevFilters,
                              class: newSelectedClasses,
                            }));
                          }}
                          className="mr-2"
                        />
                        <label>{c.name}</label>
                      </div>
                    ))}
                </div>
                <div className="line">
                  <hr></hr>
                </div>
              </div>

              <div>
                <div>
                  <label className={styles.filterLabel}>Admission Status</label>
                </div>
                <div>
                  <select
                    name="admissionStatus"
                    onChange={(e) => {
                      handleChange(e);
                      const value = e.target.value ? e.target.value : null;
                      setFilters({
                        ...filters,
                        admissionStatus: value,
                      });
                      setSelectedAdmissionStatus(value);
                    }}
                    onBlur={handleBlur}
                    value={selectedAdmissionStatus}
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "0.6px solid #ccc",
                      outline: "none",
                      fontSize: "16px",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      color: "#000",
                      padding: "0 10px",
                    }}
                  >
                    <option value="" key="">
                      Select
                    </option>
                    {admissionStatus &&
                      admissionStatus.map((item) => (
                        <option
                          value={item.value}
                          key={item.id + item.name + 1}
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <div>
                  <label className={styles.filterLabel}>Gender</label>
                </div>
                <div>
                  <select
                    name="genders"
                    onChange={(e) => {
                      handleChange(e);
                      setFilters({
                        ...filters,
                        gender: e.target.value ? e.target.value : null,
                      });
                      setSelectedGender(e.target.value);
                    }}
                    value={selectedGender}
                    onBlur={handleBlur}
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "0.6px solid #ccc",
                      outline: "none",
                      fontSize: "16px",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      color: "#000",
                      padding: "0 10px",
                    }}
                  >
                    <option value="">Select</option>
                    {gender &&
                      gender.map((item) => (
                        <option value={item.name} key={item.id + item.name + 1}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <div>
                  <label className={styles.filterLabel}>Day Boarding</label>
                </div>
                <div>
                  <select
                    name="dayBoarding"
                    onChange={(e) => {
                      handleChange(e);
                      setFilters({
                        ...filters,
                        residencyType: e.target.value ? e.target.value : null,
                      });
                      setSelectedDayBoarding(e.target.value);
                    }}
                    value={selectedDayBoarding}
                    onBlur={handleBlur}
                    style={{
                      width: "100%",
                      height: "40px",
                      border: "0.6px solid #ccc",
                      outline: "none",
                      fontSize: "16px",
                      backgroundColor: "#fff",
                      borderRadius: "10px",
                      color: "#000",
                      padding: "0 10px",
                    }}
                  >
                    <option value="">Select</option>
                    {dayBoarding &&
                      dayBoarding.map((item) => (
                        <option value={item.name} key={item.id + item.name + 1}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  style={{
                    width: "100%",
                    height: "40px",
                    border: "none",
                    borderRadius: "10px",
                    margin: "2rem 0",
                    cursor: "pointer",
                    backgroundColor: "#F77EAA",
                    boxShadow: "0 0 10px #ccc",
                    color: "white",
                  }}
                >
                  Apply Filter
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default Filter;

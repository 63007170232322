import React, { useState, useEffect } from "react";
import { Modal, Fade, Box, Typography, Button, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import classesOptions from "src/utils/classes";
import { API_URL } from "src/utils/constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";

const EnquiryModal = ({
  openEnquiryModal,
  item,
  setOpenEnquiryModal,
  selectedClass,
  selectedSession,
  selectedAcademicYear,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [class_id, setSelectedClass] = useState("");
  const [academicYearId, setAcademicYearId] = useState("");
  const [academicYears, setAcademicYears] = useState([]);
  const [captchaToken, setCaptchaToken] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAcademicYears = async () => {
      try {
        const response = await fetch(
          `${API_URL}/public/academic-years?status=active`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch academic years");
        }
        const data = await response.json();
        setAcademicYears(data.data);
      } catch (error) {
        console.error("Error fetching academic years:", error);
      }
    };

    fetchAcademicYears();
  }, []);

  const handleClose = () => {
    setOpenEnquiryModal({ ...openEnquiryModal, [item.id]: false });
    clearForm();
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setSubject(
      `${selectedSession ? `Enquiry for ${selectedSession.name}` : ""}`
    );
    setMessage("");
    setSelectedClass(`${selectedClass ? selectedClass : ""}`);
    setAcademicYearId(`${selectedAcademicYear ? selectedClass : ""}`);
    setCaptchaToken(null);
    setError(null);
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async () => {
    if (!captchaToken) {
      setError("Please complete the CAPTCHA verification.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}/enquiry/create-enquiry/${item.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phone,
            subject,
            message,
            class_id: parseInt(class_id),
            academic_year_id: parseInt(academicYearId),
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Enquiry submitted successfully:", data);
      toast.success("Email sent successfully!");
      handleClose();
    } catch (error) {
      console.error("Error submitting enquiry:", error);
      setError("Error submitting enquiry. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="enquiry-modal-title"
        aria-describedby="enquiry-modal-description"
        open={openEnquiryModal[item.id]}
        onClose={handleClose}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Fade in={openEnquiryModal[item.id]}>
          <Box
            sx={{
              padding: "1.5rem",
              minWidth: "300px",
              maxWidth: "80%",
              borderRadius: "8px",
              backgroundColor: "#fff",
              marginTop: "6%",
            }}
          >
            <CloseIcon
              onClick={handleClose}
              sx={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
                color: "gray",
              }}
            />
            <Typography
              variant="h5"
              id="enquiry-modal-title"
              gutterBottom
              className="mb-4"
            >
              Send an Enquiry
            </Typography>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              sx={{ marginBottom: "1rem" }}
            />

            <div style={{ marginBottom: "1rem" }}>
              <select
                value={class_id}
                onChange={(e) => setSelectedClass(e.target.value)}
                style={{
                  backgroundColor: "#fff ",
                  width: "100%",
                  padding: " 1rem 0.5rem",
                  border: " 1px solid #b3b4b5",
                  color: "#5e5e5e",
                  borderRadius: "5px",
                  marginTop: "0.1rem",
                }}
              >
                <option value="" disabled>
                  Select Class
                </option>
                {classesOptions?.map((classOption) => (
                  <option key={classOption.id} value={classOption.id}>
                    {classOption.name}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ marginBottom: "1rem" }}>
              <select
                value={academicYearId}
                onChange={(e) => setAcademicYearId(e.target.value)}
                style={{
                  backgroundColor: "#fff ",
                  width: "100%",
                  padding: " 1rem 0.5rem",
                  border: " 1px solid #b3b4b5",
                  color: "#5e5e5e",
                  borderRadius: "5px",
                  marginTop: "0.1rem",
                }}
              >
                <option value="" disabled>
                  Select Academic Year
                </option>
                {academicYears?.map((year) => (
                  <option key={year.id} value={year.id}>
                    {year.name}
                  </option>
                ))}
              </select>
            </div>

            <TextField
              label="Subject"
              variant="outlined"
              fullWidth
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              sx={{ marginBottom: "1rem" }}
            />
            <TextField
              label="Message"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              sx={{ marginBottom: "1rem" }}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={handleCaptchaChange}
              sx={{ marginBottom: "1rem" }}
            />
            {error && (
              <Typography color="error" sx={{ marginBottom: "1rem" }}>
                {error}
              </Typography>
            )}
            <div className="flex justify-between">
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  backgroundColor: "#F77EAA",
                  "&:hover": {
                    backgroundColor: "#FFA3C0",
                  },
                  paddingX: "4rem",
                }}
              >
                Send
              </Button>
              <Button
                onClick={handleClose}
                sx={{ marginLeft: "1rem" }}
                color="warning"
              >
                Close
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </>
  );
};

export default EnquiryModal;

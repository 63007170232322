import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Modal, Typography } from "@mui/material";
import Login from "../Authentication/Login/Login";
import cookie from "js-cookie";
import useAuth from "../../utils/useAuth";
import { API_URL } from "../../utils/constant";

const style = {
  overflow: "scroll",
  position: "absolute",
  top: "45%",
  left: "50%",
  bottom: 0,
  transform: "translate(-50%, -50%)",
  width: 470,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function FavoriteNotifyToast(props) {
  const [openNotify, setOpenNotify] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const token = useAuth();

  const [isSignedIn, setIsSignedIn] = React.useState(false);

  React.useEffect(() => {
    if (token) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, [token]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotify(false);
    setOpen(false);
  };

  const [shortlisted, setShortlisted] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleShortlist = async (e) => {
    try {
      /* Getting the token from the cookie. */
      const token = cookie.get("token");

      /* This is a fetch request to the backend. */
      const response = await fetch(
        `${API_URL}/school/shortlisted/${props.id}?notify=yes`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        e.target.style.color = "gray";
        setShortlisted(true);
        setOpenNotify(true);
      } else {
        setOpen(true);
      }

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {!shortlisted ? (
        <Typography
          component="div"
          className=" d-flex "
          style={{
            color: "gray",
            fontFamily: "Space Grotesk",
          }}
        >
          Shortlist
          {isSignedIn ? (
            <Typography component="div" onClick={handleShortlist}>
              <FavoriteIcon />
            </Typography>
          ) : (
            <Typography component="div" onClick={handleOpen}>
              <FavoriteIcon />
            </Typography>
          )}
          <Snackbar
            open={openNotify}
            autoHideDuration={4000}
            onClose={handleClose}
            message={props.message}
            action={action}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CloseIcon
                onClick={handleClose}
                style={{
                  float: "right",
                  cursor: "pointer",
                  margin: "10px",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              />
              <Login />
            </Box>
          </Modal>
        </Typography>
      ) : (
        <>
          <Typography
            component="div"
            className=" d-flex "
            style={{
              color: "gray",
              fontFamily: "Space Grotesk",
            }}
          >
            Shortlisted
            {isSignedIn ? (
              <Typography component="div" onClick={handleShortlist}>
                <FavoriteIcon style={{ color: "red" }} />
              </Typography>
            ) : (
              <Typography component="div" onClick={handleOpen}>
                <FavoriteIcon style={{ color: "red" }} />
              </Typography>
            )}
            <Snackbar
              open={openNotify}
              autoHideDuration={4000}
              onClose={handleClose}
              message={props.message}
              action={action}
            />
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <CloseIcon
                  onClick={handleClose}
                  style={{
                    float: "right",
                    cursor: "pointer",
                    margin: "10px",
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                />
                <Login />
              </Box>
            </Modal>
          </Typography>
        </>
      )}
    </>
  );
}

import React from "react";
import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import AddChildModal from "./AddChildModal";

const projects = [
  {
    name: "Gender",
    members: "Male",
  },
  {
    name: "Date of Birth",
    members: "12/06/2017",
  },
  {
    name: "Certifications",
    members: "Certificate A",
  },
  {
    name: "GPA",
    members: "3.5",
  },
  {
    name: "Contact Number",
    members: "+1 (123) 456-7890",
  },
  {
    name: "Allergies",
    members: "None",
  },
];

const ManageChild = () => {
  return (
    <Typography component="div">
      <div className="mt-16 flex flex-column items-end">
        <AddChildModal />

        <Card className="w-full flex-row mt-3 mb-0 shadow-none">
          <CardBody className="w-full">
            <div className="flex gap-3">
              <img
                src={
                  "https://cutewallpaper.org/24/profile-icon-png/profile-user-svg-png-icon-free-download-24787-onlinewebfontscom.png"
                }
                alt="profile-image" // Add alt text for accessibility
                className="object-cover"
                style={{ width: "70px" }}
              />
              <div className="w-full">
                <div className="flex justify-between">
                  <Typography variant="h6" className="text-black text-lg">
                    Jim Smith
                  </Typography>
                </div>
                <div className="flex justify-between">
                  <Button color="black" variant="outlined" size="sm">
                    Class 3
                  </Button>
                  <div>
                    <Button variant="Danger" size="sm">
                      Delete
                    </Button>{" "}
                    <Button color="black" variant="outlined" size="sm">
                      Edit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="bg-gray-100 h-full w-full shadow-sm py-2 pt-4">
          <ul
            role="list"
            className="px-3 grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-6 lg:grid-cols-6 "
          >
            {projects.map((project) => (
              <li key={project.name} className="col-span-1 flex rounded-md ">
                <div className="flex flex-1 items-center justify-between ">
                  <div className="flex-1 truncate px-4 text-sm">
                    <a
                      href={project.href}
                      className="font-bold text-gray-900 hover:text-gray-600 text-md"
                    >
                      {project.name}
                    </a>
                    <p className="text-gray-600">{project.members} </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Typography>
  );
};

export default ManageChild;

import React from "react";
import LayoutDashboard from "../../components/Dashboard/Layout";
import { Helmet } from "react-helmet";
import ManageChild from "./ManageChild";

const ManageChildPage = () => {
  return (
    <LayoutDashboard>
      <Helmet>
        <title>Manage Child</title>
      </Helmet>
      <ManageChild />
    </LayoutDashboard>
  );
};

export default ManageChildPage;

import { Container, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import AutoCompleteSearch from "./AutoCompleteSearch";
import styles from "../../styles/HeroSection.module.css";
import ServicesComponent from "../Services/ServicesComponent";

const SearchSchool = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener to track window resize
    window.addEventListener("resize", handleResize);

    // Check initial window width
    handleResize();

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className={styles.add_bg_img}>
        <Container className={styles.searchContainer}>
          <Grid
            data-aos="fade-up"
            data-aos-once="true"
            container
            direction="column"
            className="flex items-center"
          >
            <div className={styles.hero_text}>School Admissions Made Easy</div>
            <div className={styles.hero_subText}>
              {isMobile
                ? "Research, Apply and Track Admissions"
                : "Find the right school for your child. Research, Apply and Track Admissions anywhere, anytime"}
            </div>
            <AutoCompleteSearch />
          </Grid>
        </Container>
        <ServicesComponent />
      </div>
    </>
  );
};

export default SearchSchool;

import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import SchoolIcon from "@mui/icons-material/School";
import WcIcon from "@mui/icons-material/Wc";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NavigationIcon from "@mui/icons-material/Navigation";
import jwt_decode from "jwt-decode";
import FavoriteNotifyToast from "../../Shortlist/Shortlist";
import styles from "../../../styles/SearchResults.module.css";
import cookie from "js-cookie";

export const MoreDetailsIcons = (props) => {
  const [token, setToken] = useState(cookie.get("token"));
  const decoded = token ? jwt_decode(token) : null;

  return (
    <>
      <div
        className="d-lg-flex d-none gap-4"
        style={{
          fontFamily: "Space Grotesk",
        }}
      >
        <div
          className={styles.classes}
          onClick={() => {
            props.handleOpenModal();
          }}
        >
          Available Classes
        </div>
        {props.item.enquiry_school && (
          <div
            className={styles.classes}
            onClick={() => {
              props.handleEnquiry();
            }}
          >
            Enquiry
          </div>
        )}
        {props.item.admission_status === "open" && (
          <div
            className={styles.ApplyButton}
            onClick={() => {
              props.handleClassModalOpen();
            }}
          >
            Apply now
          </div>
        )}

        <div className={styles.TextStyle} id={props.item.id}>
          <FavoriteNotifyToast
            message={props.item.name + " added to your shortlist"}
            id={props.item.id}
            shortlisted={props.item.shortlisted}
          />
        </div>
      </div>
    </>
  );
};

export const MoreDetailsIconsPhone = (props) => {
  return (
    <>
      <Grid
        container
        direction="row"
        className="mt-1"
        style={{
          fontFamily: "Space Grotesk",
          backgroundColor: "#F5F5F5",
          padding: "1rem",
          float: "right",
          width: "100%",
        }}
      >
        <Grid item className="my-2" xs={6} md={6} lg={3}>
          <div className="d-flex">
            <div>
              <ApartmentIcon className={styles.IconStyles} />
            </div>
            <div>
              <div className={styles.TextAfterIcon}>Classes</div>
              <div className={styles.AllClass}>
                {props.item.classes[0].name} -{" "}
                {props.item.classes[props.item.classes.length - 1].name}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item className="my-2" xs={6} md={6} lg={3}>
          <div className="d-flex">
            <div>
              <SchoolIcon className={styles.IconStyles} />
            </div>
            <div>
              <div className={styles.TextAfterIcon}>Board</div>
              <div className={styles.AllClass}>{props.item.board}</div>
            </div>
          </div>
        </Grid>
        <Grid item className="my-2" xs={6} md={6} lg={3}>
          <div className="d-flex">
            <div>
              <WcIcon className={styles.IconStyles} />
            </div>
            <div>
              <div className={styles.TextAfterIcon}>Gender</div>
              <div className={styles.AllClass}>
                {props.item.gender_accepted}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item className="my-2" xs={6} md={6} lg={3}>
          <div className="d-flex">
            <div>
              <BusinessIcon className={styles.IconStyles} />
            </div>
            <div>
              <div className={styles.TextAfterIcon}>Residential</div>
              <div className={styles.AllClass}>{props.item.residency_type}</div>
            </div>
          </div>
        </Grid>
        <div className="mt-3">
          <LocationOnIcon
            style={{
              color: "#F876A7",
            }}
          />
          {props.item.address}
        </div>

        <div className="row w-100">
          <div
            className="mt-2 mt-lg-0 float-end text-end"
            onClick={() => {
              props.handleMapModalOpen();
            }}
          >
            <NavigationIcon />
            View on map
          </div>
        </div>
      </Grid>
    </>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/ForSchools/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".About_aboutContainer__G2AVQ {\n    padding: 0;\n}\n\n.About_heroContainer__Ivhsn {\n    background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n    background-position: center center;\n    padding: 5rem 0;\n}\n\n.About_heroTitle__njn75 {\n    font-size: 2.5rem;\n    font-weight: 600;\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n    color: aliceblue;\n    text-align: center;\n    margin: 0 auto;\n    padding: 0px 0px 0px 20px;\n    line-height: 1.5;\n    margin-top: 20%;\n}\n\n.About_heroBtn__ypdBS {\n    margin-top: 2rem;\n}", "",{"version":3,"sources":["webpack://./src/styles/About.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,6DAAsD;IACtD,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,4DAA4D;IAC5D,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".aboutContainer {\n    padding: 0;\n}\n\n.heroContainer {\n    background: url(../assets/ForSchools/bg.png) no-repeat;\n    background-position: center center;\n    padding: 5rem 0;\n}\n\n.heroTitle {\n    font-size: 2.5rem;\n    font-weight: 600;\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n    color: aliceblue;\n    text-align: center;\n    margin: 0 auto;\n    padding: 0px 0px 0px 20px;\n    line-height: 1.5;\n    margin-top: 20%;\n}\n\n.heroBtn {\n    margin-top: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutContainer": "About_aboutContainer__G2AVQ",
	"heroContainer": "About_heroContainer__Ivhsn",
	"heroTitle": "About_heroTitle__njn75",
	"heroBtn": "About_heroBtn__ypdBS"
};
export default ___CSS_LOADER_EXPORT___;

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Cog8ToothIcon, TrashIcon } from "@heroicons/react/24/solid";
import { FilterX } from "lucide-react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Select,
  Option,
} from "@material-tailwind/react";
import { debounce } from "lodash";
import { API_URL } from "src/utils/constant";
import { format } from "date-fns";
import { useRouter } from "src/hooks/useRouter";
import classesOptions from "src/utils/classes";
import jwtDecode from "jwt-decode";

const TABLE_HEAD = [
  "User",
  "Class ID",
  "Academic Year",
  "Subject",
  "Message",
  "Date",
  "",
];

const getClassById = (classId) => {
  const classObj = classesOptions.find((cls) => cls.id === classId);
  return classObj ? classObj.name : "Unknown";
};

const limit = 10;

export default function EnquiryList() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [enquiryToDelete, setEnquiryToDelete] = useState(null);

  const handleOpenDelete = (enquiryId) => {
    setEnquiryToDelete(enquiryId);
    setOpenDelete(!openDelete);
  };

  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [classId, setClassId] = useState("");
  const [academicYearId, setAcademicYearId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const token = localStorage.getItem("token");
  const decoded = jwtDecode(token);
  const router = useRouter();
  const { schoolId } = router.query;

  console.log("decoded", decoded);

  const fetchEnquiries = async (
    searchTerm,
    classId,
    academicYearId,
    dateFrom,
    dateTo,
    page = 1,
    limit = 10
  ) => {
    try {
      setLoading(true);
      const queryParams = new URLSearchParams({
        page: page,
        limit: limit,
      });
      if (searchTerm) {
        queryParams.append("keyword", searchTerm);
      }
      if (classId) {
        queryParams.append("class_id", classId);
      }
      if (academicYearId) {
        queryParams.append("academic_year_id", academicYearId);
      }
      if (dateFrom) {
        queryParams.append("date_from", dateFrom);
      }
      if (dateTo) {
        queryParams.append("date_to", dateTo);
      }

      const response = await fetch(
        `${API_URL}/enquiry/get-enquiries/${schoolId}?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        setEnquiries(data.data.rows);
        setCurrentPage(data.data.page);
        setCount(data.data.count);
      } else {
        console.error(data.err);
        toast.error(data.err);
      }
    } catch (error) {
      console.error("Error fetching enquiries:", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchEnquiriesDebounced = debounce(
    (searchTerm, classId, academicYearId, dateFrom, dateTo) => {
      fetchEnquiries(searchTerm, classId, academicYearId, dateFrom, dateTo);
    },
    300
  );

  const deleteEnquiry = async (enquiryId) => {
    try {
      const response = await fetch(
        `https://api.admissionpedia.dev/api/enquiry/delete-enquiry/${enquiryId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data.success) {
        toast.success("Enquiry deleted successfully");
        fetchEnquiries(
          searchTerm,
          classId,
          academicYearId,
          dateFrom,
          dateTo,
          currentPage,
          limit
        );
      } else {
        console.error(data.err);
        toast.error(data.err);
      }
    } catch (error) {
      console.error("Error deleting enquiry:", error);
      toast.error(error.message);
    } finally {
      setOpenDelete(false);
      setEnquiryToDelete(null);
    }
  };

  const [formData, setFormData] = useState({
    status: "",
    forward_to_email: "",
    forward_email: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${API_URL}/enquiry/update-enquiry-config/${schoolId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      console.log(data);
      if (data.success) {
        toast.success("Enquiry configuration updated successfully");
        setOpen(false);
        fetchEnquiries(
          searchTerm,
          classId,
          academicYearId,
          dateFrom,
          dateTo,
          currentPage,
          limit
        );
      } else {
        console.error(data.err);
        toast.error(data.err);
      }
    } catch (error) {
      console.error("Error updating enquiry configuration:", error);
      toast.error(error.message);
    }
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setClassId("");
    setAcademicYearId("");
    setDateFrom("");
    setDateTo("");
  };

  useEffect(() => {
    fetchEnquiriesDebounced(
      searchTerm,
      classId,
      academicYearId,
      dateFrom,
      dateTo
    );
    return () => fetchEnquiriesDebounced.cancel();
  }, [searchTerm, classId, academicYearId, dateFrom, dateTo]);

  return (
    <div className="bg-[#fef7f9] px-[5%] py-[4%]">
      <Card className="h-full w-full">
        <Card floated={false} shadow={false} className="rounded-none mt-4 mx-2">
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row pt-2">
            <div className="flex flex-col gap-2 sm:flex-row">
              <select
                value={classId}
                className="border-[#c7c7c7] border py-[.5rem] px-3 bg-transparent rounded-md"
                onChange={(e) => setClassId(e.target.value)}
              >
                <option key="" value="">
                  Select class
                </option>
                {classesOptions.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name}
                  </option>
                ))}
              </select>

              <select
                value={academicYearId}
                onChange={(e) => setAcademicYearId(e.target.value)}
                className="border-[#c7c7c7] border py-[.5rem] px-3 bg-transparent rounded-md"
              >
                <option value="">Select Year</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
              <Input
                color="pink"
                type="date"
                label="From Date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
              />
              <Input
                color="pink"
                type="date"
                label="To Date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
              />

              <Button
                color="red"
                size="sm"
                variant="outlined"
                onClick={handleResetFilters}
                className="flex items-center gap-2 w-full"
              >
                <FilterX className="h-5 w-5" />
              </Button>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Button
                className="flex items-center gap-2 w-full"
                color="pink"
                size="sm"
                onClick={handleOpen}
              >
                <Cog8ToothIcon strokeWidth={2} className="h-5 w-5" /> Configure
                Enquiry
              </Button>
              <Input
                color="pink"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  console.log("Search Term:", e.target.value);
                }}
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </Card>
        <CardBody className="overflow-scroll px-0">
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50  p-3"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="leading-none opacity-70 font-semibold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {enquiries.length > 0 &&
                enquiries.map((enquiry, index) => {
                  const isLast = index === enquiries.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";
                  return (
                    <tr key={enquiry.id}>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {enquiry.email}
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal opacity-70"
                            >
                              {enquiry.name}
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal opacity-70"
                            >
                              {enquiry.phone}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {getClassById(enquiry.class_id)}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {enquiry.academic_year.name}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {enquiry.subject}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {enquiry.message}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {format(new Date(enquiry.created), "MM/dd/yyyy")}
                            </Typography>
                          </div>
                        </div>
                      </td>

                      <td>
                        <Tooltip content="Delete Enquiry">
                          <IconButton
                            variant="text"
                            color="red"
                            onClick={() => handleOpenDelete(enquiry.id)}
                          >
                            <TrashIcon className="h-4 w-4" />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {enquiries.length === 0 && (
            <div className="flex justify-center">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal opacity-70"
              >
                No enquiries found.
              </Typography>
            </div>
          )}
        </CardBody>
        {count > limit && (
          <CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
            <div className="flex items-center gap-2">
              <Button
                variant="outlined"
                color="blue-gray"
                size="sm"
                onClick={() =>
                  fetchEnquiries(
                    searchTerm,
                    classId,
                    academicYearId,
                    dateFrom,
                    dateTo,
                    currentPage - 1,
                    limit
                  )
                }
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                color="blue-gray"
                size="sm"
                onClick={() =>
                  fetchEnquiries(
                    searchTerm,
                    classId,
                    academicYearId,
                    dateFrom,
                    dateTo,
                    currentPage + 1,
                    limit
                  )
                }
                disabled={currentPage === Math.ceil(count / limit)}
              >
                Next
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>
      <Dialog open={open} handler={handleOpen} className="opacity-20">
        <DialogHeader>Update Enquiry Configuration</DialogHeader>
        <DialogBody divider>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <Select
                label="Status"
                name="status"
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e })}
              >
                <Option value="active">Active</Option>
                <Option value="inactive">Inactive</Option>
              </Select>
            </div>
            <div className="mb-4">
              <Select
                label="Forward to Email"
                name="forward_to_email"
                value={formData.forward_to_email}
                onChange={(e) =>
                  setFormData({ ...formData, forward_to_email: e })
                }
              >
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </div>
            <div className="mb-4">
              <Input
                type="email"
                label="Forward Email"
                name="forward_email"
                value={formData.forward_email}
                onChange={handleChange}
              />
            </div>
            <DialogFooter className="space-x-2">
              <Button variant="text" color="blue-gray" onClick={handleOpen}>
                Cancel
              </Button>
              <Button variant="gradient" color="pink" type="submit">
                Save
              </Button>
            </DialogFooter>
          </form>
        </DialogBody>
      </Dialog>
      <Dialog
        open={openDelete}
        handler={() => handleOpenDelete(null)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogHeader>Confirm Delete</DialogHeader>
        <DialogBody divider>
          Are you sure you want to delete this enquiry?
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={() => handleOpenDelete(null)}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="pink"
            onClick={() => deleteEnquiry(enquiryToDelete)}
          >
            <span>Delete</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
}

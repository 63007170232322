import React from "react";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import axios from "axios";
import cookie from "js-cookie";
import Feilds from "../Fields/InputField";
import Button from "../Button/Button";
import styles from "../../../styles/Login.module.css";
import { API_URL } from "../../../utils/constant";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import image from "../../../assets/images/login.jpg";
import ReCAPTCHA from "react-google-recaptcha";

const SchoolLogin = () => {
  const [Iferrors, setIfErrors] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [captchaValid, setCaptchaValid] = React.useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      if (!captchaValid) {
        setIfErrors("Please complete the CAPTCHA.");
        window.scrollTo(0, 0);
        return;
      }
      setLoading(true);
      const { email, password } = values;
      const response = await axios
        .post(`${API_URL}` + `/user/school-login`, {
          userName: email,
          password,
        })
        .catch((err) => {
          setIfErrors(err.response.data.message);
          console.log(err);
        });

      /* Getting the token from the response. */
      const token = response.data;

      if (token) {
        /* Setting the token in the local storage. */
        localStorage.setItem("token", token.token);
        /* Setting the token in the cookie. */
        cookie.set("token", token.token);
        /* Setting the token in the header of the axios request. */
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token.token}`;
        // redirect the user to the dashboard or home page
        window.location.href = "/school-dashboard/application-list";
      } else {
        setIfErrors("Invalid Credentials");
        alert("Invalid Credentials");
        window.location.href = "/school-login";
      }
    } catch (err) {
      setIfErrors(err.response.data.err);
      console.log(err);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };
  return (
    <>
      <MDBContainer fluid className={styles.containerBg}>
        <MDBRow>
          <MDBCol sm="6" className="login-wrapper">
            <div className="d-flex flex-row ps-5 pt-5">
              <div>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.91)",
                  }}
                  className="logoStyle"
                >
                  <span>Admission</span>
                  <span className="pedia">Pedia</span>
                </Link>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center h-custom-2 loginWidth pt-1">
              <h3
                className="fw-light mb-5 ps-5 pb-3 loginSub"
                style={{
                  letterSpacing: "1px",
                }}
              >
                School Login
              </h3>

              <Formik
                /* Setting the initial values of the form. */
                initialValues={{ email: "", password: "" }}
                /* Validating the form. */
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Email is Required";
                  }
                  if (!values.password) {
                    errors.password = "Password is Required";
                  }
                  return errors;
                }}
                /* A function that is called when the form is submitted. */
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  // isSubmitting,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="ps-5 d-flex flex-column gap-2"
                  >
                    {Iferrors ? (
                      <div
                        className={styles.errorStyle}
                        role="alert"
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          backgroundColor: "#f8d7da",
                        }}
                      >
                        {Iferrors}
                      </div>
                    ) : null}
                    {errors.email && touched.email && errors.email ? (
                      <>
                        <Feilds
                          label="Email"
                          placeholder={
                            errors.email && touched.email && errors.email
                          }
                          inputType="text"
                          name="email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.email}
                          errors="email"
                          classNameProp={styles.errorStyle}
                        />
                      </>
                    ) : (
                      <Feilds
                        label="Email"
                        placeholder="Enter your Email "
                        inputType="text"
                        name="email"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.email}
                        errors="email"
                        classNameProp={styles.inputField}
                      />
                    )}

                    {errors.password && touched.password && errors.password ? (
                      <>
                        <Feilds
                          label="Password"
                          placeholder={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                          name="password"
                          inputType="password"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.password}
                          errors="password"
                          classNameProp={styles.errorStyle}
                        />
                      </>
                    ) : (
                      <>
                        <Feilds
                          label="Password"
                          placeholder="Enter your password"
                          name="password"
                          inputType="password"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          value={values.password}
                          errors="password"
                          classNameProp={styles.inputField}
                        />
                      </>
                    )}
                    <ReCAPTCHA
                      className="w-[100%]"
                      sitekey={process.env.REACT_APP_SITE_KEY}
                      size="normal"
                      onChange={(value) => {
                        setCaptchaValid(!!value);
                      }}
                    />
                    <Button action="Sign in" loading={loading} />

                    <Typography
                      component="div"
                      margin={1}
                      textAlign={"end"}
                      className="mx-5 text-black-50"
                    >
                      {"Don't have account?"}
                      <Link to="/get-started/add-school">
                        <span className="mx-1 fw-semibold text-decoration-underline text-dark">
                          Sign up
                        </span>
                      </Link>
                    </Typography>
                  </form>
                )}
              </Formik>
            </div>
          </MDBCol>

          <MDBCol sm="6" className="d-none d-sm-block px-0">
            <img
              src={image}
              alt="Login image"
              className="w-100 h-[110vh]"
              style={{ objectFit: "cover", objectPosition: "left" }}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default SchoolLogin;

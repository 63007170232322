import { Drawer, MenuItem, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import styles from '../../../styles/Header.module.css'

const drawerWidth = 300

const HeaderDrawer = (props) => {
  return (
    <Typography component='div'>
      <Drawer
        container={props.container}
        variant='temporary'
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        <br />
        <Typography
          component='div'
          className={styles.closeDrawer}
          onClick={props.handleDrawerToggle}
        >
          <CloseIcon />
        </Typography>

        {props.pages.map((page) => (
          <MenuItem key={page}>
            <Typography textAlign='center' className={styles.drawerItems}>
              {page}
            </Typography>
          </MenuItem>
        ))}
      </Drawer>
    </Typography>
  )
}

export default HeaderDrawer

import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSpring, animated } from "@react-spring/web";
import { File, Plus } from "lucide-react";
import { Formik } from "formik";
import axios from "axios";
import { API_URL } from "src/utils/constant";
import InputField from "./Authentication/Fields/InputField";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "90%",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  borderRadius: "5px",
  overflow: "auto",
  p: 4,
};

function FormComponent({
  classs,
  initialSelectedClasses,
  handleSelectAll,
  onSelectedClassesChange,
}) {
  const fileInputRef = React.useRef(null);
  const [selectedFileName, setSelectedFileName] = React.useState("");
  const [selectedClasses, setSelectedClasses] = React.useState(
    initialSelectedClasses
  );
  const [isUploadingFile, setIsUploadingFile] = React.useState(true);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const fileName = e.target.files[0]?.name || "";
    setSelectedFileName(fileName);
  };

  const handleLinkChange = (e) => {
    // Handle link input here
  };

  const handleChange = (e) => {
    const classId = parseInt(e.target.value);
    setSelectedClasses((prevSelectedClasses) => {
      if (prevSelectedClasses.includes(classId)) {
        return prevSelectedClasses.filter((id) => id !== classId);
      } else {
        return [...prevSelectedClasses, classId];
      }
    });

    onSelectedClassesChange(selectedClasses);
  };

  const handleSelectAllClick = () => {
    if (selectedClasses.length === classs.length) {
      setSelectedClasses([]);
    } else {
      setSelectedClasses(classs.map((item) => item.id));
    }

    onSelectedClassesChange(selectedClasses);
  };

  return (
    <div>
      <div className="flex gap-2 items-end">
        <div className="flex items-baseline">
          {isUploadingFile && (
            <>
              <Button
                className="btnHover"
                sx={{ mt: 3 }}
                variant="outlined"
                style={{ color: "#F87FAA", borderColor: "#F87FAA" }}
                onClick={handleFileButtonClick}
              >
                <input
                  type="file"
                  hidden
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <File className="mr-2 h-5 w-5" />
                Upload File
              </Button>
            </>
          )}
        </div>
        {!isUploadingFile && (
          <div className="linkInput">
            <InputField type="text" placeholder="Enter Link" />
          </div>
        )}

        <div>
          <label>
            <select
              value={isUploadingFile ? "file" : "link"}
              onChange={(e) => setIsUploadingFile(e.target.value === "file")}
              style={{
                width: "100%",
                height: "40px",
                border: "0.6px solid #ccc",
                outline: "none",
                fontSize: "16px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                color: "#000",
                padding: "0 10px",
                marginTop: "1.5rem",
              }}
            >
              <option value="file">Upload File</option>
              <option value="link">Add Link</option>
            </select>
          </label>
        </div>
      </div>

      {isUploadingFile && (
        <Typography
          id="spring-modal-description"
          sx={{ fontWeight: "500", mt: 1.2 }}
        >
          {selectedFileName && <span> {selectedFileName}</span>}
        </Typography>
      )}

      <div className="flex justify-between items-center my-3">
        <Typography
          id="spring-modal-description"
          sx={{ mt: 2, mb: 2, fontWeight: "bold" }}
        >
          Select Classes that apply to the form uploaded
        </Typography>
        <div>
          <Button
            className="btnHover"
            variant="outlined"
            style={{
              color: "#F87FAA",
              borderColor: "#F87FAA",
              fontSize: ".8rem",
              border: "none",
              padding: "0",
              borderBottom: "2px solid #F87FAA",
            }}
            onClick={handleSelectAllClick}
          >
            Select All
          </Button>
        </div>
      </div>
      <Formik
        onSubmit={() => {}}
        initialValues={{
          classs: [],
        }}
      >
        {({ values, handleBlur, handleSubmit }) => (
          <form>
            <div
              className="grid grid-cols-3 mb-2"
              style={{
                outline: "none",
                fontSize: "16px",
                backgroundColor: "#fff",
              }}
            >
              {classs &&
                classs.map((item) => (
                  <div
                    key={item.id + item.name + 1}
                    style={{
                      padding: "5px",
                      cursor: "pointer",
                      display: "flex",
                    }}
                    value={item.name}
                  >
                    <input
                      type="checkbox"
                      name="classes"
                      value={item.id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                        color: "#f77eaa",
                      }}
                      checked={selectedClasses.includes(item.id)}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      id={item.id}
                    >
                      {item.name}
                    </div>
                  </div>
                ))}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default function InputModal(props) {
  const [open, setOpen] = React.useState(false);
  const [classs, setClasss] = React.useState([]);
  const [formCount, setFormCount] = React.useState(1);
  const [formSelectedClasses, setFormSelectedClasses] = React.useState([[]]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const fetchFilters = async () => {
      try {
        const res = await axios.get(`${API_URL}/school/search-filters`);
        setClasss(res.data.classes);
      } catch (err) {
        console.error(err);
      }
    };
    fetchFilters();
  }, []);

  const handleSelectAll = (formIndex) => {
    if (formSelectedClasses[formIndex].length === classs.length) {
      setFormSelectedClasses((prevSelectedClasses) => {
        const updatedSelectedClasses = [...prevSelectedClasses];
        updatedSelectedClasses[formIndex] = [];
        return updatedSelectedClasses;
      });
    } else {
      setFormSelectedClasses((prevSelectedClasses) => {
        const updatedSelectedClasses = [...prevSelectedClasses];
        updatedSelectedClasses[formIndex] = classs.map((item) => item.id);
        return updatedSelectedClasses;
      });
    }
  };

  const handleFormSelectedClassesChange = (formIndex, selectedClasses) => {
    setFormSelectedClasses((prevSelectedClasses) => {
      const updatedSelectedClasses = [...prevSelectedClasses];
      updatedSelectedClasses[formIndex] = selectedClasses;
      return updatedSelectedClasses;
    });
  };

  const handleAddNewForm = () => {
    setFormCount(formCount + 1);
    setFormSelectedClasses((prevSelectedClasses) => [
      ...prevSelectedClasses,
      [],
    ]);
  };

  return (
    <div>
      <Button
        className="btnHover"
        variant="outlined"
        style={{
          color: "#F87FAA",
          borderColor: "#F87FAA",
          borderRadius: "5px",
        }}
        onClick={handleOpen}
      >
        {props.title}
      </Button>
      <Modal
        sx={{ overflow: "auto" }}
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              {formCount > 1 ? "Add New Form" : "Upload Form"}
            </Typography>

            {Array.from({ length: formCount }, (_, i) => (
              <FormComponent
                key={i}
                classs={classs}
                initialSelectedClasses={formSelectedClasses[i]}
                handleSelectAll={() => handleSelectAll(i)}
                onSelectedClassesChange={(selectedClasses) =>
                  handleFormSelectedClassesChange(i, selectedClasses)
                }
              />
            ))}

            <div className="flex justify-between">
              <Button
                className="btnHover"
                variant="outlined"
                style={{ color: "#F87FAA", borderColor: "#F87FAA" }}
                sx={{ marginTop: 2 }}
                onClick={handleAddNewForm}
              >
                <Plus className=" mr-2 h-5 w-5" /> Add new form
              </Button>

              <Button
                style={{ color: "#fff", backgroundColor: "#F87FAA" }}
                sx={{ marginTop: 2 }}
              >
                Submit Files
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

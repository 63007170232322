import { useState } from "react";
import jwtDecode from "jwt-decode";
import cookie from "js-cookie";
import axios from "axios";

const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleGoogle = async (response) => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ credential: response.credential }),
    })
      .then((res) => {
        setLoading(false);
        console.log(res);
        return res.text();
      })
      .then((data) => {
        console.log("token:", data);

        const decodedToken = jwtDecode(data);
        console.log("decoded token:", decodedToken);
        if (decodedToken) {
          localStorage.setItem("token", data);
          /* Setting the token in the cookie. */
          cookie.set("token", data);
          /* Setting the token in the header of the axios request. */

          axios.defaults.headers.common["Authorization"] = `Bearer ${data}`;
          // Redirect the user to the dashboard/home page.
          window.location.href = "/Dashboard";
        } else {
          throw new Error(decodedToken?.message || "Token decoding error");
        }
      })
      .catch((error) => {
        setError(error?.message);
      });
  };

  return { loading, error, handleGoogle };
};

export default useFetch;

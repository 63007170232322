import React from "react";
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useMemo } from "react";
import cookie from "js-cookie";
import SchoolIcon from "@mui/icons-material/School";
import WcIcon from "@mui/icons-material/Wc";
import BusinessIcon from "@mui/icons-material/Business";
import ActionButtons from "../../SearchResults/Buttons/ActionButtons";
import styles from "../../../styles/SearchResults.module.css";
import { API_URL } from "../../../utils/constant";
import image from "../../../assets/image/SearchResult/school1.jpg";

const ShortlistComponent = () => {
  const [schools, setSchools] = React.useState([]);

  const token = cookie.get("token");

  const fetchShorlistedSchools = useMemo(
    () => () => {
      fetch(`${API_URL}/school/shortlisted`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setSchools(
              data.data.map((item) => ({
                ...item,
              }))
            );
          });
        }
      });
    },
    [token]
  );

  console.log(schools);

  useEffect(() => {
    fetchShorlistedSchools();
  }, [fetchShorlistedSchools]);

  return (
    <div>
      <Typography
        component="div"
        className="lg-p-5"
        style={{ minHeight: "200vh" }}
      >
        <Typography
          component="div"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={2} className="mx-lg-5 mx-0 mt-2 pt-5">
            <h3
              className=" mx-lg-5 px-lg-5 mx-4"
              style={{
                color: "#1A1A1A",
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              <SchoolIcon className=" mx-2 mb-2" />
              Shortlisted Schools
            </h3>
            <div className=" text-muted mx-5">
              Apply to your shortlisted schools and get a chance to get
              admission.
            </div>
            {schools &&
              schools.map((item) => (
                <div
                  className={`${styles.cardComponent} bg-white h-100 my-2 shadow-sm px-3 py-3 mx-auto hover-shadow`}
                  style={{
                    width: "82%",
                    borderRadius: "30px",
                    cursor: "pointer",
                  }}
                  key={item.name}
                  onClick={() => {
                    window.location.href = `/search-results?keyword=${item.name}`;
                  }}
                >
                  <Grid
                    container
                    className="px-0"
                    style={{ height: "100%" }}
                    direction="column"
                  >
                    <Grid
                      container
                      direction="row"
                      style={{ height: "100%" }}
                      alignItems="center"
                      className="py-2"
                    >
                      <img
                        src={image}
                        alt={item.name}
                        width="60"
                        height="60"
                        className="rounded-circle m-3"
                      />
                      <Typography
                        component="div"
                        className="pl-2"
                        style={{ width: "85%" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>
                            <Typography variant="h5" className="mb-0">
                              {item.name}
                            </Typography>
                          </div>
                          <div className=" d-none d-lg-flex">
                            <Typography
                              style={{
                                float: "right",
                              }}
                            >
                              <Typography component="div">
                                Shortlisted
                              </Typography>
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {item.address}
                        </Typography>
                      </Typography>
                      <Typography
                        component="div"
                        className="d-flex flex-column align-items-end w-100"
                      >
                        <Grid
                          container
                          direction="row"
                          className="mt-1 border-top"
                          spacing={1}
                        >
                          <Grid item className="my-2" xs md lg>
                            <div className="d-flex">
                              <div>
                                <SchoolIcon className={styles.IconStyles} />
                              </div>
                              <div>
                                <div className={styles.TextAfterIcon}>
                                  Admission Status
                                </div>
                                <div className={styles.AllClass}>
                                  {item.admission_status == 1 ? (
                                    <div
                                      style={{
                                        color: "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Open for Admission
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Closed
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item className="my-2" xs md lg>
                            <div className="d-flex">
                              <div>
                                <WcIcon className={styles.IconStyles} />
                              </div>
                              <div>
                                <div className={styles.TextAfterIcon}>
                                  Gender
                                </div>
                                <div className={styles.AllClass}>
                                  {item.gender_accepted}
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item className="my-2" xs md={6} lg={3}>
                            <div className="d-flex">
                              <div>
                                <BusinessIcon className={styles.IconStyles} />
                              </div>
                              <div>
                                <div className={styles.TextAfterIcon}>
                                  Residential
                                </div>
                                <div className={styles.AllClass}>
                                  {item.residency_type}
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <ActionButtons item={item} />
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
          </Grid>
        </Typography>
      </Typography>
    </div>
  );
};

export default ShortlistComponent;

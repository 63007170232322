import React from "react";
import { Helmet } from "react-helmet";
import Layout from "src/components/Layout/Layout";
import Article from "src/components/FreeResources/Article";

const index = () => {
  return (
    <>
      <Layout>
        <Helmet>
          <title>Best Practices - AdmissionPedia</title>
          <meta
            name="description"
            content="Best practices for school websites to improve online presence."
          />
        </Helmet>
        <Article />
      </Layout>
    </>
  );
};

export default index;

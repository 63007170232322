import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import AboutUs from "./pages/about-us/index";
import AddSchool from "./pages/add-school/index";
import HomeComponent from "src/components/Home/HomeComponent";
import Apply from "src/pages/apply/[id]";
import ApplyThankYou from "src/pages/apply/thank-you";
import Contact from "src/pages/contact/";
import Dashboard from "src/pages/Dashboard/";
import DashboardAppliedSchools from "src/pages/Dashboard/applied-schools";
import MyProfile from "src/pages/Dashboard/my-profile";
import ShortlistedSchools from "src/pages/Dashboard/sortlisted-schools";
import DashboardLogout from "src/pages/Dashboard/logout";
import DashboardTrackApplication from "src/pages/Dashboard/track-application";
import ForgetPassword from "src/pages/forget-password";
import GetStarted from "src/pages/get-started";
import GetStartedAddSchool from "src/pages/get-started/add-school";
import GetStartedCompleted from "src/pages/get-started/completed";
import GetStartedConfirmDetails from "src/pages/get-started/confirm-details";
import GetStartedSchoolInfo from "src/pages/get-started/school-info";
import GetStartedAdditionInfo from "src/pages/get-started/additional-info/[id]";
import Login from "src/pages/login";
import LoginAs from "src/pages/login-as";
import Notification from "src/pages/notification";
import OtpValidation from "src/pages/otp-validation";
import PasswordReset from "src/pages/password-reset";
import PrivacyPolicy from "src/pages/privacy-policy";
import Article from "src/pages/free-resources/best-practices";

import SchoolDashboard from "src/pages/school-dashboard";
import SchoolDashboardLogout from "src/pages/school-dashboard/logout";
import SchoolDashboardEdit from "src/pages/school-dashboard/edit/class";
import SchoolDashboardConfig from "src/pages/school-dashboard/configuration";
import SchoolDashboardSettings from "src/pages/school-dashboard/settings";
import SchoolDashboardApplicationList from "src/pages/school-dashboard/application-list";
import SchoolDashboardApplicationClass from "src/pages/school-dashboard/application-list/class";
import SchoolDashboardApplicationListDetails from "src/pages/school-dashboard/application-list/details";

import SchoolLogin from "src/pages/school-login";
import SearchResults from "src/pages/search-results";
import Signup from "src/pages/signup";
import TermsOfUse from "src/pages/terms-of-use";
import FreeResources from "src/pages/free-resources";

import ScrollToTop from "./components/Layout/ScrollToTop";
import useAuth from "./utils/useAuth";
import ProtectedRoute from "./ProtectedRoute";
import ClientDashboard from "./pages/client-dashboard";
import SchoolAdmins from "./pages/client-dashboard/school-admins";
import SubAccounts from "./pages/client-dashboard/sub-accounts";

import "src/styles/globals.css";
import ManageChild from "./pages/manage-child";
import ManageChildPage from "./pages/manage-child";
import OtpRequest from "./pages/otp-enquiry";
import RequestEnquiry from "./pages/request-enquiry";
import SchoolPage from "./pages/school/[id]";
import Enquiries from "./pages/enquiries";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { decoded } = useAuth();

  const isAuthenticated = decoded && decoded.email_verified;

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route exact path={"/about-us"} render={(props) => <AboutUs />} />
          <Route exact path={"/add-school"} render={(props) => <AddSchool />} />
          <Route
            exact
            path={"/apply/thank-you"}
            render={(props) => <ApplyThankYou />}
          />
          <ProtectedRoute
            exact
            path={"/apply/:id"}
            authRequired={true}
            component={Apply}
          />
          <Route exact path={"/contact"} render={(props) => <Contact />} />
          <ProtectedRoute
            exact
            authRequired={true}
            path={"/dashboard/applied-schools"}
            component={DashboardAppliedSchools}
          />

          <ProtectedRoute
            exact
            authRequired={true}
            path={"/dashboard/my-profile"}
            component={MyProfile}
          />
          <ProtectedRoute
            exact
            authRequired={true}
            path={"/dashboard/shortlisted-schools"}
            component={ShortlistedSchools}
          />
          <ProtectedRoute
            exact
            authRequired={true}
            path={"/dashboard/manage-child"}
            component={ManageChildPage}
          />
          <ProtectedRoute
            exact
            authRequired={true}
            path={"/dashboard/logout"}
            component={DashboardLogout}
          />
          <ProtectedRoute
            exact
            authRequired={true}
            path={"/dashboard/track-application"}
            component={DashboardTrackApplication}
          />
          <ProtectedRoute
            exact
            path={"/dashboard"}
            authRequired={true}
            component={Dashboard}
          />

          <Route
            exact
            path={"/forget-password"}
            render={(props) => <ForgetPassword />}
          />
          <Route
            exact
            path={"/get-started/add-school"}
            render={(props) => <GetStartedAddSchool />}
          />
          <Route
            exact
            path={"/get-started/completed"}
            render={(props) => <GetStartedCompleted />}
          />
          <Route
            exact
            path={"/get-started/confirm-details"}
            render={(props) => <GetStartedConfirmDetails />}
          />
          <Route
            exact
            path={"/get-started/school-info"}
            render={(props) => <GetStartedSchoolInfo />}
          />
          <Route
            exact
            path={"/get-started/additional-info/:id"}
            render={(props) => <GetStartedAdditionInfo />}
          />
          <Route
            exact
            path={"/school/:id"}
            render={(props) => <SchoolPage />}
          />

          <Route
            exact
            path={"/get-started"}
            render={(props) => <GetStarted />}
          />
          <Route
            exact
            path={"/free-resources/best-practices"}
            render={(props) => <Article />}
          />
          <Route
            exact
            path={"/login"}
            render={(props) =>
              isAuthenticated ? <Redirect to="/" /> : <Login />
            }
          />
          <Route
            exact
            path={"/login-as"}
            render={(props) =>
              isAuthenticated ? <Redirect to="/" /> : <LoginAs />
            }
          />

          <Route
            exact
            path={"/notification"}
            render={(props) => <Notification />}
          />
          <Route
            exact
            path={"/otp-validation"}
            render={(props) => <OtpValidation />}
          />
          <Route exact path={"/password-reset"} component={PasswordReset} />
          <Route
            exact
            path={"/privacy-policy"}
            render={(props) => <PrivacyPolicy />}
          />

          <Route
            authRequired={true}
            exact
            path={"/school-dashboard/configuration"}
            component={SchoolDashboardConfig}
          />
          <ProtectedRoute
            authRequired={true}
            exact
            path={"/school-dashboard/settings"}
            component={SchoolDashboardSettings}
          />
          <ProtectedRoute
            authRequired={true}
            exact
            path={"/school-dashboard/application-list/class"}
            component={SchoolDashboardApplicationClass}
          />
          <ProtectedRoute
            authRequired={true}
            exact
            path={"/school-dashboard/application-list/details"}
            component={SchoolDashboardApplicationListDetails}
          />
          <ProtectedRoute
            authRequired={true}
            exact
            path={"/school-dashboard/application-list/"}
            component={SchoolDashboardApplicationList}
          />
          <ProtectedRoute
            authRequired={true}
            exact
            path={"/school-dashboard/edit"}
            component={SchoolDashboardEdit}
          />
          <ProtectedRoute
            authRequired={true}
            exact
            path={"/school-dashboard/logout"}
            component={SchoolDashboardLogout}
          />

          <ProtectedRoute
            authRequired={true}
            exact
            path={"/school-dashboard"}
            component={SchoolDashboard}
          />

          <Route
            exact
            path={"/school-login"}
            render={(props) =>
              isAuthenticated ? <Redirect to="/" /> : <SchoolLogin />
            }
          />
          <Route
            exact
            path={"/search-results"}
            render={(props) => <SearchResults />}
          />
          <Route
            exact
            path={"/signup"}
            render={(props) =>
              isAuthenticated ? <Redirect to="/" /> : <Signup />
            }
          />
          <Route
            exact
            path={"/terms-of-use"}
            render={(props) => <TermsOfUse />}
          />
          <Route
            exact
            path={"/free-resources"}
            render={(props) => <FreeResources />}
          />

          <Route exact path={"/"} render={(props) => <HomeComponent />} />

          <Route
            exact
            path={"/client-dashboard"}
            render={(props) => <ClientDashboard />}
          />

          <Route
            exact
            path={"/client-dashboard/school-admins"}
            render={(props) => <SchoolAdmins />}
          />

          <Route
            exact
            path={"/client-dashboard/sub-accounts"}
            render={(props) => <SubAccounts />}
          />

          <Route
            exact
            path={"/request-enquiry"}
            render={(props) => <RequestEnquiry />}
          />

          <Route
            exact
            path={"/otp-enquiry/:schoolId"}
            render={(props) => <OtpRequest />}
          />

          <Route
            exact
            path={"/enquiries/:schoolId"}
            render={(props) => <Enquiries />}
          />
        </Switch>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      </BrowserRouter>
    </div>
  );
}

export default App;

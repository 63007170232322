import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useRouter } from "src/hooks/useRouter";
import axios from "axios";

import Hero from "src/components/school/Hero";
import SchoolDetails from "src/components/school/SchoolDetails";
import { API_URL } from "src/utils/constant";
import Loading from "src/components/Loading/Loading";

const SchoolPage = () => {
  const router = useRouter();
  const [schoolData, setSchoolData] = useState(null);
  const id = router.query.id;

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const response = await axios.get(`${API_URL}/school/${id}`);
        setSchoolData(response.data);
      } catch (error) {
        console.error("Error fetching school data:", error);
      }
    };

    fetchSchoolData();
  }, []);

  if (!schoolData) {
    return <Loading />;
  }

  return (
    <>
      <Helmet>
        <title>{schoolData ? schoolData.name : "School"}</title>
      </Helmet>

      <Hero schoolData={schoolData} />
      <SchoolDetails schoolData={schoolData} />
    </>
  );
};

export default SchoolPage;

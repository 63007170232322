import React from "react";
import { Box, Modal, Typography, Grid, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import ActionButtons from "./Buttons/ActionButtons";
import ClassModal from "./ClassModal";
import MapModal from "./MapModal";
import { style } from "./result.styles.js";
import { MoreDetailsIcons } from "./Icons/MoreDetailsIcons";
import { MoreDetailsIconsPhone } from "./Icons/MoreDetailsIcons";
import EnquiryModal from "./EnquiryModal";

const SchoolCard = ({
  item,
  modalOpen,
  setModalOpen,
  mapModalOpen,
  setMapModalOpen,
  openClassModal,
  setOpenClassModal,
  openEnquiryModal,
  setOpenEnquiryModal,
  SearchResultsImage,
  handleDownload,
  decoded,
  router,
}) => {
  const handleOpenModal = (index) => {
    setModalOpen({ ...modalOpen, [index]: true });
  };

  const handleClassModalOpen = (index) => {
    setOpenClassModal({ ...openClassModal, [index]: true });
  };

  const handleMapModalOpen = (index) => {
    setMapModalOpen({ ...mapModalOpen, [index]: true });
  };

  const handleEnquiry = (index) => {
    setOpenEnquiryModal({ ...openEnquiryModal, [index]: true });
  };

  return (
    <div
      className={`bg-white h-100 my-4 shadow mx-auto p-0`}
      style={{ width: "100%", borderRadius: "25px", cursor: "pointer" }}
    >
      <Grid container direction="column" style={{ height: "100%" }}>
        <MapModal
          mapModalOpen={mapModalOpen}
          item={item}
          setMapModalOpen={setMapModalOpen}
        />
        <ClassModal
          openClassModal={openClassModal}
          item={item}
          setOpenClassModal={setOpenClassModal}
          handleDownload={handleDownload} // Passing handleDownload
          decoded={decoded} // Passing decoded
          router={router} // Passing router
        />
        <EnquiryModal
          openEnquiryModal={openEnquiryModal}
          item={item}
          setOpenEnquiryModal={setOpenEnquiryModal}
        />

        {item.admission_status === "open" && (
          <div className="activeRibbon">
            <span>Open</span>
          </div>
        )}
        {item.admission_status === "closed" && (
          <div className="ribbon">
            <span>Closed</span>
          </div>
        )}
        <Grid
          container
          direction="row"
          style={{ height: "100%", marginTop: "-50px" }}
          alignItems="center"
        >
          <div className="d-flex">
            <img
              src={SearchResultsImage}
              alt={item?.name}
              width="95"
              height="95"
              className="rounded-circle shadow"
              style={{
                height: "fit-content",
                marginLeft: "20px",
                marginRight: "10px",
              }}
            />
            <Typography component="div" style={{ width: "85%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginLeft: "10px",
                  marginTop: "20px",
                }}
              >
                <div>
                  <Link className="box" to={`/school/${item?.id}`}>
                    <Typography
                      variant="h5"
                      className="mb-0"
                      style={{ fontFamily: "Space Grotesk", fontWeight: 500 }}
                    >
                      {item?.name}
                    </Typography>
                  </Link>
                </div>
              </div>
              {modalOpen[item.id] && (
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  closeAfterTransition
                  open={modalOpen[item.id]}
                >
                  <Fade in={modalOpen[item.id]}>
                    <Box sx={style}>
                      <CloseIcon
                        style={{
                          float: "right",
                          cursor: "pointer",
                          color: "gray",
                          marginBottom: "1rem",
                        }}
                        onClick={() =>
                          setModalOpen({ ...modalOpen, [item.id]: false })
                        }
                      />
                      <div
                        className="table"
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              fontSize: "1rem",
                              fontFamily: "Space Grotesk",
                            }}
                          >
                            Available Classes for {item?.name}
                          </div>
                          <hr />
                        </div>
                      </div>
                      {item.sortedClasses.map((c) => (
                        <div
                          className="py-2"
                          style={{ display: "flex", flexDirection: "row" }}
                          key={c.id}
                        >
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                fontSize: "1rem",
                                fontFamily: "Space Grotesk",
                              }}
                            >
                              {c?.name}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Box>
                  </Fade>
                </Modal>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                style={{
                  fontSize: "0.8rem",
                  marginLeft: "10px",
                  fontFamily: "Space Grotesk",
                }}
              >
                {item.District?.name === item.Locality?.name
                  ? item.District?.name || item.Locality?.name
                  : `${item.District?.name}${
                      item.District?.name && item.Locality?.name ? ", " : ""
                    }${item.Locality?.name}`}
              </Typography>
              <div>
                <Typography style={{ marginTop: "30px" }} component="div">
                  <Typography component="div">
                    <MoreDetailsIcons
                      item={item}
                      handleOpenModal={() => handleOpenModal(item.id)}
                      handleClassModalOpen={() =>
                        item.admission_status === "open" &&
                        handleClassModalOpen(item.id)
                      }
                      handleEnquiry={() => handleEnquiry(item.id)}
                    />
                  </Typography>
                </Typography>
              </div>
            </Typography>
          </div>
          <Typography
            component="div"
            className="d-flex flex-column align-items-end w-100"
            style={{ marginTop: "10px" }}
          >
            <ActionButtons
              item={item}
              handleOpenModal={() => handleOpenModal(item.id)}
              handleClassModalOpen={() =>
                item.admission_status === "open" &&
                handleClassModalOpen(item.id)
              }
              handleEnquiry={() => handleEnquiry(item.id)}
            />
            <MoreDetailsIconsPhone
              item={item}
              className="mx-0 mt-1"
              handleMapModalOpen={() => handleMapModalOpen(item.id)}
            />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default SchoolCard;

import { Typography } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import Card from "../Authentication/CardLayout/Card";
import { API_URL } from "../../utils/constant";
import { Formik } from "formik";
import styles from "../../styles/Contact.module.css";
import ButtonRectangle from "../Authentication/Button/ButtonRectangle";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRouter } from "src/hooks/useRouter";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const OtpEnquiry = () => {
  const [Iferrors, setIfErrors] = useState("");
  const history = useHistory();
  const router = useRouter();
  const { schoolId } = router.query;

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    axios
      .post(`${API_URL}/enquiry/verify-otp-for-enquiry-access/${schoolId}`, {
        otp: values.otp,
      })
      .then((response) => {
        setSubmitting(false);
        toast.success("OTP verified successfully!");
        resetForm();
        localStorage.setItem("token", response.data.token);
        setTimeout(() => {
          history.push(`/enquiries/${schoolId}`);
        }, 1000);
      })
      .catch((err) => {
        setIfErrors(err.response.data.message || "An error occurred");
        setSubmitting(false);
        console.error(err);
      });
  };

  const resendOtp = () => {
    axios
      .post(`${API_URL}/enquiry/request-otp-for-enquiry-access/${schoolId}`)
      .then(() => {
        toast.success("OTP has been resent to your email!");
      })
      .catch((err) => {
        setIfErrors(err.response.data.message || "An error occurred");
        console.error(err);
      });
  };

  return (
    <div className="lg:mt-24 lg:mb-8">
      <Card className="mx-auto" noWrap>
        <Typography component="div" className="text-center">
          <Typography variant="h4" className="text-center mb-1">
            Validate your email
          </Typography>
        </Typography>
        <Typography component="div" className="text-center">
          <Typography component="div" className="text-center text-black-50">
            Enter the OTP sent to your email address
          </Typography>
        </Typography>

        <Typography component="div">
          <Formik
            initialValues={{ otp: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.otp) {
                errors.otp = "Required";
              }
              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                {Iferrors ? (
                  <div
                    className={styles.errorStyle}
                    role="alert"
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "10px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      fontWeight: "bold",
                      borderRadius: "5px",
                      backgroundColor: "#f8d7da",
                    }}
                  >
                    {Iferrors}
                  </div>
                ) : null}
                {errors.otp && touched.otp && errors.otp ? (
                  <>
                    <input
                      name="otp"
                      type="text"
                      className={`${styles.formError} text-center form-control rounded border-black mb-3 form-input mt-4 py-2`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otp}
                    />
                  </>
                ) : (
                  <input
                    name="otp"
                    type="text"
                    className={`${styles.formControl} text-center form-control rounded border-black mb-3 form-input mt-4 py-2`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.otp}
                  />
                )}

                <ButtonRectangle action="Verify OTP"></ButtonRectangle>
              </form>
            )}
          </Formik>
        </Typography>
        <Typography component="div" className="text-center my-3">
          <Typography variant="div" className="text-center text-black-50">
            Didn't receive the OTP?{" "}
            <a
              onClick={resendOtp}
              className="text-dark text-decoration-none"
              style={{ cursor: "pointer" }}
            >
              Resend OTP
            </a>
          </Typography>
        </Typography>
      </Card>
    </div>
  );
};

export default OtpEnquiry;

// MapModal.js
import React from "react";
import { Modal, Fade, Box, Backdrop } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { mapStyle } from "./result.styles.js";
import GoogleMapComponent from "./GoogleMapComponent";

const MapModal = ({ mapModalOpen, item, setMapModalOpen }) => {
  const handleClose = () => {
    setMapModalOpen({ ...mapModalOpen, [item.id]: false });
  };
  return (
    mapModalOpen[item.id] && (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={mapModalOpen[item.id]}
        closeAfterTransition
        onClose={handleClose}
      >
        <Fade in={mapModalOpen[item.id]}>
          <Box sx={mapStyle}>
            <CloseIcon
              style={{
                float: "right",
                cursor: "pointer",
                color: "gray",
                marginBottom: "1rem",
                zIndex: "1000",
              }}
              onClick={() =>
                setMapModalOpen({ ...mapModalOpen, [item.id]: false })
              }
            />
            <GoogleMapComponent coordinates={item.geolocation.coordinates} />
          </Box>
        </Fade>
      </Modal>
    )
  );
};

export default MapModal;

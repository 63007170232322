import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "./utils/useAuth";

const ProtectedRoute = ({ component: Component, authRequired, ...rest }) => {
  const { decoded } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (decoded) {
        setLoading(false);
      } else if (authRequired && !decoded) {
        setLoading(false);
        <Redirect to="/login" />;
      }
    }, 500);
  }, [decoded]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (authRequired && !decoded) {
    return <Redirect to="/login" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default ProtectedRoute;

import React from 'react'
import { useMemo } from 'react'
import { RE_DIGIT } from '../../../utils/helper'

const OtpInput = ({ value, valueLength, onChange }) => {
  const valueItems = useMemo(() => {
    const valueArray = value.split('') // split the value into an array
    const valueItems = [] // ['1', '2', '3', '4', '5', '6'] as default

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i] // get the char at index i

      if (RE_DIGIT.test(char)) {
        // if char is a digit
        valueItems.push(char) // add it to valueItems
      } else {
        valueItems.push('') // otherwise add an empty string
      } // ['1', '2', '3', '', '', ''] as an example
    }

    return valueItems // ['1', '2', '3', '', '', ''] as an example
  }, [value, valueLength]) // re-run if value or valueLength changes

  const onInputChange = (e, idx) => {
    const target = e.target // <input type="text" value="1" />

    const targetValue = target.value // '1' as an example

    const isTargetValueDigit = RE_DIGIT.test(targetValue) // true if targetValue is a digit

    if (!isTargetValueDigit && targetValue !== '') {
      return // if targetValue is not a digit, do nothing
    }

    // if targetValue is a digit, keep it, otherwise set it to ''

    const targetValueToUse = isTargetValueDigit ? targetValue : ''

    /* Checking if the input is a digit. */
    if (!RE_DIGIT.test(targetValueToUse)) {
      return
    }

    const newValue =
      value.substring(0, idx) +
      targetValueToUse +
      value.substring(idx + 1, valueLength) // '123456' as

    onChange(newValue) // call onChange with the new value

    const nextElementSibling = target.nextElementSibling || null // it will be null if the input is the last one

    if (nextElementSibling) {
      nextElementSibling.focus()
    } // it will focus on the next input element
  }

  const inputOnKeyDown = (e) => {
    const target = e.target // <input type="text" value="1" />

    if (e.key !== 'Backspace') {
      return // if the key is not Backspace, do nothing
    }

    const previousElementSibling = target.previousElementSibling || null // it will be null if the input is the first one

    if (previousElementSibling) {
      previousElementSibling.focus()
    } // it will focus on the previous input element

    const newValue = value.substring(0, value.length - 1) + ''

    onChange(newValue)

    console.log(value)

    e.preventDefault() // prevent the default behavior of Backspace

    e.stopPropagation() // stop the propagation of the event

    return false // return false to prevent the default behavior of Backspace (optional)
  }

  return (
    <div className='d-flex justify-content-center align-items-center flex-wrap  gap-2'>
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type='text'
          inputMode='numeric'
          autoComplete='one-time-code'
          pattern='\d{1}'
          maxLength={valueLength}
          className='otp-input bg-white text-black-50 shadow rounded'
          style={{ width: '3rem', height: '3rem', margin: '1rem 0.25rem' }}
          value={digit}
          onChange={(e) => onInputChange(e, idx)}
          onKeyDown={(e) => inputOnKeyDown(e, idx)}
        />
      ))}
      {/* it will render 6 input elements */}
    </div>
  )
}

export default OtpInput

import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styles from "src/styles/Navbar.module.css";
import useAuth from "../../utils/useAuth";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import bgImage from "src/assets/images/school.jpg";

const navigation = [
  { name: "Find Schools", href: "/search-results" },
  { name: "For Schools", href: "/add-school" },
  { name: "Resources", href: "/free-resources" },
];

const Hero = ({ schoolData }) => {
  const { token, decoded } = useAuth() || {};
  const [tokenStatus, setTokenStatus] = useState("");
  const [linkSet, setLinkSet] = useState("");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  useEffect(() => {
    if (token) {
      if (decoded.role === "school-admin") {
        setTokenStatus("Dashboard");
        setLinkSet("/school-dashboard/application-list");
      } else {
        setTokenStatus("Dashboard");
        setLinkSet("/Dashboard");
      }
    } else {
      setTokenStatus("Login");
      setLinkSet("/login-as");
    }
  }, [token, decoded]);

  return (
    <div
      className="bg-white relative h-screen z-10"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-30"></div>
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-20"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "rgba(0, 0, 0, 0.91)",
              }}
              className={styles.LogoStyle}
            >
              <span className="text-white">Admission</span>
              <span className={styles.pedia}>Pedia</span>
            </Link>
          </div>
          <div
            className={`${styles.menuIcon} ${
              isNavExpanded ? styles.open : null
            } text-white`}
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className="hidden lg:flex lg:gap-x-24 ">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-md font-semibold leading-6 text-white"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end ">
            <Link
              className="text-white py-2 px-6 bg-main rounded-xl"
              to={linkSet}
            >
              {tokenStatus}
            </Link>
          </div>
        </nav>
      </header>

      <div className="relative isolate pt-12 ">
        <div className="mx-auto px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-20 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <h1 className="mt-10 max-w-3xl text-4xl font-bold tracking-tight text-white sm:text-6xl">
              {schoolData.name}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-200">
              {schoolData.about}
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                href="#admission-forms"
                className="rounded-md bg-white px-8 py-3.5 text-lg font-semibold text-black shadow-sm hover:bg-pink-100 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Apply now
              </a>
              <a
                href="#contact-details"
                className="text-md font-semibold leading-6 text-gray-200 border border-white px-8 py-3.5 rounded-md"
              >
                View on map <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow"></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";

const GoogleMapComponent = ({ coordinates }) => {
  // Ensure latitude and longitude are extracted correctly
  const [lat, lng] = coordinates.map(parseFloat);

  return (
    <iframe
      title="map"
      width="100%"
      height="100%"
      style={{ border: 0, borderRadius: "8px" }}
      src={`https://maps.google.com/maps?q=${lat},${lng}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
      allowFullScreen=""
    ></iframe>
  );
};

export default GoogleMapComponent;

import React from 'react'
import styles from '../../../styles/GetStarted.module.css'

const FormHead = (props) => {
  return (
    <div>
      <div
        className={styles.title}
        style={{
          fontSize: '28px',
        }}
      >
        {props.itemTitle}
      </div>
    </div>
  )
}

export default FormHead

import React, { useEffect } from "react";
import { Formik, Field } from "formik";
import { useRouter } from "../../hooks/useRouter";
import axios from "axios";
import cookie from "js-cookie";
import LoginFirst from "../../components/LoginFirst/LoginFirst";
import Layout from "../../components/Layout/Layout";
import { API_URL } from "../../utils/constant";
import styles from "../../styles/Contact.module.css";

const cssStyles = {
  select: {
    width: "100%",
    padding: "15px",
    border: "1px solid #7777",
    borderRadius: "10px",
    backgroundColor: "white",
    color: "grey",
  },
};

const Index = () => {
  const [Iferrors, setIfErrors] = React.useState("");
  let successMessage = false;
  const router = useRouter(); //using the router to get the query parameters
  const classID = router.query.classID; //getting the classID from the query parameters
  const schoolID = router.query.id; //getting the schoolID from the query parameters

  const [fields, setFields] = React.useState([]); //this state is for storing the fields and showing them on the form
  const [fieldNames, setFieldNames] = React.useState([]); // this is for managing the formik state and sending the data to the backend

  const [token, setToken] = React.useState(cookie.get("token")); //getting the token from the cookie and storing it in the state

  const [isSignedIn, setIsSignedIn] = React.useState(false); //this state is for checking if the user is signed in or not

  const [selectedDate, setSelectedDate] = React.useState(Date("12-12-2000"));
  const [initialValue, setInitialValues] = React.useState({});
  console.log(selectedDate);

  /* Checking if the user is signed in or not. */
  useEffect(() => {
    if (token) {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, [token]);

  const getIntitalValues = async (fieldDataArr) => {
    let newValues = {};
    await Promise.all(
      fieldDataArr.map((field, index) => {
        if (field.field_name === "Class" && classID) {
          newValues = {
            ...newValues,
            [field.field_name]: classID,
          };
        } else {
          newValues = {
            ...newValues,
            [field.field_name]: "",
          };
        }

        return null;
      })
    );

    console.log("Res===", newValues);

    return newValues;
  };

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const res = await axios.get(
          `${API_URL}/forms/fieldsFixed/` + schoolID,
          {
            headers: {
              Authorization: `Bearer ${token}`, //the token is sent to the backend as a header
            },
          }
        );
        setFields(res.data); //setting the state
        const newInitialState = await getIntitalValues(res.data);
        setInitialValues(newInitialState);
        const classData = res.data.filter(
          (fieldDataE) => fieldDataE.special_type === "class"
        );

        if (classData) {
          const availableClasses = classData[0].field_constraints.set;
        }
        setFieldNames(res.data); //setting the state for formik
      } catch (err) {
        console.error(err); //logging the error
      }
    };
    fetchFields(); //calling the function to fetch the data from the API
  }, [token, schoolID]); //the useEffect hook will run when the token or the schoolID changes

  const getFieldElement = (field, values, handleChange, setFieldValue) => {
    let resultElement = null;
    switch (field.field_type) {
      case "set": {
        if (field.special_type === "class") {
          resultElement = (
            <input
              hidden
              name={field.field_name}
              value={values[field.field_name]}
              onChange={handleChange}
            />
          );
        } else {
          resultElement = (
            <>
              <div className=" text-start">
                <label htmlFor="" className="fw-bold text-black-50 my-1">
                  {field.field_name}
                </label>
              </div>
              <div>
                <select
                  style={cssStyles.select}
                  name={field.field_name}
                  value={values[field.field_name] || ""}
                  onChange={handleChange}
                >
                  <option value="">Select {field.field_name}</option>
                  {field.field_constraints.set.map((option) => {
                    let optionValue, optionLabel;
                    if (
                      typeof option === "string" ||
                      typeof option === "number"
                    ) {
                      optionValue = optionLabel = option;
                    } else {
                      optionValue = option.value;
                      optionLabel = option.label;
                    }
                    return (
                      <>
                        <option
                          value={optionValue}
                          style={{
                            color: "black",
                            backgroundColor: "white",
                          }}
                        >
                          {optionLabel}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
            </>
          );
        }
        break;
      }

      case "email": {
        resultElement = (
          <>
            <div className=" text-start">
              <label htmlFor="" className="fw-bold text-black-50 my-1">
                {field.field_name}
              </label>
            </div>
            <div>
              <input
                type={field.field_type}
                style={{
                  width: "100%",
                  padding: "15px",
                  border: "1px solid #7777",
                  borderRadius: "10px",
                  backgroundColor: "white",

                  color: "grey",
                }}
                name={field.field_name}
                value={values[field.field_name]}
                onChange={(e) => {
                  setFieldValue(field.field_name, e.target.value);
                }}
                className="form-control form-input"
                aria-required="true"
              />
            </div>
          </>
        );
        break;
      }

      case "date":
      case "number":
      case "string": {
        if (
          field.field_name === "Residance Address" ||
          field.field_name === "Residential Address"
        ) {
          resultElement = (
            <>
              <div className=" text-start">
                <label htmlFor="" className="fw-bold text-black-50 my-1">
                  {field.field_name}
                </label>
              </div>
              <textarea
                style={{
                  width: "100%",
                  padding: "15px",
                  border: "1px solid #7777",
                  borderRadius: "10px",
                  backgroundColor: "white",

                  color: "grey",
                }}
                rows="3"
                name={field.field_name}
                value={values[field.field_name] || ""}
                onChange={handleChange}
                maxLength={field.field_constraints.max_length}
                minLength={field.field_constraints.min_length}
                placeholder="e.g No. 12 Sabari Street, Nesapakkam, K.K. Nagar West, Chennai - 600 078"
              />
            </>
          );
        } else {
          resultElement = (
            <>
              <div className=" text-start">
                <label htmlFor="" className="fw-bold text-black-50 my-1">
                  {field.field_name}
                </label>
              </div>
              <div>
                <input
                  type={field.field_type === "date" ? "date" : ""}
                  style={{
                    width: "100%",
                    padding: "15px",
                    border: "1px solid #7777",
                    borderRadius: "10px",
                    backgroundColor: "white",

                    color: "grey",
                  }}
                  name={field.field_name}
                  value={values[field.field_name]}
                  onChange={(e) => {
                    if (field.field_type === "number") {
                      let newValue = e.target.value;
                      newValue = newValue.replace(/\D/gm, "");

                      setFieldValue(field.field_name, newValue);
                    } else {
                      setFieldValue(field.field_name, e.target.value);
                    }
                  }}
                  className="form-control form-input"
                  aria-required="true"
                />
              </div>
            </>
          );
        }
        break;
      }

      default: {
        break;
      }
    }

    return resultElement;
  };

  return (
    <Layout>
      <div
        style={{
          backgroundColor: "#FEF7F9",
          minHeight: "100vh",
          color: "grey",
          padding: "5rem 0",
        }}
      >
        {isSignedIn ? (
          <div>
            <div
              style={{
                backgroundColor: "#fff",
                boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.1)",
                paddingBottom: "20px",
                borderRadius: "15px",
                width: "75%",
                paddingTop: "25px",
                marginTop: "20px",
              }}
              className="centering px-lg-5 px-3 mx-auto"
            >
              <Formik
                initialValues={initialValue}
                enableReinitialize={true}
                validate={(values) => {
                  const errors = {};
                  fields.forEach((field) => {
                    const min_length = field.field_constraints.min_length
                      ? +field.field_constraints.min_length
                      : null;
                    const max_length = field.field_constraints.max_length
                      ? +field.field_constraints.max_length
                      : null;

                    if (
                      !values[field.field_name] &&
                      field.field_constraints.field_required
                    ) {
                      errors[field.field_name] = "Required";
                    } else if (
                      values[field.field_name] &&
                      min_length &&
                      values[field.field_name].length < min_length
                    ) {
                      errors[
                        field.field_name
                      ] = `${field.field_name} should contain atleast ${min_length} characters`;
                    } else if (
                      values[field.field_name] &&
                      max_length &&
                      values[field.field_name].length > max_length
                    ) {
                      errors[
                        field.field_name
                      ] = `Maximum ${max_length} characters allowed`;
                    } else if (
                      field.field_type === "email" &&
                      values[field.field_name]
                    ) {
                      if (
                        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          values[field.field_name]
                        )
                      ) {
                        errors[field.field_name] = `Enter a valid email`;
                      }
                    }
                  });
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  /* This is a hack to get the class id. The class id is required to send the data to the backend. */

                  setSubmitting(true);

                  /* This is the data that is being sent to the backend. */
                  const data = {
                    schoolId: router.query.id,
                    classId: parseInt(classID),
                    fields: fields.map((field) => ({
                      fieldId: field.id,
                      fieldValue:
                        field.field_name === "Class"
                          ? parseInt(values[field.field_name])
                          : field.field_type === "date"
                          ? values[field.field_name]
                              .split("-")
                              .reverse()
                              .join("-") //this is a hack to convert the date format to the format that the backend accepts (dd-mm-yyyy)
                          : values[field.field_name],
                    })),
                  };

                  console.log("data", data);
                  axios
                    .post(`${API_URL}/forms/submissions/add`, data, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    })

                    .then((res) => {
                      console.log("values", values);
                      console.log("res123", res);

                      if (res.data.success) {
                        setSubmitting(false);
                        console.log("success");
                        router.push("/apply/thank-you");
                      } else {
                        setSubmitting(false);
                        console.log(res.data.message);
                        setIfErrors(res.data.message);
                        window.scrollTo(0, 0);
                      }
                    })
                    .catch((err) => {
                      setIfErrors(err.res.data.err);
                      setSubmitting(false);
                      console.error(err);
                    });
                }}
              >
                {({
                  errors,
                  values,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* <div
                      style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Apply for
                      {" " + className + " "} Class
                    </div> */}
                    <div
                      style={{
                        color: "grey",
                        fontSize: "14px",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      Fill in the form below.
                    </div>

                    {Iferrors ? (
                      <div
                        className={styles.errorStyle}
                        role="alert"
                        style={{
                          color: "red",
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "10px",
                          marginBottom: "10px",
                          fontWeight: "bold",
                          borderRadius: "5px",
                          backgroundColor: "#f8d7da",
                        }}
                      >
                        {Iferrors}
                      </div>
                    ) : null}

                    {values &&
                      fields.map((field) => (
                        <div className="my-4" key={field.field_name}>
                          {getFieldElement(
                            field,
                            values,
                            handleChange,
                            setFieldValue
                          )}
                          <div className="text-end text-danger">
                            {errors[field.field_name]}
                          </div>
                        </div>
                      ))}
                    <div className="my-5">
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#F876A7",
                          width: "100%",
                          padding: "15px",
                          border: "none",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 2px white",
                        }}
                      >
                        <div className="text-center text-white">Apply</div>
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        ) : (
          <LoginFirst />
        )}
      </div>
    </Layout>
  );
};

export default Index;

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { File, Plus } from "lucide-react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  borderRadius: "5px",
  p: 4,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  age: Yup.number()
    .required("Age is required")
    .positive("Age must be positive"),
  gender: Yup.string().required("Gender is required"),
  allergies: Yup.string(),
});

export default function AddChildModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fileInputRef = React.useRef(null);
  const [selectedFileName, setSelectedFileName] = React.useState("");
  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  // Function to update the selected file name when a file is chosen
  const handleFileChange = (e) => {
    const fileName = e.target.files[0]?.name || "";
    setSelectedFileName(fileName);
  };

  return (
    <div>
      <Button
        variant="outlined"
        className="btnHover border-pink-300 focus:ring-pink-300 ring-pink-200 border-2 text-md flex items-center gap-3 py-2"
        style={{
          color: "#F87FAA",
          borderColor: "#F87FAA",
          borderRadius: "5px",
        }}
        onClick={handleOpen}
      >
        <Plus className="h-6 w-6" />
        Add Child
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Add Child Information
            </Typography>
            <Formik
              initialValues={{
                name: "",
                age: "",
                gender: "",
                allergies: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                // Handle form submission here, you can send values and uploaded file to your server or perform other actions
                console.log(values);
                handleClose();
              }}
            >
              <Form>
                <ChildFormFields />
                <div className="flex items-baseline mb-10">
                  <Button
                    className="btnHover"
                    sx={{ mt: 3 }}
                    variant="outlined"
                    style={{ color: "#F87FAA", borderColor: "#F87FAA" }}
                    onClick={handleFileButtonClick}
                  >
                    <input
                      type="file"
                      hidden
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <File className="mr-2 h-5 w-5" />
                    Upload Certifications
                  </Button>
                  <Typography
                    id="spring-modal-description"
                    sx={{ fontWeight: "500", ml: 2 }}
                  >
                    {selectedFileName && <span> {selectedFileName}</span>}
                  </Typography>
                </div>

                <Button
                  type="submit"
                  className="btnHover border-pink-300 focus:ring-pink-300 ring-pink-200 border-2 text-md flex items-center gap-3 py-2 w-100"
                  style={{
                    color: "#fff",
                    background: "#F87FAA",
                    borderRadius: "5px",
                  }}
                >
                  Submit
                </Button>
              </Form>
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

// Create a separate component for the form fields to use useFormikContext
function ChildFormFields() {
  const formik = useFormikContext();

  return (
    <>
      <Field
        as={TextField}
        name="name"
        label="Name"
        fullWidth
        variant="outlined"
        margin="normal"
        error={Boolean(formik.touched.name && formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        InputProps={{
          sx: { "&:focus": { borderColor: "#FF69B4" } }, // Change borderColor to pink when focused
        }}
      />
      <Field
        as={TextField}
        name="age"
        label="Age"
        type="number"
        fullWidth
        variant="outlined"
        margin="normal"
        error={Boolean(formik.touched.age && formik.errors.age)}
        helperText={formik.touched.age && formik.errors.age}
        InputProps={{
          sx: { "&:focus": { borderColor: "#FF69B4" } }, // Change borderColor to pink when focused
        }}
      />
      <FormControl fullWidth variant="outlined" margin="normal">
        <InputLabel htmlFor="gender">Gender</InputLabel>
        <Field as={Select} name="gender" label="Gender">
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Field>
      </FormControl>

      <Field
        as={TextField}
        name="contact"
        label="Contact Number"
        fullWidth
        variant="outlined"
        margin="normal"
        error={Boolean(formik.touched.name && formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        InputProps={{
          sx: { "&:focus": { borderColor: "#FF69B4" } }, // Change borderColor to pink when focused
        }}
      />
      <Field
        as={TextField}
        name="contact"
        label="GPA"
        fullWidth
        variant="outlined"
        margin="normal"
        error={Boolean(formik.touched.name && formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        InputProps={{
          sx: { "&:focus": { borderColor: "#FF69B4" } }, // Change borderColor to pink when focused
        }}
      />
    </>
  );
}

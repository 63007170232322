import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import { Formik } from 'formik'
import { useRouter } from '../../hooks/useRouter'
import axios from 'axios'
import FormHead from '../../components/GetStarted/Form/FormHead'
import FormLayout from '../../components/GetStarted/Layout/FormLayout'
import PageLayout from '../../components/GetStarted/Layout/PageLayout'
import styles from '../../styles/GetStarted.module.css'
import { API_URL } from '../../utils/constant'
import { Helmet } from 'react-helmet'

const Index = () => {
  /* A hook that allows us to access the router object. */
  const router = useRouter()
  const [board, setBoard] = React.useState([])

  /* Fetching the data from the API and setting the state. */
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const res = await axios.get(`${API_URL}/school/search-filters`)
        setBoard(res.data.boards)
      } catch (err) {
        console.error(err)
      }
    }
    fetchFilters()
  }, [])

  return (
   
      <PageLayout>
         <Helmet>
          <title>Get Started</title>
        </Helmet>
        <FormHead itemTitle='Find Your School' />
        <div
          style={{
            color: 'grey',
            fontSize: '14px',
            textAlign: 'center',
          }}
        >
          Your school can already be on our platform. Please search for your
          school below.
        </div>
        <FormLayout>
          <Formik
            initialValues={{
              board: '',
              registration_no: '',
            }}
            validate={(values) => {
              /* Creating an empty object. */
              const errors = {}

              /* Checking if the values are empty. If they are empty, it will return an error. */
              if (!values.board) {
                errors.board = 'Required'
              }
              if (!values.registration_no) {
                errors.registration_no = 'Required'
              }

              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              /* A hack to make the form submit. */
              setTimeout(() => {
                setSubmitting(false)
                router.push(
                  '/get-started/confirm-details?board=' +
                    values.board +
                    '&registrationNumber=' +
                    values.registration_no
                )
              }, 0.00001)
            }}
          >
            {({ errors, values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Container maxWidth='md' style={{ color: 'white' }}>
                  <div className='my-4'>
                    <div className=' text-start'>
                      <label htmlFor=''>Board</label>
                    </div>
                    <div>
                      <select
                        name='board'
                        className={styles.field}
                        value={values.board}
                        onChange={handleChange}
                      >
                        <option value=''>Select Board</option>
                        {Array.isArray(board) &&
                          board.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>

                      {errors.board && (
                        <div className=' text-end text-danger'>
                          {errors.board}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='my-4'>
                    <div className=' text-start'>
                      <label htmlFor=''>Registration Number</label>
                    </div>
                    <div>
                      <input
                        type='text'
                        placeholder='Enter Registration Number'
                        className={styles.field}
                        name='registration_no'
                        value={values.registration_no}
                        onChange={handleChange}
                      />
                      {errors.registration_no && (
                        <div className=' text-end text-danger'>
                          {errors.registration_no}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='my-5'>
                    <button
                      style={{
                        backgroundColor: '#F876A7',
                        width: '100%',
                        padding: '15px',
                        border: 'none',
                        borderRadius: '10px',
                        boxShadow: '0px 0px 2px white',
                      }}
                    >
                      <div className='text-center text-white'>Search</div>
                    </button>
                  </div>
                </Container>
              </form>
            )}
          </Formik>
        </FormLayout>
      </PageLayout>
    
  )
}

export default Index

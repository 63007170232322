import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import { Grid } from "@mui/material";
import useAuth from "src/utils/useAuth";
import Loading from "../Loading/Loading";

function removeHtmlTags(text) {
  const div = document.createElement("div");
  div.innerHTML = text;
  return div.textContent || div.innerText || "";
}

function formatting(text) {
  const words = text.split(" ");
  const format = words.slice(0, 34);
  return format.join(" ");
}

const FreeResources = () => {
  const history = useHistory();
  const { token, decoded } = useAuth();

  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@info_6783`
    )
      .then((res) => res.json())
      .then((response) => {
        setMediumData(response.items);
        console.log(response);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const finalData = mediumData.slice(0, 6);

  const handleDownload = (resourceType) => {
    if (token) {
      console.log(decoded);
      // Logic for allowing the user to download the resource
      if (resourceType === "digital") {
        console.log(finalData);
        const link = document.createElement("a");
        link.href =
          "https://docs.google.com/spreadsheets/d/1nDODXD7P7Qbc62uR7OhnNdaMUwA46bHCqkKJe8oUp60/export?format=xlsx&gid=0";
        link.download = "digital_version.xlsx";
        link.click();
      } else if (resourceType === "print") {
        const link = document.createElement("a");
        link.href =
          "https://docs.google.com/spreadsheets/d/1bXlITTjAWPmb36CT9C19zUR-NF6JRgatxfxt9kUbxp4/export?format=xlsx&gid=0";
        link.download = "print_version.xlsx";
        link.click();
      }
    } else {
      // Redirect the user to sign up if not signed up
      history.push("/signup");
    }
  };

  return (
    <>
      <meta
        name="description"
        content="Admissionpedia provides a comprehensive solution for managing student attendance of your schools and colleges. We offer both digital and print versions of the attendance sheet. 
         Download the version that suits you best and streamline your attendance recording process with Admissionpedia."
      />

      <div
        className="text-center p-lg-2 m-0 w-100  text-white"
        style={{ backgroundColor: "#F87FAA" }}
      >
        <h1 className="p-5">Free Resources</h1>
      </div>

      <Grid
        alignItems="center"
        justifyContent="center"
        container
        gap={5}
        sx={{ marginY: 10 }}
      >
        {!isLoading && (
          <>
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140, width: "100%" }}
                image="https://i.ibb.co/ZNxTFxC/pexels-johny-rebel-the-explorer-panda-357271.jpg"
                title="attendance sheet"
              />
              <CardContent sx={{ height: 230 }}>
                <Typography gutterBottom variant="h5" component="div">
                  Best practices for School websites
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  In a digital era, school websites play multifaceted role,
                  extending far beyond mere online representation. They function
                  as dynamic hubs, with front-end accessible to the parents &
                  students, and an exclusive admin panel to school staff.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  sx={{ color: "#F87FAA" }}
                  href="/free-resources/best-practices"
                >
                  Learn More
                </Button>
              </CardActions>
            </Card>

            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                sx={{ height: 140 }}
                image="https://images.pexels.com/photos/733857/pexels-photo-733857.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                title="attendance sheet"
              />
              <CardContent sx={{ height: 230 }}>
                <Typography gutterBottom variant="h5" component="div">
                  School Attendance sheet
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Admissionpedia provides a comprehensive solution for managing
                  student attendance. We offer both digital and print versions
                  of the attendance sheet to cater to your specific needs.
                  Download the version that suits you best and streamline your
                  attendance recording process with Admissionpedia.
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  sx={{ color: "#F87FAA" }}
                  size="small"
                  onClick={() => handleDownload("digital")}
                >
                  Digital Version
                </Button>
                <Button
                  sx={{ color: "#F87FAA" }}
                  size="small"
                  onClick={() => handleDownload("print")}
                >
                  Print Version
                </Button>
              </CardActions>
            </Card>
          </>
        )}

        {finalData.map((article) => (
          <Card sx={{ maxWidth: 345 }} key={article.guid}>
            <CardMedia
              sx={{ height: 140 }}
              image={article.thumbnail}
              title="img"
            />
            <CardContent sx={{ height: 230 }}>
              <Typography gutterBottom variant="h5" component="div">
                {article.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formatting(removeHtmlTags(article.content))}...
              </Typography>
            </CardContent>

            <CardActions>
              <Button sx={{ color: "#F87FAA" }} href={article.link}>
                Learn More
              </Button>
            </CardActions>
          </Card>
        ))}

        {/* Display the loading message while waiting for the API response */}
        {isLoading && <Loading />}
      </Grid>
    </>
  );
};

export default FreeResources;

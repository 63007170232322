import { Typography } from "@mui/material";
import React, { useState } from "react";
import axios from "axios";
import Card from "../Authentication/CardLayout/Card";
import { API_URL } from "../../utils/constant";
import { Formik } from "formik";
import styles from "../../styles/Contact.module.css";
import ButtonRectangle from "../Authentication/Button/ButtonRectangle";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

const RequestEnquiry = () => {
  const [Iferrors, setIfErrors] = useState("");
  const history = useHistory();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    try {
      // Get the school ID using the provided email
      const response = await axios.post(
        `${API_URL}/enquiry/get-school-id-from-email`,
        { email: values.email }
      );
      const schoolId = response.data.data;

      // Request OTP using the retrieved school ID
      await axios.post(
        `${API_URL}/enquiry/request-otp-for-enquiry-access/${schoolId}`
      );
      setSubmitting(false);
      toast.success("OTP has been sent to your email!");
      resetForm();
      setTimeout(() => {
        history.push(`/otp-enquiry/${schoolId}`);
      }, 1000);

      console.log("success");
    } catch (err) {
      setIfErrors(err.response.data);
      setSubmitting(false);
      console.error(err);
    }
  };

  return (
    <div className="lg:mt-24 lg:mb-8">
      <Card className="mx-auto" noWrap>
        <Typography component="div" className="text-center">
          <Typography variant="h4" className="text-center mb-1">
            Request Enquiry
          </Typography>
        </Typography>
        <Typography component="div" className="text-center">
          <Typography component="div" className="text-center text-black-50">
            Enter your email
          </Typography>
        </Typography>

        <Typography component="div">
          <Formik
            initialValues={{ email: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (!/\S+@\S+\.\S+/.test(values.email)) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                {Iferrors ? (
                  <div
                    className={styles.errorStyle}
                    role="alert"
                    style={{
                      color: "red",
                      textAlign: "center",
                      fontSize: "14px",
                      padding: "10px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      fontWeight: "bold",
                      borderRadius: "5px",
                      backgroundColor: "#f8d7da",
                    }}
                  >
                    {Iferrors}
                  </div>
                ) : null}
                {errors.email && touched.email && errors.email ? (
                  <>
                    <input
                      name="email"
                      type="email"
                      className={`${styles.formError} text-center form-control rounded border-black mb-3 form-input mt-4 py-2`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </>
                ) : (
                  <input
                    name="email"
                    type="email"
                    className={`${styles.formControl} text-center form-control rounded border-black mb-3 form-input mt-4 py-2`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                )}

                <ButtonRectangle action="Request OTP"></ButtonRectangle>
              </form>
            )}
          </Formik>
        </Typography>
      </Card>
    </div>
  );
};

export default RequestEnquiry;

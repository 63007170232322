import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styles from "src/styles/Navbar.module.css";
import useAuth from "../../utils/useAuth";
import jwtDecode from "jwt-decode";

const Navbar = (props) => {
  const { token, decoded } = useAuth() || {};
  const [tokenStatus, setTokenStatus] = useState("");
  const [linkSet, setLinkSet] = useState("");
  const [enquiryRole, setEnquiryRole] = useState(false);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const getEnquiry = () => {
    const enquiryToken = localStorage.getItem("token");
    if (enquiryToken) {
      return jwtDecode(enquiryToken);
    }
    return null;
  };

  const enquiryDecoded = getEnquiry();

  useEffect(() => {
    if (token) {
      if (decoded?.role === "school-admin") {
        setTokenStatus("Dashboard");
        setLinkSet("/school-dashboard/application-list");
      } else {
        setTokenStatus("Dashboard");
        setLinkSet("/Dashboard");
      }
    } else {
      setTokenStatus("Login");
      setLinkSet("/login-as");
    }

    if (enquiryDecoded) {
      if (enquiryDecoded.role === "enquiry-school-admin") {
        setEnquiryRole(true);
      } else {
        setEnquiryRole(false);
      }
    }
  }, [token, decoded]);

  return (
    <nav className={styles.Navbar} style={{ position: props.position }}>
      <div className={styles.logo}>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.91)",
          }}
          className={styles.LogoStyle}
        >
          <span>Admission</span>
          <span className={styles.pedia}>Pedia</span>
        </Link>
      </div>

      <div
        className={`${styles.menuIcon} ${isNavExpanded ? styles.open : ""}`}
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div
        className={
          isNavExpanded ? styles.navbarElementsMenu : styles.navbarElements
        }
      >
        <ul>
          <li className={styles.navbarItem}>
            <Link className={styles.navbarItemStyle} to="/search-results/">
              Find Schools
            </Link>
          </li>
          <li className={styles.navbarItem}>
            <Link className={styles.navbarItemStyle} to="/add-school">
              <span>For Schools</span>
            </Link>
          </li>
          <li className={styles.navbarItem}>
            <Link className={styles.navbarItemStyle} to="/free-resources">
              Resources
            </Link>
          </li>

          {enquiryRole && (
            <li className={styles.navbarItem}>
              <Link
                className={styles.navbarItemStyle}
                to={`/enquiries/${enquiryDecoded?.userId}`}
              >
                Enquiries
              </Link>
            </li>
          )}

          {tokenStatus && (
            <li className={styles.navbarItemLast}>
              <Link className={styles.navbarItemStyle} to={linkSet}>
                {tokenStatus}
              </Link>
            </li>
          )}
        </ul>
      </div>

      <Link className={styles.navbarItem} to={linkSet}>
        <button className={styles.SignInButton}>{tokenStatus}</button>
      </Link>
    </nav>
  );
};

export default Navbar;

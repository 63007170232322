import React from 'react'
import LongQuesImage from 'src/assets/ForSchools/long-ques.svg'

const LongQues = () => {
  return (
    <div className='container text-center text-md-start' data-aos="fade-up"  data-aos-once='true'>
      <div className='row py-5'>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center centering'>
          <img
            src={LongQuesImage}
            alt='longQues'
            width={500}
            height={500}
            className='img-fluid mx-auto'
          />
        </div>
        <div
          className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center my-5 text-white'
          style={{
            fontFamily: 'Space Grotesk',
          }}
        >
          <h1 className='fs-1 pb-4'>
            <span
              className='fw-bold fs-1'
              style={{
                color: '#864D61',
                borderBottom: '4px solid #864D61',
              }}
            >
              {' '}
              Avoid{' '}
            </span>
            Long Queues
          </h1>
          <div className='text-white fs-3'>
            {
              'AdmissionPedia will empower parents to apply for admissions online from anywhere, at anytime from the comfort of their homes.'
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default LongQues

import React from "react";
import styles from "../../../styles/Login.module.css";

const Button = (props) => {
  return (
    <>
      <button
        type="submit"
        value="submit"
        className={styles.buttonStyle}
        onClick={props.onSub}
        disabled={props.loading} // Disable the button while loading
      >
        {props.loading ? (
          <div className={styles.spinner}></div> // Render a spinner if loading
        ) : (
          props.action
        )}
      </button>
    </>
  );
};

export default Button;

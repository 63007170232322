import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import styles from "../../styles/Login.module.css";
import { Link } from "react-router-dom";
import image from "../../assets/images/login.jpg";
import bg from "../../assets/images/login-bg.svg";
import { Helmet } from "react-helmet";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

function Index() {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>

      <MDBContainer fluid className={styles.containerBg}>
        <MDBRow>
          <MDBCol sm="6" className="login-wrapper">
            <div className="d-flex flex-row ps-5 pt-5">
              <div>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0, 0, 0, 0.91)",
                  }}
                  className="logoStyle"
                >
                  <span>Admission</span>
                  <span className="pedia">Pedia</span>
                </Link>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center h-custom-2 loginWidth pt-1">
              <h3
                className="fw-light mb-5 ps-5 pb-3 loginSub"
                style={{
                  letterSpacing: "1px",
                }}
              >
                Are you a parent or a school staff?
              </h3>

              <Link className="ms-5 text-decoration-none" to="/school-login">
                <div className={styles.loginAs}>
                  <div className={styles.loginAsText}>
                    <div className="d-flex">
                      <div>
                        <AutoStoriesIcon
                          style={{
                            paddingRight: "0.5rem",
                            fontSize: "3rem",
                          }}
                        />
                      </div>
                      <div className="mt-1" style={{ textAlign: "left" }}>
                        <div>I am a school staff</div>
                        <div
                          style={{
                            fontSize: "0.8rem",
                            color: "#C05179",
                            fontWeight: 300,
                          }}
                        >
                          I am a school staff and I want to login to my school
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link className="ms-5 text-decoration-none" to="/login">
                <div className={styles.loginAs}>
                  <div className={styles.loginAsText}>
                    <div className="d-flex">
                      <div>
                        <SupervisorAccountIcon
                          style={{
                            marginRight: "0.5rem",
                            fontSize: "3rem",
                          }}
                        />
                      </div>
                      <div className="mt-1" style={{ textAlign: "left" }}>
                        <div>I am a Parent</div>
                        <div
                          style={{
                            fontSize: "0.8rem",
                            color: "#C05179",
                            fontWeight: 300,
                          }}
                        >
                          I want to apply for a school for my child
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </MDBCol>

          <MDBCol sm="6" className="d-none d-sm-block px-0">
            <img
              src={image}
              alt="Login image"
              className="w-100 vh-100"
              style={{ objectFit: "cover", objectPosition: "left" }}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
}

export default Index;

import React from 'react'
import CommunicationImg from 'src/assets/ForSchools/communication.svg'

const Communication = () => {
  return (
    <div
      className='container py-lg-5 text-white text-center text-md-start'
      style={{
        fontFamily: 'Space Grotesk',
      }}
      data-aos="fade-up"
      data-aos-once='true'
    >
      <div className='row my-5 py-lg-5'>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center centering d-lg-none d-flex'>
          <img
            src={CommunicationImg}
            alt='Communication'
            width={500}
            height={500}
            className='img-fluid'
          />
        </div>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center my-5'>
          <h1 className='fs-1 pb-4'>
            <span
              className='fw-bold fs-1'
              style={{
                color: '#864D61',
                borderBottom: '4px solid #864D61',
              }}
            >
              {' '}
              Follow-up{' '}
            </span>
            with applicants
          </h1>
          <div className='fs-3'>
            If selected, notify the selected candidate about the next steps,
            including requesting payment. Collaborate with parents easily.
          </div>
        </div>
        <div className='col-md-12 col-lg-6 col-sm-12 mx-lg-auto mx-md-0 mx-sm-0 align-content-center centering d-none d-lg-flex'>
          <img
            src={CommunicationImg}
            alt='Communication'
            width={500}
            height={500}
            className='img-fluid'
          />
        </div>
      </div>
    </div>
  )
}

export default Communication

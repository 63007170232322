import React from "react";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useMemo } from "react";
import LayoutDashboard from "../../../components/Dashboard/Layout";
import Loading from "../../../components/Loading/Loading";
import { API_URL } from "../../../utils/constant";
import { useRouter } from "../../../hooks/useRouter";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { Button } from "reactstrap";

const Results = () => {
  const [schools, setSchools] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const router = useRouter();

  // const schoolID = router.query.schoolId
  const schoolName = router.query.schoolName;
  const submissionId = router.query.submissionId;

  const fetchSchools = useMemo(
    () => async () => {
      setLoading(true); // set loading to true before making the API call
      const res = await fetch(
        `${API_URL}/forms/user/submissions?submission_id=${submissionId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await res.json();
      setSchools(data.map((item) => ({ ...item })));
      setLoading(false); // set loading to false after the API call has completed
    },
    [submissionId]
  );
  useEffect(() => {
    fetchSchools();
  }, [fetchSchools]);

  const generatePDF = async () => {
    // Load the existing PDF from the provided file (Modify 'path/to/existing.pdf' with the correct file path)
    const existingPdfBytes = await fetch("/template.pdf").then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get the first page of the existing PDF
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Add content to the first page using pdf-lib
    const { width, height } = firstPage.getSize();
    // Clear existing content on the first page

    firstPage.drawText(`${schoolName}`, { x: 32, y: 790 });

    const textOptions = {
      size: 11,
      font: font,
      color: rgb(100 / 255, 100 / 255, 100 / 255),
    };

    let yPosition = height - 85;
    const tableX = 34;
    const tableWidth = 525;
    const rowHeight = 25;

    // // Add the title
    // const title = "Application Details";
    // const titleWidth = font.widthOfTextAtSize(title, 18);
    // const titleX = (width - titleWidth) / 2;
    // firstPage.drawText(title, {
    //   ...textOptions,
    //   x: titleX,
    //   y: yPosition,
    //   size: 17,
    // });
    yPosition -= 50; // Move down

    // Add school details as a table
    const schoolsTableY = yPosition;

    const drawTableRow = (fieldName, fieldValue) => {
      // Draw the background color for the field value column
      firstPage.drawRectangle({
        x: tableX + tableWidth / 2,
        y: yPosition, // Add some padding to center the text vertically
        width: tableWidth / 2,
        height: rowHeight, // Adjust the height to avoid overlapping with borders
        color: rgb(240 / 255, 240 / 255, 240 / 255), // Set the background color
      });

      firstPage.drawText(fieldName, {
        ...textOptions,
        x: tableX,
        y: yPosition,
      });

      firstPage.drawText(fieldValue, {
        ...textOptions,
        x: tableX + tableWidth / 2 + 10, // Add some padding to center the text horizontally
        y: yPosition + 6,
        color: rgb(80 / 255, 80 / 255, 80 / 255), // Set the text color to black (optional, in case you want a different text color)
      });

      yPosition -= rowHeight;
    };

    yPosition -= rowHeight; // Move down to the first row position

    // Add school details

    console.log(schools);

    schools.forEach((school) => {
      drawTableRow("School ID:", school.school_id);

      school.values.forEach((item) => {
        drawTableRow(item.fieldName + ":", item.field_value);
      });

      // Add some space between schools
      yPosition -= rowHeight;
    });

    // Save the modified PDF
    const modifiedPdfBytes = await pdfDoc.save();
    const modifiedPdfBlob = new Blob([modifiedPdfBytes], {
      type: "application/pdf",
    });

    // Create a download link and trigger click to download the modified PDF
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(modifiedPdfBlob);
    downloadLink.download = "modified-pdf-filename.pdf";
    downloadLink.click();
  };

  return (
    <LayoutDashboard>
      <Typography
        component="div"
        className="lg-p-5 mt-5 pt-5"
        style={{ minHeight: "200vh" }}
      >
        <div
          id="pdf-content"
          className="container-fluid"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3
            className=" mx-lg-5 px-lg-5 mx-4"
            style={{
              color: "gray",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Application Details
          </h3>
          <div className=" text-muted centering">
            Track your application status here and view your application
            details.
          </div>
          {loading ? <Loading /> : null}
          {!loading && schools.length === 0 ? (
            <div className="text-center centering">
              You have not applied to any school yet
            </div>
          ) : null}
          {schools.map((item) => (
            <>
              <div
                className={`bg-white my-4 shadow mx-auto p-2`}
                style={{
                  borderRadius: "25px",
                  cursor: "pointer",
                  height: "auto",
                  width: "100%",
                  margin: "2%",
                }}
                key={item.id + item.school_id + item.class_id}
              >
                <div className="py-2">
                  <table className="table table-borderless">
                    {item.values.map((item) => (
                      <tbody key={item.id}>
                        {item.fieldName === "Class" ? (
                          <tr key={item.fieldName}>
                            <td className="text-muted">{item.fieldName}:</td>
                            <td>{item.className}</td>
                          </tr>
                        ) : (
                          <tr key={item.fieldName}>
                            <td className="text-muted">{item.fieldName}:</td>
                            <td>{item.field_value}</td>
                          </tr>
                        )}
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
              <Button className="mt-2 py-3" block onClick={generatePDF}>
                Download Application List
              </Button>
            </>
          ))}
        </div>
      </Typography>
    </LayoutDashboard>
  );
};

export default Results;

import React from "react";
import SchoolLogin from "../../components/Authentication/Login/SchoolLogin";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>School Sign in</title>
      </Helmet>

      <SchoolLogin />
    </>
  );
};

export default Index;

import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import FavoriteNotifyToast from "../../Shortlist/Shortlist";
import cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import styles from "../../../styles/SearchResults.module.css";

const ActionButtons = (props) => {
  const [token, setToken] = useState(cookie.get("token"));
  const decoded = token ? jwt_decode(token) : null;

  return (
    <Typography
      component="div"
      style={{
        width: "100%",
        padding: "0 1rem",
      }}
    >
      <Typography
        component="div"
        className="d-lg-none justify-content-between d-flex gap-3 mt-2 align-items-center my-2 "
      >
        <div
          className={styles.classes}
          onClick={() => {
            props.handleOpenModal();
          }}
        >
          Available Classes
        </div>
        <Typography component="div" className={styles.TextStyle}>
          <FavoriteNotifyToast
            message={props.item.name + " added to your shortlist"}
          />
        </Typography>
      </Typography>
      {props.item.enquiry_school && (
        <div className="d-lg-none d-block">
          <div
            className={styles.classes}
            onClick={() => {
              props.handleEnquiry();
            }}
          >
            Enquiry
          </div>
        </div>
      )}
      {props.item.admission_status === "open" && (
        <div className="d-lg-none d-block">
          <div
            className={styles.ApplyButtonPhone}
            onClick={() => {
              props.handleClassModalOpen();
            }}
          >
            Apply
          </div>
        </div>
      )}
    </Typography>
  );
};

export default ActionButtons;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchFilter_AdvancedSearchText__jPa3N {\n  text-decoration: underline;\n  border-bottom: 0.6px;\n  color: white;\n  font-weight: 600;\n  font-size: 16px;\n  cursor: pointer;\n  float: right;\n  margin-top: -15px;\n  margin-right: 20px;\n}\n\n.SearchFilter_filterClass__VrQIh {\n  padding: 1rem 0 0;\n}\n\n.SearchFilter_filterLabel__nVHig {\n  font-family: \"Space Grotesk\";\n  font-size: 1.2rem;\n  padding-bottom: 0.5rem;\n  margin-top: 1.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/SearchFilter.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,oBAAoB;EACpB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;EAC5B,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":[".AdvancedSearchText {\n  text-decoration: underline;\n  border-bottom: 0.6px;\n  color: white;\n  font-weight: 600;\n  font-size: 16px;\n  cursor: pointer;\n  float: right;\n  margin-top: -15px;\n  margin-right: 20px;\n}\n\n.filterClass {\n  padding: 1rem 0 0;\n}\n\n.filterLabel {\n  font-family: \"Space Grotesk\";\n  font-size: 1.2rem;\n  padding-bottom: 0.5rem;\n  margin-top: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AdvancedSearchText": "SearchFilter_AdvancedSearchText__jPa3N",
	"filterClass": "SearchFilter_filterClass__VrQIh",
	"filterLabel": "SearchFilter_filterLabel__nVHig"
};
export default ___CSS_LOADER_EXPORT___;

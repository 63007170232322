import React from "react";
import LayoutClient from "../../../components/ClientDashboard/LayoutClient";
import { Helmet } from "react-helmet";
import { Button, Typography } from "@material-tailwind/react";
import Image from "../images/avatar.svg";
const people = [
  {
    name: "Leslie Alexander",
    email: "leslie.alexander@example.com",
    imageUrl: Image,
    href: "#",
  },
  {
    name: "Michael Foster",
    email: "michael.foster@example.com",
    imageUrl: Image,
    href: "#",
  },
  {
    name: "Dries Vincent",
    email: "dries.vincent@example.com",
    imageUrl: Image,
    href: "#",
  },
];

const SchoolAdmins = () => {
  return (
    <Typography component="div">
      <Helmet>
        <title>School Admins</title>
      </Helmet>
      <LayoutClient>
        <div className="bg-white my-16 py-3 px-8">
          <ul role="list" className="divide-y divide-gray-100">
            {people.map((person) => (
              <li
                key={person.email}
                className="flex items-center justify-between gap-x-6 py-4"
              >
                <div className="flex gap-x-4">
                  <img
                    className="h-12 w-12 flex-none rounded-full "
                    src={person.imageUrl}
                    alt=""
                  />
                  <div className="min-w-0 flex-auto">
                    <p className="text-sm font-semibold  text-gray-900">
                      {person.name}
                    </p>
                    <p className=" truncate text-xs text-gray-500">
                      {person.email}
                    </p>
                  </div>
                </div>

                <div className="flex gap-3">
                  <Button
                    variant="outlined"
                    href={person.href}
                    className="rounded-full px-3 py-2 text-xs font-semibold text-black shadow-sm  "
                  >
                    Edit
                  </Button>
                  <Button
                    color="red"
                    href={person.href}
                    className="rounded-full px-3 py-2 text-xs font-semibold shadow-sm  "
                  >
                    Delete
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </LayoutClient>
    </Typography>
  );
};

export default SchoolAdmins;

import React, { useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import LayoutDashboard from "../../components/Dashboard/Layout";
import useAuth from "../../utils/useAuth";
import { Link } from "react-router-dom";
import { API_URL } from "../../utils/constant";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const { token, decoded } = useAuth();
  const [schools, setSchools] = React.useState([]);
  const [applied, setApplied] = React.useState([]);

  const fetchShorlistedSchools = useMemo(
    () => () => {
      fetch(`${API_URL}/school/shortlisted`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        console.log(decoded);
        if (res.ok) {
          res.json().then((data) => {
            setSchools(
              data.data.map((item) => ({
                ...item,
              }))
            );
          });
        }
      });
    },
    [token]
  );

  const fetchSchools = useMemo(
    () => async () => {
      const res = await fetch(`${API_URL}/school/applied`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await res.json();
      setApplied(data.data.map((item) => ({ ...item })));
    },
    []
  );

  useEffect(() => {
    fetchShorlistedSchools();
    fetchSchools();
  }, [fetchShorlistedSchools, fetchSchools]);

  return (
    <Typography component="div">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <LayoutDashboard>
        <div>
          {token && decoded && (
            <div className="mt-5 pt-lg-5">
              <div className="">Welcome,</div>
              <div className="fs-3">{decoded.email}!</div>
            </div>
          )}
        </div>
        <div>
          <div className="row">
            <h2 className="col-12 centering my-4 text-muted"></h2>

            <div className="col-12 col-lg-6">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h2 className="card-title">{schools.length}</h2>
                  <h5 className="card-title text-muted">Shortlisted Schools</h5>
                  <div className="card-text ">
                    You have shortlisted {schools.length} schools.
                  </div>

                  <br />
                  <Link
                    to="Dashboard/shortlisted-schools"
                    style={{
                      color: "#f50057",
                      padding: "10px",
                      borderRadius: "10px",
                      textDecoration: "none",
                      marginTop: "50px",
                    }}
                  >
                    View Shortlisted Schools
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h2 className="card-title">{applied.length}</h2>
                  <h5 className="card-subtitle mb-2 text-muted">
                    Applied Schools
                  </h5>
                  <div className="card-text">
                    You have applied to {applied.length} schools.
                  </div>
                  <br />
                  <Link
                    to="Dashboard/applied-schools"
                    style={{
                      color: "#f50057",
                      padding: "10px",
                      borderRadius: "10px",
                      textDecoration: "none",
                      marginTop: "50px",
                    }}
                  >
                    Track Application Status
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDashboard>
    </Typography>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SideBar from "../../components/SearchResults/Sidebar/SideBar";
import Layout from "../../components/Layout/Layout";
import styles from "../../styles/SearchResults.module.css";
import Results from "../../components/SearchResults/Results";
import { FilterProvider } from "../../context/FilterContext";
import { Helmet } from "react-helmet";

const SearchResults = () => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [locality, setLocality] = useState(null);
  const [pincodeTerm, setPincodeTerm] = useState(null);

  console.log("main page: ", locality);
  return (
    <FilterProvider>
      <Helmet>
        <title>Find Schools</title>
      </Helmet>
      <Layout>
        <div className={styles.mainContainer}>
          <div className={styles.gridWrapper}>
            <Grid container direction="row" columnSpacing={6}>
              {(searchTerm !== null ||
                locality !== undefined ||
                pincodeTerm !== undefined) && (
                <Grid item lg={3} md={4} sm>
                  <SideBar />
                </Grid>
              )}

              <Grid
                item
                lg={
                  searchTerm !== null ||
                  locality !== undefined ||
                  pincodeTerm !== undefined
                    ? 9
                    : 12
                }
                md={
                  searchTerm !== null ||
                  locality !== undefined ||
                  pincodeTerm !== undefined
                    ? 8
                    : 12
                }
                sm={12}
                xs={12}
              >
                <Results
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  locality={locality}
                  setLocality={setLocality}
                  pincodeTerm={pincodeTerm}
                  setPincodeTerm={setPincodeTerm}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Layout>
    </FilterProvider>
  );
};

export default SearchResults;

import React from "react";
import Layout from "../../components/Layout/Layout";
import EnquiryList from "src/components/Enquiry/EnquiryList";
import { ToastContainer } from "react-toastify";

const Index = () => {
  return (
    <Layout>
      <EnquiryList />
    </Layout>
  );
};

export default Index;

import * as React from "react";
import { useState, useEffect, useContext, useRef } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Typography } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useRouter } from "../../hooks/useRouter";
import styles from "../../styles/SearchButton.module.css";
import { API_URL } from "../../utils/constant";
import { LocationOn } from "@mui/icons-material";
import { FilterContext } from "src/context/FilterContext";

const ValidationSchema = yup.object().shape({
  search: yup.string().required("Required"),
});

export default function SearchSchools({ refreshSchools, searchTerm }) {
  const [inputValue, setInputValue] = React.useState(searchTerm || "");
  const [inputLocation, setInputLocation] = useState("");
  const [inputDistrict, setInputDistrict] = useState("");
  const [pincode, setPincode] = useState("");
  const { filters, setFilters } = useContext(FilterContext);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [error, setError] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [districtSuggestions, setDistrictSuggestions] = useState([]);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const suggestionsRef = useRef(null);

  const router = useRouter();

  const fetchLocationSuggestions = async () => {
    const districtQueryParam = selectedDistrict
      ? `&district=${selectedDistrict.value}`
      : "";
    const res = await fetch(
      `${API_URL}/location?keyword=${inputLocation}${districtQueryParam}`
    );
    const data = await res.json();

    setLocationSuggestions(
      data.map((item) => ({
        value: item.locality_id,
        label: item.locality_name,
        districtValue: item.district_id,
        districtLabel: item.district_name,
      }))
    );
  };

  const fetchDistrictSuggestions = async () => {
    const res = await fetch(
      `${API_URL}/location/districts?keyword=${inputDistrict}`
    );
    const data = await res.json();

    setDistrictSuggestions(
      data.map((item) => ({
        value: item.district_id,
        label: item.district_name,
      }))
    );
  };

  const updateAppliedFilters = () => {
    const updatedFilters = [];

    if (selectedDistrict && selectedDistrict.value) {
      updatedFilters.push({
        id: "district",
        name: selectedDistrict.label,
        value: selectedDistrict.value,
      });
    }

    if (selectedLocation && selectedLocation.value) {
      updatedFilters.push({
        id: "location",
        name: selectedLocation.label,
        value: selectedLocation.value,
      });
      if (selectedLocation.districtValue) {
        updatedFilters.push({
          id: "district",
          name: selectedLocation.districtLabel,
          value: selectedLocation.districtValue,
        });
      }
    }

    setAppliedFilters(updatedFilters);
  };

  // Handler for location input change
  const handleLocationChange = (e) => {
    const inputValue = e.target.value;
    setInputLocation(inputValue);
    setError("");

    if (inputValue.trim().length > 0) {
      if (/^\d+$/.test(inputValue)) {
        console.log("pincode:", inputValue);
        setPincode(inputValue);

        // Check if pincode filter already exists
        const pincodeFilterExists = appliedFilters.some(
          (filter) => filter.id === "pincode"
        );

        if (!pincodeFilterExists) {
          // Update both appliedFilters and filters only if pincode filter doesn't exist
          setAppliedFilters((prevFilters) => [
            ...prevFilters,
            {
              id: "pincode",
              name: "Pincode",
              value: inputValue,
            },
          ]);
          setFilters({
            ...filters,
            pincode: inputValue ? inputValue : null,
          });
        } else {
          // If pincode filter already exists, update its value
          setAppliedFilters((prevFilters) =>
            prevFilters.map((filter) =>
              filter.id === "pincode"
                ? { ...filter, value: inputValue }
                : filter
            )
          );
          setFilters({
            ...filters,
            pincode: inputValue ? inputValue : null,
          });
        }
      } else {
        // If not a number, fetch location suggestions
        fetchLocationSuggestions();
      }
    } else {
      // If input value for pincode is empty, clear the pincode filter
      setPincode("");
      setAppliedFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.id !== "pincode")
      );
      setFilters({
        ...filters,
        pincode: null,
      });
      setLocationSuggestions([]);
    }
  };

  const handleDistrictChange = (e) => {
    const inputValue = e.target.value;
    setInputDistrict(inputValue);
    setError("");

    if (inputValue.trim().length > 0) {
      fetchDistrictSuggestions();
    } else {
      setDistrictSuggestions([]);
    }
  };

  const handleLocationSelection = (selectedLocation) => {
    console.log("Selected Location:", selectedLocation);
    setSelectedLocation(selectedLocation);

    // Update the filters state
    setFilters({
      ...filters,
      location: selectedLocation.value ? selectedLocation.value : null,
      district: selectedLocation.districtValue
        ? selectedLocation.districtValue
        : null,
    });

    // Update the input fields
    setInputLocation(selectedLocation.label);
    setInputDistrict(selectedLocation.districtLabel);

    // Clear suggestions
    setLocationSuggestions([]);
    setDistrictSuggestions([]);

    // Update the applied filters
    updateAppliedFilters();
  };

  const handleDistrictSelection = async (selectedDistrict) => {
    console.log("Selected District:", selectedDistrict);
    setError("");
    setSelectedDistrict(selectedDistrict);

    setFilters({
      ...filters,
      district: selectedDistrict.value ? selectedDistrict.value : null,
    });
    setInputDistrict(selectedDistrict.label);
    setDistrictSuggestions([]);
    updateAppliedFilters();
  };

  useEffect(() => {
    const appliedFiltersArray = [];
    if (filters.location)
      appliedFiltersArray.push({
        id: "location",
        name: selectedLocation.label,
        value: selectedLocation.value,
      });

    if (filters.district && selectedDistrict) {
      appliedFiltersArray.push({
        id: "district",
        name: selectedDistrict.label,
        value: selectedDistrict.value,
      });
    } else if (filters.district && selectedLocation) {
      appliedFiltersArray.push({
        id: "district",
        name: selectedLocation.districtLabel,
        value: selectedLocation.districtValue,
      });
    }

    updateAppliedFilters(appliedFiltersArray);
    console.log("applied filters search schools", appliedFiltersArray);
  }, [selectedLocation, selectedDistrict, filters]);

  useEffect(() => {
    if (!inputValue || !inputValue.trim()) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        keyword: null,
      }));
    }
  }, [inputValue, selectedDistrict, selectedLocation]);

  const handleRemoveFilter = (filterId) => {
    let updatedFilters = appliedFilters.filter(
      (filter) => filter.id !== filterId
    );

    let updatedFilterState = { ...filters };

    if (filterId === "district") {
      updatedFilters = appliedFilters.filter(
        (filter) => filter.id !== "location" && filter.id !== "district"
      );
      updatedFilterState = {
        ...updatedFilterState,
        location: null,
        district: null,
      };
      setInputLocation("");
      setInputDistrict("");
      setSelectedDistrict(null);
      setSelectedLocation(null);
    } else {
      updatedFilterState = { ...updatedFilterState, [filterId]: null };

      if (filterId === "location") {
        updatedFilterState = {
          ...updatedFilterState,
          location: null,
          district: null,
        };
        setSelectedLocation(null);
        setSelectedDistrict(null);
        setInputLocation("");
        setInputDistrict("");
      }

      if (filterId === "pincode") {
        setPincode("");
        setInputLocation("");
      }
    }

    setFilters(updatedFilterState);
    setAppliedFilters(updatedFilters);
  };

  /* Setting the input value to the query parameter id. */
  useEffect(() => {
    const url = new URL(window.location);
    const keyword = url.searchParams.get("keyword");
    setInputValue(keyword);

    if (keyword && keyword.length < 3) {
      setError("Please enter atleast 3 characters");
    }
  }, []);

  console.log("filters:", filters);

  const handleSearch = (e) => {
    e.preventDefault();

    if (!inputValue && !inputDistrict && !pincode) {
      setError("Please enter a School name or a location");
      return;
    }

    if (!inputValue && !inputLocation && !pincode) {
      setError("Please enter a locality");
      return;
    }

    const searchParams = new URLSearchParams();
    if (inputValue.trim()) {
      searchParams.set("keyword", inputValue.trim());
    }
    if (filters.location) {
      searchParams.set("locality", filters.location);
    }
    if (filters.district) {
      searchParams.set("district", filters.district);
    }
    searchParams.set("status", "1");
    searchParams.set("page", "1");

    const url = new URL(window.location);
    url.search = searchParams.toString();
    window.history.pushState(null, "", url.toString());

    if (refreshSchools) {
      refreshSchools();
    }
  };

  return (
    <Typography
      component="div"
      style={{
        fontFamily: "Space Grotesk",
      }}
    >
      <Typography component="div">
        <form
          id="search-form"
          style={{
            position: "relative",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div className="flex justify-between mb-2">
            <div></div>
            <div className="flex bg-[#f77eaa] items-center px-2 mb-2 py-2 w-1/4 rounded-md">
              <LocationOn className="text-white" />
              <input
                className={`${styles.searchLocationBar}  py-2 px-2 placeholder:text-white text-white`}
                type="text"
                name="district"
                value={inputDistrict}
                autoComplete="off"
                id="district"
                placeholder="District"
                onChange={handleDistrictChange}
              />

              {appliedFilters.map(
                (filter, index) =>
                  filter.id === "district" &&
                  filters.district !== null && (
                    <>
                      <button
                        key={index} // Make sure to add a unique key for each rendered element
                        className="text-white pr-4 pl-4 text-lg"
                        type="button"
                        onClick={() => handleRemoveFilter(filter.id)}
                      >
                        &#10005;
                      </button>
                    </>
                  )
              )}
            </div>
          </div>

          {districtSuggestions.length > 0 && (
            <div
              className="w-1/4"
              style={{
                position: "absolute",
                right: 0,
                zIndex: 10,
                backgroundColor: "#fff",
                height: "180px",
                overflow: "auto",
                boxShadow: "0 0 10px #ccc",
                padding: "1rem",
                marginTop: "-15px",
              }}
            >
              {districtSuggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="item-row-suggestion"
                  onClick={() => handleDistrictSelection(suggestion)}
                >
                  {suggestion.label}
                </div>
              ))}
            </div>
          )}

          <div
            className="d-flex align-items-center  "
            style={{
              width: "100%",
            }}
          >
            <div className="flex w-full">
              <input
                className={styles.searchResultSearchBar}
                type="text"
                name="search"
                value={inputValue}
                autoComplete="off"
                id="search"
                placeholder="Enter school name"
                onChange={(e) => {
                  setInputValue(
                    /* Making the first letter of the input value uppercase. */
                    e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1)
                  );
                  setError("");

                  // setInputClicked(false) &&
                  // handleChange(e)
                }}
                // onClick={() => setInputClicked(true)}
              />
            </div>

            <div className="relative w-1/2">
              <div className="pl-2  flex bg-white  border-b-2 items-center border-[#f77eaa]">
                <div
                  className={`${styles.searchLocationBar} border-l-2  border-[#f77eaa] py-2 bg-white `}
                >
                  <input
                    className="placeholder:text-gray-500 text-black bg-white focus-visible:outline-none mx-2"
                    type="text"
                    name="location"
                    value={inputLocation}
                    autoComplete="off"
                    id="location"
                    placeholder="Locality or Pincode"
                    onChange={handleLocationChange}
                  />
                </div>

                {appliedFilters.map(
                  (filter, index) =>
                    filter.id === "location" &&
                    filters.location !== null && (
                      <>
                        <button
                          key={index} // Make sure to add a unique key for each rendered element
                          className="text-[#f77eaa] pr-4 pl-4 text-lg"
                          type="button"
                          onClick={() => handleRemoveFilter(filter.id)}
                        >
                          &#10005;
                        </button>
                      </>
                    )
                )}
                <button
                  className={styles.searchButtonResult}
                  onClick={handleSearch}
                >
                  <SearchOutlinedIcon className="text-white" />
                </button>
              </div>

              {locationSuggestions.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    backgroundColor: "#fff",
                    width: "100%",
                    height: "180px",
                    overflow: "auto",
                    boxShadow: "0 0 10px #ccc",
                    padding: "1rem",
                  }}
                >
                  {locationSuggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className="item-row-suggestion"
                      onClick={() => handleLocationSelection(suggestion)}
                    >
                      {suggestion.label}, {suggestion.districtLabel}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* {suggestions && suggestions.length > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: '0',
                      width: '100%',
                      height: `
                        ${suggestions.length > 5 ? '300px' : 'auto'}`,
                      overflow: 'auto',
                      zIndex: '100',
                      backgroundColor: 'white',
                      borderRadius: '0 0 25px 25px',
                      fontSize: '1rem',

                      fontFamily: 'Space Grotesk',
                    }}
                    className='shadow'
                  >
                    {suggestions.map((suggestion) => (
                      <div
                        key={suggestion.value}
                        style={{
                          padding: '10px',
                          cursor: 'pointer',
                        }}
                        className='hoverColor'
                        onClick={() => {
                          setInputValue(suggestion.label)
                          setSuggestions([])
                        }}
                      >
                        {suggestion.label}, {suggestion.location}
                      </div>
                    ))}
                  </div>
                )} */}
        </form>

        <div
        // className="d-flex justify-content-center align-items-center "
        // style={{
        //   width: "100%",
        // }}
        >
          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </Typography>
    </Typography>
  );
}

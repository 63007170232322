import { Typography } from "@mui/material";
import styles from "../../../styles/Login.module.css";
import React, { useState } from "react";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const CustomTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #f87faa;
    }
    &.Mui-focused fieldset {
      border-color: #f87faa;
    }
  }
  & .MuiInputLabel-root {
    color: #404040;
  }
  & .MuiInputLabel-root.Mui-focused {
    color: #404040;
  }
`;

const InputField = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Typography component="div" className="my-2" key={props.key}>
        {/* <Typography component="div">
          <label className={styles.labelStyle}>{props.label}</label>
        </Typography> */}
        <Typography component="div">
          <CustomTextField
            label={props.label}
            name={props.name}
            type={
              props.inputType === "password" && showPassword
                ? "text"
                : props.inputType
            }
            className={props.classNameProp}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.value}
            id={props.id}
            InputProps={{
              endAdornment: props.inputType === "password" && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Typography>
      </Typography>
    </>
  );
};

export default InputField;
